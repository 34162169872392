import React, { Fragment, useContext, useState } from 'react'
import { css } from 'styled-components'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { VerticalMenu } from '@wh/common/chapter/components/Header/VerticalMenu'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { BaseDropdown } from '@wh-components/core/Dropdown/BaseDropdown'
import { Button } from '@wh-components/core/Button/Button'
import { Avatar } from '@wh-components/core/Avatar/Avatar'
import { Wash } from '@wh-components/core/LoadingWash/LoadingWash'
import { BlockBodyScroll } from '@wh-components/core/BlockBodyScroll/BlockBodyScroll'
import { useScreenSize } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import TinyArrowDownIcon from '@wh-components/icons/ArrowtinyDown'
import TinyArrowUpIcon from '@wh-components/icons/ArrowtinyUp'
import { theme } from '@wh-components/core/theme'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { EnterBirthYearContext } from '@wh/common/chapter/components/EnterBirthYearProvider/EnterBirthYearProvider'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { requiresDac7Action } from '../../lib/userHints'
import { LoginAndRegisterLinks } from './LoginAndRegisterLinks'
import { UserProfileSkeleton } from './UserProfileSkeleton'

interface MyProfileLinkProps {
    onClick: () => void
    isVerticalMenuTransparent?: boolean
}

export const MyProfileLink: React.FunctionComponent<MyProfileLinkProps> = ({ onClick, isVerticalMenuTransparent }) => {
    const [profileData] = useProfileData()

    if (isUserLoggedIn(profileData) && !profileData?.isAdminUser) {
        return <HeaderDropdown userData={profileData} onClick={onClick} isVerticalMenuTransparent={isVerticalMenuTransparent} />
    } else if (profileData === `unsure`) {
        return <UserProfileSkeleton />
    } else {
        return <LoginAndRegisterLinks onClick={onClick} isVerticalMenuTransparent={isVerticalMenuTransparent} />
    }
}

const HeaderDropdown: React.FunctionComponent<{
    userData: UserProfileData
    onClick: () => void
    isVerticalMenuTransparent?: boolean
}> = ({ userData, onClick, isVerticalMenuTransparent = false }) => {
    const [isOpen, setIsOpen] = useState(false)
    const screenSize = useScreenSize()
    const userName = getUserName(userData)
    const { shouldShowEnterBirthYear } = useContext(EnterBirthYearContext)

    return (
        <Box
            height="100%"
            css={css`
                ${(p) => p.theme.media.only.phone} {
                    .tippy-box {
                        transform: translateX(-5px);
                        top: 0 !important;
                        box-shadow: none;
                        border-top: 1px solid ${(p) => p.theme.colors.palette.owl};
                    }
                }

                > div {
                    height: 100%;
                }

                .wh-vertical-menu {
                    min-width: 200px;
                    background-color: ${(p) => p.theme.colors.semantic.surface.DEFAULT};
                    border: 0;
                    border-radius: 0;

                    ${(p) => p.theme.media.only.phone} {
                        width: 100vw;
                        max-height: calc(100vh - ${FIXED_HEADER_HEIGHT_PHONE}px - 40px);
                        max-height: calc(100dvh - ${FIXED_HEADER_HEIGHT_PHONE}px);
                        overflow-y: scroll;
                        -webkit-overflow-scrolling: touch;
                    }

                    &--header {
                        padding-left: ${(p) => p.theme.space.m}px;
                        padding-right: ${(p) => p.theme.space.m}px;
                        align-items: flex-start;
                        color: ${(p) => p.theme.colors.palette.verydarkgrey};
                        user-select: none;
                    }

                    &--header,
                    &--footer {
                        background-color: ${(p) => p.theme.colors.palette.polarbear};
                    }
                }
            `}
        >
            <BaseDropdown
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                placement="bottom-end"
                offset={[0, 0]}
                zIndex={theme.zIndices.wash + 1}
                content={
                    <div onClick={() => setIsOpen(!isOpen)}>
                        <VerticalMenu showUserData={false} menuPosition="header" />
                    </div>
                }
            >
                <Button
                    size="small"
                    variant="transparent"
                    height="100%"
                    maxWidth={250}
                    paddingVertical={2}
                    testId="header-dropdown"
                    aria-label="Mein Willhaben Menü"
                    onClick={() => {
                        setIsOpen(!isOpen)
                        onClick()
                        return callActionEvent('header_click_myWillhaben', undefined)
                    }}
                    css={css`
                        ${(p) => p.theme.media.only.phone} {
                            &:focus {
                                box-shadow: none;
                                outline: 1px auto ${(p) => p.theme.colors.palette.primary.main};
                            }
                        }
                    `}
                >
                    <Box display="flex" gap="xs" width="100%" alignItems="center">
                        <Box position="relative">
                            <Avatar display="flex" size={{ phone: 32, tablet: 24 }} src={userData.picture} />
                            {(shouldShowEnterBirthYear || requiresDac7Action(userData)) && (
                                <Box
                                    height={7}
                                    width={7}
                                    position="absolute"
                                    top={0}
                                    right={0}
                                    borderRadius="100%"
                                    backgroundColor="palette.coral"
                                />
                            )}
                        </Box>
                        <Text
                            display={{ phone: 'none', tablet: 'block' }}
                            color={isVerticalMenuTransparent ? 'semantic.foreground.white' : 'palette.verydarkgrey'}
                            truncate={true}
                            testId="header-dropdown-username"
                        >
                            {userName}
                        </Text>
                        {isOpen ? (
                            <TinyArrowUpIcon
                                size={10}
                                marginLeft="xs"
                                color={isVerticalMenuTransparent ? 'semantic.foreground.white' : undefined}
                            />
                        ) : (
                            <TinyArrowDownIcon
                                size={10}
                                marginLeft="xs"
                                color={isVerticalMenuTransparent ? 'semantic.foreground.white' : undefined}
                            />
                        )}
                    </Box>
                </Button>
            </BaseDropdown>
            {isOpen && screenSize === 'phone' && (
                <Fragment>
                    <BlockBodyScroll />
                    <Wash
                        variant="dark"
                        css={css`
                            margin-top: 50px;
                        `}
                    />
                </Fragment>
            )}
        </Box>
    )
}

const getUserName = (userData: UserProfileData) => {
    const { emailAddress, firstName, lastName } = userData
    let username = emailAddress
    if (firstName) {
        username = firstName

        if (lastName) {
            username = `${firstName} ${lastName}`
        }
    }

    return username
}
