import React, { FunctionComponent, useState } from 'react'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'
import { storyblokCallActionEvent } from '../../lib/storyblokCallActionEvent'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'

interface StoryblokDiscoveryBlockWithCtaButtonBlok extends StoryblokComponentType<string> {
    ctaButtonText: string
    ctaButtonColor: 'primary' | 'secondary'
    ctaButtonVariant: 'solid' | 'outline'
    ctaClickTaggingEventName: string
    visibleAfterClickContent?: SbBlokData[]
}

interface Props extends StoryblokDefaultProps<StoryblokDiscoveryBlockWithCtaButtonBlok> {}

export const StoryblokDiscoveryBlockWithCtaButton: FunctionComponent<Props> = ({ blok, story }) => {
    const [isCtaClicked, setCtaClicked] = useState(false)

    return (
        <form {...storyblokEditable(blok as unknown as SbBlokData)}>
            {isCtaClicked ? (
                blok.visibleAfterClickContent?.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
                ))
            ) : (
                <LinkWithButtonStyle
                    color={blok.ctaButtonColor}
                    variant={blok.ctaButtonVariant}
                    marginTop="s"
                    onClick={() => {
                        storyblokCallActionEvent(blok.ctaClickTaggingEventName, story?.full_slug ?? '')
                        setCtaClicked(true)
                    }}
                >
                    {blok.ctaButtonText}
                </LinkWithButtonStyle>
            )}
        </form>
    )
}
