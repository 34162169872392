import { storyblokResolveLink } from '../../lib/storyblokResolveLink'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { InternalRichText } from './InternalRichText'

interface StoryblokHintLink extends StoryblokComponentType<string> {
    image: StoryblokAsset
    content: SbBlokData
    link: StoryblokLink
}

interface Props extends StoryblokDefaultProps<StoryblokHintLink> {}

export const StoryblokHintLink: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <Link
            type="anchor"
            href={storyblokResolveLink(blok.link)}
            className="hint-link"
            {...storyblokEditable(blok as unknown as SbBlokData)}
        >
            <HintImage src={blok.image.filename} alt={blok.image.alt} className="hint-link-image" />
            <InternalRichText
                blok={blok as unknown as SbBlokData}
                richTextKey="content"
                fontSize="m"
                story={story}
                css={css`
                    p {
                        margin-bottom: ${(p) => p.theme.space.s}px;
                    }
                    & > *:first-child {
                        margin-top: 0;
                    }
                    & > *:last-child {
                        margin-bottom: 0;
                    }
                `}
            />
        </Link>
    )
}

const Link = styled(ClientRoutingAnchorLink)`
    display: flex;
    align-items: center;
    gap: ${(p) => p.theme.space.m}px;
    &:hover .hint-link-image,
    &:focus .hint-link-image {
        border: 1px solid ${(p) => p.theme.colors.palette.primary.main};
    }
`

const HintImage = styled.img`
    display: block;
    width: 75px;
    height: 75px;
    background-color: ${(p) => p.theme.colors.semantic.surface.DEFAULT};
    border-radius: 50%;
    border: 1px solid ${(p) => p.theme.colors.palette.owl};
    transition: all 0.2s ease 0s;
`
