/* eslint-disable max-lines */
import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    PageToAdvertisingMessage,
    PageToDataMessage,
} from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageProtocols'
import {
    hasCorrectAdvertisingProtocol,
    sanitizeAdvertisingToPageMessage,
} from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageDecoder'
import { captureException } from '@wh/common/chapter/components/Sentry/sentry'
import { AdvertisingParametersV2 } from '@wh/common/digital-advertising/types/advertising-parametersV2'
import { useRouter } from 'next/router'
import {
    undeprecatedBackgroundTransparentTarget,
    undeprecatedCloseAdSlotTarget,
    undeprecatedTopStyle,
} from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingStateDeprecationHelper'
import { AdvertisingPageModificationsState } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingPageModificationsState'
import { useSyncDmpSegmentsToCookie } from './useSyncDmpSegmentsToCookie'

export interface AdvertisingState {
    onReset: () => void
    onSetCurrentAdvertisingParameters: (advertisingParametersV2: AdvertisingParametersV2) => void
    onSetCurrentLeaderboardTop: (leaderboardTop: number) => void
    currentLeaderboardTop: number
    pageModifications: AdvertisingPageModificationsState
}

const fallbackLeaderboardTop = 116
const motorSearchEntryAreaBottomDefaultHeight = 250
const motorSearchEntryAreaBottomMaxHeight = 350
const estateSearchEntryAreaBottomDefaultHeight = 250
const estateSearchEntryAreaBottomMaxHeight = 350
const bapSearchEntryAreaBottomDefaultHeight = 250
const bapSearchEntryAreaBottomMaxHeight = 350
const bapSearchEntryPopularCategoriesIframeDefaultHeight = 250
const bapSearchEntryPopularCategoriesIframeMaxHeight = 350

export const AdvertisingStateContext = React.createContext<AdvertisingState>({
    onReset: () => {},
    onSetCurrentAdvertisingParameters: (_) => {},
    onSetCurrentLeaderboardTop: (_) => {},
    currentLeaderboardTop: fallbackLeaderboardTop,
    pageModifications: {},
})

// this is extracted as a constant on purpose, as it will be used in hook deps which are compared by reference, and thus save rerenders
const initialPageModificationState: AdvertisingPageModificationsState = {}

export const AdvertisingStateProvider: FunctionComponent<PropsWithChildren<{}>> = (props) => {
    const tmpPageModificationsRef = useRef<AdvertisingPageModificationsState>(initialPageModificationState)
    const [pageModifications, setPageModifications] = useState<AdvertisingPageModificationsState>(initialPageModificationState)
    const [currentAdvertisingParameters, setCurrentAdvertisingParameters] = useState<AdvertisingParametersV2>({})
    const [currentLeaderboardTop, setCurrentLeaderboardTop] = useState<number>(fallbackLeaderboardTop)

    // avoid unnecessary rerenders when currentAdvertisingParameters change
    const currentAdvertisingParametersRef = useRef(currentAdvertisingParameters)
    currentAdvertisingParametersRef.current = currentAdvertisingParameters

    const messageListener = useCallback(
        (event: MessageEvent) => {
            handleMessage(event, tmpPageModificationsRef, setPageModifications, currentAdvertisingParametersRef.current)
        },
        [setPageModifications, currentAdvertisingParametersRef],
    )

    const onReset = useCallback(() => {
        tmpPageModificationsRef.current = initialPageModificationState
        setPageModifications(initialPageModificationState)
    }, [setPageModifications])

    const router = useRouter()
    const routerRef = useRef(router)
    routerRef.current = router

    // resetting the advertising state on client side navigation is a safety measure to guarantee cleanup (the useDigitalAdvertising hook cleans up too on unmount, but the reset here is a safety net)
    useEffect(() => {
        const handleRouteChange = () => {
            onReset()
        }
        routerRef.current.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            routerRef.current.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [onReset])

    useEffect(() => {
        addEventListener('message', messageListener, false)
        return () => removeEventListener('message', messageListener, false)
    }, [messageListener])

    useSyncDmpSegmentsToCookie()

    const state: AdvertisingState = useMemo(() => {
        return {
            onReset: onReset,
            onSetCurrentAdvertisingParameters: setCurrentAdvertisingParameters,
            onSetCurrentLeaderboardTop: setCurrentLeaderboardTop,
            currentLeaderboardTop: currentLeaderboardTop,
            pageModifications: pageModifications,
        }
    }, [onReset, pageModifications, setCurrentAdvertisingParameters, currentLeaderboardTop, setCurrentLeaderboardTop])

    return <AdvertisingStateContext.Provider value={state}>{props.children}</AdvertisingStateContext.Provider>
}

const handleMessage = (
    event: MessageEvent,
    tmpPageModificationsRef: React.MutableRefObject<AdvertisingPageModificationsState>,
    setPageModifications: (value: AdvertisingPageModificationsState) => void,
    currentAdvertisingParameters: AdvertisingParametersV2,
) => {
    // validate the event origin to mitigate the possiblilty of XSS attacks
    if (
        ![
            window.location.origin,
            'https://preview.animotion.agency',
            'https://cdn.animotion.agency',
            'https://cdn.publishr.studio',
        ].includes(event.origin)
    ) {
        return
    }

    const adMessage = sanitizeAdvertisingToPageMessage(event.data)

    if (!adMessage) {
        if (hasCorrectAdvertisingProtocol(event.data)) {
            captureException(new Error(`could not parse ad-wh-v1 message: ${JSON.stringify(event.data)}`), undefined, 'warning')
        }
        return
    }

    switch (adMessage.type) {
        case 'apply':
            setPageModifications(tmpPageModificationsRef.current)
            break
        case 'set-header-gradient':
            tmpPageModificationsRef.current = {
                ...tmpPageModificationsRef.current,
                headerGradient: true,
            }
            break
        case 'set-background-transparent':
            tmpPageModificationsRef.current = {
                ...tmpPageModificationsRef.current,
                // directly setting it causes a second message with fewer targets to make areas non-transparent again
                backgroundTransparent: undeprecatedBackgroundTransparentTarget(adMessage.target),
            }
            break
        case 'set-background-color':
            tmpPageModificationsRef.current = {
                ...tmpPageModificationsRef.current,
                backgroundColors: {
                    ...tmpPageModificationsRef.current.backgroundColors,
                    [adMessage.target]: adMessage.color,
                },
            }
            break
        case 'set-foreground-color':
            tmpPageModificationsRef.current = {
                ...tmpPageModificationsRef.current,
                foregroundColors: {
                    ...tmpPageModificationsRef.current.foregroundColors,
                    [adMessage.target]: adMessage.color,
                },
            }
            break
        case 'set-border': {
            const active = 'active' in adMessage ? (adMessage.active ?? true) : true
            if (!active) {
                tmpPageModificationsRef.current = {
                    ...tmpPageModificationsRef.current,
                    borders: { ...tmpPageModificationsRef.current.borders, [adMessage.target]: undefined },
                }
            } else {
                switch (adMessage.target) {
                    case 'main-vertical-navigation':
                    case 'tab-active':
                    case 'tab-inactive':
                    case 'tab-hover':
                    case 'motor-search-box':
                    case 'estate-search-box':
                    case 'bap-search-box-parent-container':
                    case 'bap-shop-widget':
                    case 'bap-category-lines':
                    case 'startpage-body':
                    case 'startpage-verticals-widget':
                    case 'startpage-nearby-widget':
                        tmpPageModificationsRef.current = {
                            ...tmpPageModificationsRef.current,
                            borders: { ...tmpPageModificationsRef.current.borders, [adMessage.target]: adMessage.color },
                        }
                        break
                    case 'motor-search-box-search-button':
                        tmpPageModificationsRef.current = {
                            ...tmpPageModificationsRef.current,
                            borders: {
                                ...tmpPageModificationsRef.current.borders,
                                'motor-search-box-search-button': active,
                            },
                        }
                        break
                    case 'estate-search-box-search-button':
                        tmpPageModificationsRef.current = {
                            ...tmpPageModificationsRef.current,
                            borders: {
                                ...tmpPageModificationsRef.current.borders,
                                'estate-search-box-search-button': active,
                            },
                        }
                        break
                    case 'header-and-content-separator':
                        tmpPageModificationsRef.current = {
                            ...tmpPageModificationsRef.current,
                            borders: {
                                ...tmpPageModificationsRef.current.borders,
                                'header-and-content-separator': { color: adMessage.color, width: adMessage.width },
                            },
                        }
                        break
                    default:
                        break
                }
            }
            break
        }
        case 'close-ad-slot':
            tmpPageModificationsRef.current = {
                ...tmpPageModificationsRef.current,
                closedAdSlots: [
                    ...(tmpPageModificationsRef.current.closedAdSlots || []),
                    ...undeprecatedCloseAdSlotTarget(adMessage.target),
                ],
            }
            break
        case 'change-ad-slot-style':
            switch (adMessage.target) {
                case 'apn-large-skyscraper':
                case 'apn-skyscraper':
                    switch (adMessage.style) {
                        case 'sbar':
                            tmpPageModificationsRef.current = {
                                ...tmpPageModificationsRef.current,
                                apnSkyscraperStyle: {
                                    baseStyle: 'sbar',
                                    topStyle: undeprecatedTopStyle(adMessage.topStyle),
                                    leftStyle: adMessage.leftStyle,
                                },
                            }
                            break
                        case 'hpa':
                            tmpPageModificationsRef.current = {
                                ...tmpPageModificationsRef.current,
                                apnSkyscraperStyle: {
                                    baseStyle: 'hpa',
                                    topStyle: undeprecatedTopStyle(adMessage.topStyle),
                                    leftStyle: adMessage.leftStyle,
                                },
                            }
                            break
                        case 'sky':
                            tmpPageModificationsRef.current = {
                                ...tmpPageModificationsRef.current,
                                apnSkyscraperStyle: {
                                    baseStyle: 'sky',
                                    topStyle: undeprecatedTopStyle(adMessage.topStyle),
                                    leftStyle: adMessage.leftStyle,
                                },
                            }
                            break
                        case 'lad':
                            tmpPageModificationsRef.current = {
                                ...tmpPageModificationsRef.current,
                                apnSkyscraperStyle: {
                                    baseStyle: 'lad',
                                    topStyle: undeprecatedTopStyle(adMessage.topStyle),
                                    leftStyle: adMessage.leftStyle,
                                },
                            }
                            break
                        case 'custom': {
                            const rightOrWidth = (() => {
                                if ('marginRight' in adMessage) {
                                    return { marginRight: adMessage.marginRight }
                                } else {
                                    return { width: adMessage.width }
                                }
                            })()
                            const bottomOrHeight = (() => {
                                if ('marginBottom' in adMessage) {
                                    return { marginBottom: adMessage.marginBottom }
                                } else {
                                    return { height: adMessage.height }
                                }
                            })()
                            tmpPageModificationsRef.current = {
                                ...tmpPageModificationsRef.current,
                                apnSkyscraperStyle: {
                                    baseStyle: 'custom',
                                    position: adMessage.position,
                                    marginLeft: adMessage.marginLeft,
                                    marginTop: adMessage.marginTop,
                                    ...rightOrWidth,
                                    ...bottomOrHeight,
                                },
                            }
                            break
                        }
                        default:
                            break
                    }
                    break
                default:
                    break
            }
            break
        case 'set-iframe-content':
            switch (adMessage.target) {
                case 'background':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        backgroundIFrame: { src: adMessage.src, position: adMessage.position },
                    }
                    break
                case 'overlay':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        overlayIFrame: { src: adMessage.src, position: adMessage.position },
                    }
                    break
                case 'motor-small-leaderboard':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        motorSmallLeaderboardIFrame: {
                            src: adMessage.src,
                        },
                    }
                    break
                case 'motor-search-box-right':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        motorSearchBoxRightIFrame: { src: adMessage.src },
                    }
                    break
                case 'motor-search-entry-area-bottom':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        motorSearchEntryAreaBottomIFrame: {
                            type: 'one',
                            src: adMessage.src,
                            height: Math.min(
                                adMessage.height ?? motorSearchEntryAreaBottomDefaultHeight,
                                motorSearchEntryAreaBottomMaxHeight,
                            ),
                        },
                    }
                    break
                case 'motor-search-entry-area-bottom-left':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        motorSearchEntryAreaBottomIFrame: {
                            type: 'two',
                            left: {
                                src: adMessage.src,
                                height: Math.min(
                                    adMessage.height ?? motorSearchEntryAreaBottomDefaultHeight,
                                    motorSearchEntryAreaBottomMaxHeight,
                                ),
                            },
                            right:
                                tmpPageModificationsRef.current.motorSearchEntryAreaBottomIFrame?.type === 'two'
                                    ? tmpPageModificationsRef.current.motorSearchEntryAreaBottomIFrame.right
                                    : undefined,
                        },
                    }
                    break
                case 'motor-search-entry-area-bottom-right':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        motorSearchEntryAreaBottomIFrame: {
                            type: 'two',
                            left:
                                tmpPageModificationsRef.current.motorSearchEntryAreaBottomIFrame?.type === 'two'
                                    ? tmpPageModificationsRef.current.motorSearchEntryAreaBottomIFrame.left
                                    : undefined,
                            right: {
                                src: adMessage.src,
                                height: Math.min(
                                    adMessage.height ?? motorSearchEntryAreaBottomDefaultHeight,
                                    motorSearchEntryAreaBottomMaxHeight,
                                ),
                            },
                        },
                    }
                    break
                case 'estate-small-leaderboard':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        estateSmallLeaderboardIFrame: {
                            src: adMessage.src,
                        },
                    }
                    break
                case 'startpage-teaser-large':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        startPageTeaserIFrame: {
                            src: adMessage.src,
                        },
                    }
                    break
                case 'startpage-teaser-small':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        startPageTeaserIFrameSmall: {
                            src: adMessage.src,
                            height: adMessage.height ?? 0,
                        },
                    }
                    break
                case 'startpage-leaderboard-small':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        startPageLeaderboardIFrameSmall: {
                            src: adMessage.src,
                            height: adMessage.height ?? 0,
                        },
                    }
                    break
                case 'startpage-leaderboard':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        startPageLeaderboard: {
                            src: adMessage.src,
                            height: adMessage.height ?? 0,
                        },
                    }
                    break
                case 'estate-search-box-right':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        estateSearchBoxRightIFrame: { src: adMessage.src },
                    }
                    break
                case 'estate-search-entry-area-bottom':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        estateSearchEntryAreaBottomIFrame: {
                            type: 'one',
                            src: adMessage.src,
                            height: Math.min(
                                adMessage.height ?? estateSearchEntryAreaBottomDefaultHeight,
                                estateSearchEntryAreaBottomMaxHeight,
                            ),
                        },
                    }
                    break
                case 'estate-search-entry-area-bottom-left':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        estateSearchEntryAreaBottomIFrame: {
                            type: 'two',
                            left: {
                                src: adMessage.src,
                                height: Math.min(
                                    adMessage.height ?? estateSearchEntryAreaBottomDefaultHeight,
                                    estateSearchEntryAreaBottomMaxHeight,
                                ),
                            },
                            right:
                                tmpPageModificationsRef.current.estateSearchEntryAreaBottomIFrame?.type === 'two'
                                    ? tmpPageModificationsRef.current.estateSearchEntryAreaBottomIFrame.right
                                    : undefined,
                        },
                    }
                    break
                case 'estate-search-entry-area-bottom-right':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        estateSearchEntryAreaBottomIFrame: {
                            type: 'two',
                            left:
                                tmpPageModificationsRef.current.estateSearchEntryAreaBottomIFrame?.type === 'two'
                                    ? tmpPageModificationsRef.current.estateSearchEntryAreaBottomIFrame.left
                                    : undefined,
                            right: {
                                src: adMessage.src,
                                height: Math.min(
                                    adMessage.height ?? estateSearchEntryAreaBottomDefaultHeight,
                                    estateSearchEntryAreaBottomMaxHeight,
                                ),
                            },
                        },
                    }
                    break
                case 'bap-search-box-right':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        bapSearchBoxRightIFrame: { src: adMessage.src },
                    }
                    break
                case 'bap-search-entry-area-bottom': // iframe below the bap vertical home search box - covers the whole content width - if this is set, neither 'bap-search-entry-area-bottom-left' nor 'bap-search-entry-area-bottom-right' are displayed
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        bapSearchEntryAreaBottomIFrame: {
                            type: 'one',
                            src: adMessage.src,
                            height: Math.min(adMessage.height ?? bapSearchEntryAreaBottomDefaultHeight, bapSearchEntryAreaBottomMaxHeight),
                        },
                    }
                    break
                case 'bap-search-entry-area-bottom-left': // left one of two iframes below the bap vertical home search box that are shown next to each other - if this and 'bap-search-entry-area-bottom-right' are set, 'bap-search-entry-area-bottom' is not displayed
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        bapSearchEntryAreaBottomIFrame: {
                            type: 'two',
                            left: {
                                src: adMessage.src,
                                height: Math.min(
                                    adMessage.height ?? bapSearchEntryAreaBottomDefaultHeight,
                                    bapSearchEntryAreaBottomMaxHeight,
                                ),
                            },
                            right:
                                tmpPageModificationsRef.current.bapSearchEntryAreaBottomIFrame?.type === 'two'
                                    ? tmpPageModificationsRef.current.bapSearchEntryAreaBottomIFrame.right
                                    : undefined,
                        },
                    }
                    break
                case 'bap-search-entry-area-bottom-right': // right one of two iframes below the bap vertical home search box that are shown next to each other - if this and 'bap-search-entry-area-bottom-left' are set, 'bap-search-entry-area-bottom' is not displayed
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        bapSearchEntryAreaBottomIFrame: {
                            type: 'two',
                            left:
                                tmpPageModificationsRef.current.bapSearchEntryAreaBottomIFrame?.type === 'two'
                                    ? tmpPageModificationsRef.current.bapSearchEntryAreaBottomIFrame.left
                                    : undefined,
                            right: {
                                src: adMessage.src,
                                height: Math.min(
                                    adMessage.height ?? bapSearchEntryAreaBottomDefaultHeight,
                                    bapSearchEntryAreaBottomMaxHeight,
                                ),
                            },
                        },
                    }
                    break
                case 'bap-small-leaderboard':
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        bapSmallLeaderboardIFrame: {
                            src: adMessage.src,
                        },
                    }
                    break
                case 'bap-search-entry-popular-categories': // below the search box, before "Alle Kategorien". Replaces "Beliebte Kategorien" and "Beliebte Mode-Marken", if set. Supports height (default: 250, max: 350).
                    tmpPageModificationsRef.current = {
                        ...tmpPageModificationsRef.current,
                        bapSearchEntryPopularCategoriesIframe: {
                            src: adMessage.src,
                            height: Math.min(
                                adMessage.height ?? bapSearchEntryPopularCategoriesIframeDefaultHeight,
                                bapSearchEntryPopularCategoriesIframeMaxHeight,
                            ),
                        },
                    }
                    break
                default:
                    break
            }
            break
        case 'request-advertising-parameters': {
            if (!event.source) {
                console.error('event.source is null, cannot send advertising parameter response via postMessage')
                return
            }

            const response: PageToAdvertisingMessage = {
                protocol: 'wh-ad-v1',
                type: 'advertising-parameters-response',
                parameters: currentAdvertisingParameters,
            }
            sendPostMessage(event, response)
            break
        }
        case 'request-data': {
            if (!event.source) {
                console.error('event.source is null, cannot send advertising parameter response via postMessage')
                return
            }

            const response: PageToDataMessage = {
                protocol: 'wh-ad-v1',
                type: 'data-response',
                parameters: {
                    scrollY: window.scrollY,
                },
            }

            sendPostMessage(event, response)
            break
        }
        default:
            // ignore unknown message type
            break
    }
}

const sendPostMessage = (event: MessageEvent, response: PageToAdvertisingMessage | PageToDataMessage) => {
    try {
        ;(event.source as typeof window).postMessage(response, event.origin)
    } catch (e) {
        console.error(`error sending response via postMessage: ${e}`)
    }
}
