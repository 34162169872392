import { InternalRichText } from './InternalRichText'
import { PayLiveryIconText } from '../../../chapter/components/PayLiveryIconText/PayLiveryIconText'
import { storyblokResolveLink } from '../../lib/storyblokResolveLink'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'

interface StoryblokTitleCardBlueBlok extends StoryblokComponentType<string> {
    title: string
    link: StoryblokLink
    linkText: string
    titleBackgroundColor: string
    backgroundImage?: StoryblokAsset
    paddingBottom: string
}

interface Props extends StoryblokDefaultProps<StoryblokTitleCardBlueBlok> {}

export const StoryblokTitleCardBlue: FunctionComponent<Props> = ({ blok, story }) => {
    const { title, link, linkText, titleBackgroundColor, backgroundImage, paddingBottom } = blok
    const resolvedLink = storyblokResolveLink(link)
    return (
        <Box
            {...storyblokEditable(blok as unknown as SbBlokData)}
            borderRadius="m"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            marginTop={{ phone: 'm', tablet: 0 }}
        >
            <Box backgroundColor={titleBackgroundColor}>
                <Box color="semantic.foreground.white" fontWeight="bold" paddingHorizontal="m" paddingVertical="s">
                    <PayLiveryIconText>{title}</PayLiveryIconText>
                </Box>
            </Box>
            <Box
                backgroundColor="palette.primary.main"
                display="flex"
                paddingHorizontal="m"
                paddingTop="s"
                color="semantic.foreground.white"
                flexDirection="column"
                backgroundImage={
                    backgroundImage?.filename
                        ? `image-set(url("${backgroundImage?.filename}/m/600x0") 1x, url("${backgroundImage?.filename}/m/1200x0") 2x)`
                        : undefined
                }
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundPosition="center bottom"
                paddingBottom={paddingBottom !== '' ? `${paddingBottom}px` : undefined}
            >
                <InternalRichText
                    blok={blok as unknown as SbBlokData}
                    richTextKey="text"
                    story={story}
                    css={css`
                        a {
                            color: ${(p) => p.theme.colors.semantic.foreground.white};
                        }
                        & > *:last-child {
                            margin-bottom: ${(p) => p.theme.space.s}px;
                        }
                    `}
                />
                {resolvedLink && (
                    <ServerRoutingAnchorLink
                        type="anchor"
                        color="semantic.foreground.white"
                        css={css`
                            text-align: right;
                        `}
                        paddingBottom="s"
                        href={resolvedLink}
                    >
                        <Text fontWeight="bold">{linkText}</Text>
                    </ServerRoutingAnchorLink>
                )}
            </Box>
        </Box>
    )
}
