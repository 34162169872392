import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import React, { FunctionComponent } from 'react'

interface StoryblokRatioColumn extends StoryblokComponentType<string> {
    ratioLeft: string
    leftColumn: SbBlokData[]
    rightColumn: SbBlokData[]
    reverseOrderMobile: boolean
}

interface Props extends StoryblokDefaultProps<StoryblokRatioColumn> {}

export const StoryblokRatioColumn: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <Box
            width="100%"
            display="flex"
            flexDirection={{ phone: blok.reverseOrderMobile ? 'column-reverse' : 'column', tablet: 'row' }}
            gap="m"
            {...storyblokEditable(blok as unknown as SbBlokData)}
        >
            <Box flex={`0 0 ${blok.ratioLeft}`}>
                {blok.leftColumn.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
                ))}
            </Box>
            <Box flex="1 1 auto">
                {blok.rightColumn.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
                ))}
            </Box>
        </Box>
    )
}
