import React, { FunctionComponent } from 'react'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { StoryblokCollapsibleFaq, StoryblokCollapsibleFaqBlok } from './StoryblokCollapsibleFaq'

interface StoryblokCarAuctionsFaqListBlok extends StoryblokComponentType<string> {
    items: StoryblokCollapsibleFaqBlok[]
}

interface Props extends StoryblokDefaultProps<StoryblokCarAuctionsFaqListBlok> {}

export const StoryblokCarAuctionsFaqList: FunctionComponent<Props> = ({ blok }) => {
    return (
        <Box marginTop="xl" {...storyblokEditable(blok as unknown as SbBlokData)}>
            {blok.items.map((item) => (
                <Box key={item._uid}>
                    <StoryblokCollapsibleFaq blok={item} />
                    <Divider marginVertical="l" />
                </Box>
            ))}
        </Box>
    )
}
