import React, { Fragment, FunctionComponent, useContext } from 'react'
import { css, useTheme } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { Header } from '@wh/common/chapter/components/Header/Header'
import { Footer } from '@wh/common/chapter/components/Footer/Footer'
import { DatapixelRenderSlots } from '@wh/common/digital-advertising/components/DataPixelRenderSlots/DatapixelRenderSlots'
import { SkyscraperLargeRenderSlot } from '@wh/common/digital-advertising/components/SkyscraperLargeRenderSlot/SkyscraperLargeRenderSlot'
import { LeaderboardLargeRenderSlot } from '@wh/common/digital-advertising/components/LeaderboardLargeRenderSlot/LeaderboardLargeRenderSlot'
import { LeaderboardMediumRenderSlot } from '@wh/common/digital-advertising/components/LeaderboardMediumRenderSlot/LeaderboardMediumRenderSlot'
import { SuccessAlert } from '@wh/common/chapter/components/Alerts/SuccessAlert'
import { SkipToContent, SkipToContentContainer } from '@wh/common/chapter/components/SkipToContent/SkipToContent'
import { Grid } from '@wh-components/core/Grid/Grid'
import { WarningAlert } from '@wh/common/chapter/components/Alerts/WarningAlert'
import { useDeprecatedBrowserAlert } from '@wh/common/chapter/hooks/useDeprecatedBrowserAlert'
import { DynamicRendering } from '@wh/common/chapter/components/DynamicRendering/DynamicRendering'
import { AndroidOnelinkBanner } from '@wh/common/chapter/components/AndroidOnelinkBanner/AndroidOnelinkBanner'
import { AppStyle } from '@wh/common/chapter/components/Layouts/AppStyle'
import Head from 'next/head'
import { GlobalAdvertisingStyle } from '@wh/common/chapter/components/Layouts/GlobalAdvertisingStyle'
import { printDisplayBlockFirefoxWorkaroundCss } from '@wh/common/chapter/components/Printing/printDisplayBlockFirefoxWorkaroundCss'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { useIsiPhone } from '@wh/common/chapter/hooks/useIsiPhone'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { ssoLogoutLink } from '@wh/common/chapter/lib/config/runtimeConfig'
import { BackgroundIframe } from './Iframes/BackgroundIframe'
import { OverlayIframe } from './Iframes/OverlayIframe'
import { LeaderboardIframe } from './Iframes/LeaderboardIframe'
import { LayoutProps } from './LayoutProps'
import { VERTICAL_LINKS_HEIGHT, VerticalLinks } from '../VerticalLinks/VerticalLinks'

const FOOTER_MIN_HEIGHT_PHONE = 238 // can be 221px when no line-break in footer navlist
const FOOTER_MIN_HEIGHT_TABLET = 284

const HEADER_HEIGHT_TABLET = 109

const ColumnRight: FunctionComponent<{
    showSkyscraper: boolean
    contentFullWidth: boolean
    customSkyscraper?: React.ReactNode
    is404: boolean
}> = ({ showSkyscraper, contentFullWidth, customSkyscraper, is404 }) => {
    const themeFromContext = useTheme()
    const advertisingState = useContext(AdvertisingStateContext)
    if (contentFullWidth) {
        return null
    }

    const backgroundTransparent = new Set(advertisingState.pageModifications.backgroundTransparent || [])
    const asideRightBackgroundColor = backgroundTransparent.has('aside-right')
        ? 'transparent'
        : advertisingState.pageModifications.backgroundColors?.['aside-right']

    return (
        <Box
            height="100%"
            // relative position is necessary for absolutely positioned skyscraper div
            position="relative"
            backgroundColor={asideRightBackgroundColor || 'palette.polarbear'}
            borderLeft={`1px solid ${
                backgroundTransparent.has('apn-large-leaderboard-right-separator') ? 'transparent' : themeFromContext.colors.palette.dove
            }`}
        >
            {showSkyscraper && !is404 && customSkyscraper}
            {showSkyscraper && !is404 && !customSkyscraper && <SkyscraperLargeRenderSlot />}
        </Box>
    )
}

const ColumnLeft: FunctionComponent<
    Required<
        Pick<LayoutProps, 'children' | 'showVerificationAlert' | 'showLeaderboard' | 'showFooter' | 'style' | 'appEmbedded' | 'is404'>
    > &
        Pick<LayoutProps, 'extraMenuLink' | 'vertical' | 'verticalLinksType' | 'preventAdminAlert'>
> = ({
    children,
    showVerificationAlert,
    showLeaderboard,
    showFooter,
    style,
    appEmbedded,
    is404,
    extraMenuLink,
    vertical,
    verticalLinksType,
    preventAdminAlert,
}) => {
    const themeFromContext = useTheme()
    const advertisingState = useContext(AdvertisingStateContext)

    const leaderboardIframe = advertisingState.pageModifications.startPageLeaderboard
    const replaceLeaderboardWithIframe = leaderboardIframe !== undefined

    const headerBackgroundColorPhone = themeFromContext.colors.semantic.surface.DEFAULT

    const backgroundTransparent = new Set(advertisingState.pageModifications.backgroundTransparent || [])
    const isHeaderTransparent = backgroundTransparent.has('header')
    const isVerticalMenuTransparent = backgroundTransparent.has('header-vertical-links')
    const headerBackgroundTransparentTabletDesktop =
        isHeaderTransparent || typeof advertisingState.pageModifications.backgroundColors?.header !== 'undefined'
    const verticalMenuBorderColor = advertisingState.pageModifications.borders?.['main-vertical-navigation']
    const headerBackgroundColorTabletDesktop = isHeaderTransparent
        ? 'transparent'
        : (advertisingState.pageModifications.backgroundColors?.header ?? themeFromContext.colors.semantic.surface.DEFAULT)
    const headerAndContentSeparator = advertisingState.pageModifications.borders?.['header-and-content-separator']

    const { showDeprecatedBrowserAlert, handleSnoozeDeprecatedBrowserAlert } = useDeprecatedBrowserAlert()
    const [profileData] = useProfileData()

    return (
        <div
            css={css`
                pointer-events: auto;
            `}
        >
            <Grid
                areaComponents={{
                    header: appEmbedded ? (
                        <Fragment />
                    ) : (
                        <Box
                            paddingHorizontal={{ tablet: 'm' }}
                            display={{ phone: 'block', print: 'none' }}
                            backgroundColor={{
                                phone: headerBackgroundColorPhone,
                                tablet: headerBackgroundColorTabletDesktop,
                            }}
                            // we need to offset by space.m in order to compensate for the white top border of the leaderboard container
                            boxShadow={{
                                desktop: headerAndContentSeparator
                                    ? `${-themeFromContext.space.m}px 0px 0px ${
                                          themeFromContext.space.m + headerAndContentSeparator.width
                                      }px ${headerAndContentSeparator.color}`
                                    : undefined,
                            }}
                        >
                            <Box as="header">
                                <Header
                                    headerBackgroundTransparentTabletDesktop={headerBackgroundTransparentTabletDesktop}
                                    verticalMenuBorderColor={verticalMenuBorderColor}
                                    extraMenuLink={extraMenuLink}
                                    vertical={vertical}
                                    isVerticalMenuTransparent={isVerticalMenuTransparent}
                                />
                            </Box>
                        </Box>
                    ),
                    verticalLinks: verticalLinksType === 'off' ? <Fragment /> : <VerticalLinks sticky={verticalLinksType === 'sticky'} />,
                    leaderboard: (
                        <div>
                            {showDeprecatedBrowserAlert && (
                                <WarningAlert
                                    title="Die Version deines Browsers wird leider nicht mehr unterstützt!"
                                    message="Aufgrund regelmäßiger Anpassungen der Sicherheit und unserer Funktionen kann es dadurch zu Einschränkungen in der Nutzung kommen. Bitte aktualisiere deinen Browser, um willhaben weiterhin einwandfrei nutzen zu können."
                                    dismissable={true}
                                    onDidDismiss={handleSnoozeDeprecatedBrowserAlert}
                                    paddingHorizontal="m"
                                    paddingTop="m"
                                    margin={{ tablet: 'm' }}
                                />
                            )}
                            {showVerificationAlert && <SuccessAlert title="Dein willhaben-Benutzerkonto ist jetzt bestätigt!" margin="m" />}

                            {isUserLoggedIn(profileData) && profileData?.isAdminUser && !preventAdminAlert && (
                                <WarningAlert
                                    title="Achtung: Du bist als Admin User eingeloggt. Bitte logge dich zuerst im Admin aus."
                                    margin="m"
                                >
                                    <ServerRoutingAnchorLink type="anchor" href={ssoLogoutLink} color="inherit" testId="global-logout-link">
                                        Hier klicken, um dich im Admin auszuloggen
                                    </ServerRoutingAnchorLink>
                                </WarningAlert>
                            )}
                            {(showLeaderboard || replaceLeaderboardWithIframe) && !is404 && (
                                <Box
                                    display={{ phone: 'none', tablet: 'block' }}
                                    backgroundColor={
                                        backgroundTransparent.has('apn-large-leaderboard') ||
                                        backgroundTransparent.has('apn-medium-leaderboard')
                                            ? 'transparent'
                                            : 'semantic.surface'
                                    }
                                    borderTop={{
                                        phone: undefined,
                                        tablet: `${themeFromContext.space.m}px solid ${headerBackgroundColorTabletDesktop}`,
                                    }}
                                >
                                    {replaceLeaderboardWithIframe ? (
                                        <LeaderboardIframe />
                                    ) : (
                                        <Fragment>
                                            <LeaderboardLargeRenderSlot />
                                            <LeaderboardMediumRenderSlot />
                                        </Fragment>
                                    )}
                                </Box>
                            )}
                        </div>
                    ),
                    main: (
                        <Box
                            id="skip-to-content"
                            minHeight={{
                                phone: `calc(100vh - (${FIXED_HEADER_HEIGHT_PHONE}px + ${FOOTER_MIN_HEIGHT_PHONE}px))`,
                                tablet: `calc(100vh - (${HEADER_HEIGHT_TABLET}px + ${FOOTER_MIN_HEIGHT_TABLET}px))`,
                            }}
                            padding={
                                style === 'content-with-white-background-and-padding'
                                    ? {
                                          phone: '0px',
                                          tablet: 'm',
                                      }
                                    : '0px'
                            }
                            backgroundColor={style === 'content-with-white-background-and-padding' ? 'semantic.surface' : 'transparent'}
                            boxShadow={{
                                desktop: headerAndContentSeparator
                                    ? `${headerAndContentSeparator.width}px 0px 0px 0px ${headerAndContentSeparator.color}, ${headerAndContentSeparator.width}px -${headerAndContentSeparator.width}px 0px 0px ${headerAndContentSeparator.color}`
                                    : undefined,
                            }}
                        >
                            {children}
                        </Box>
                    ),
                    footer: appEmbedded ? (
                        <Fragment />
                    ) : (
                        <Box
                            paddingHorizontal={{ tablet: 'm' }}
                            backgroundColor="semantic.surface"
                            boxShadow={{
                                desktop: headerAndContentSeparator
                                    ? `${headerAndContentSeparator.width}px 0px 0px 0px ${headerAndContentSeparator.color}`
                                    : undefined,
                            }}
                        >
                            <Box as="footer" display={{ print: 'none' }}>
                                {showFooter && (
                                    <DynamicRendering<HTMLDivElement>
                                        forceRendering={false}
                                        renderPlaceholder={(ref) => (
                                            <Box
                                                ref={ref}
                                                height={{
                                                    phone: `${FOOTER_MIN_HEIGHT_PHONE}px`,
                                                    tablet: `${FOOTER_MIN_HEIGHT_TABLET}px`,
                                                }}
                                            />
                                        )}
                                        offset="250px"
                                    >
                                        <Footer />
                                    </DynamicRendering>
                                )}
                            </Box>
                        </Box>
                    ),
                }}
                areaTemplate={{
                    phone: [
                        {
                            header: appEmbedded ? null : { startColumn: 1, endColumn: 1, startRow: 1, endRow: 1 },
                            verticalLinks: { startColumn: 1, endColumn: 1, startRow: 2, endRow: 2 },
                            leaderboard: { startColumn: 1, endColumn: 1, startRow: 3, endRow: 3 },
                            main: { startColumn: 1, endColumn: 1, startRow: 4, endRow: 4 },
                            footer: appEmbedded ? null : { startColumn: 1, endColumn: 1, startRow: 5, endRow: 5 },
                        },
                    ],
                }}
                rowSizes={{
                    phone: [
                        appEmbedded
                            ? 'auto'
                            : `${FIXED_HEADER_HEIGHT_PHONE + (verticalLinksType === 'sticky' ? VERTICAL_LINKS_HEIGHT : 0)}px`,
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                    ],
                    tablet: ['auto', 'auto', 'auto', 'auto', 'auto'],
                }}
                columnSizes={['minmax(250px, 1fr)']}
                css={css`
                    @media only screen and (max-height: 450px) and (orientation: portrait) {
                        // sync with "hideWhenKeyboardIsOpen"
                        grid-template-rows: ${FIXED_HEADER_HEIGHT_PHONE}px auto auto auto;
                    }
                    ${printDisplayBlockFirefoxWorkaroundCss}
                `}
            />
            <DatapixelRenderSlots />
        </div>
    )
}

export const Layout: FunctionComponent<LayoutProps> & { appEmbedded: boolean } = ({
    children,
    showVerificationAlert,
    showLeaderboard = false,
    showSkyscraper = false,
    contentFullWidth = false,
    customSkyscraper,
    showFooter = true,
    allowAndroidBanner = true,
    preventAdminAlert = false,
    verticalLinksType = 'off',
    style = 'content-with-white-background-and-padding',
    appEmbedded = false,
    is404,
    extraMenuLink,
    vertical,
    fullWidth = false,
}) => {
    const isiPhone = useIsiPhone()
    return (
        <Fragment>
            {appEmbedded ? (
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
                    />
                </Head>
            ) : isiPhone ? (
                <Head>
                    {/* this is a workaround for iPhone that will avoid autmatic zooming to input fields, but still stay zoomable. this takes advantage of iOS ignoring the maximum-scale=1 for manual zooming when no minimum-scale is set */}
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
                </Head>
            ) : (
                <AndroidOnelinkBanner allowAndroidBanner={allowAndroidBanner} />
            )}
            <Box position="relative">
                <SkipToContentContainer />
                <SkipToContent targetId="skip-to-content" text="Zum Inhalt" />
                <div id="top-scroll-anchor" />
                <GlobalAdvertisingStyle />
                <AppStyle />
                <Grid
                    rowSizes={['auto']}
                    // we need to use minmax(0, 970px) for the left column because the available width might be smaller than the browser width because of the vertical scroll bar
                    columnSizes={{
                        phone: ['minmax(0, 1fr)', 'auto'],
                        desktop: [fullWidth ? 'minmax(0, 100%)' : 'minmax(0, 970px)', 'minmax(1px, 1fr)'],
                    }}
                    areaComponents={{
                        columnLeft: (
                            <ColumnLeft
                                style={style}
                                appEmbedded={appEmbedded}
                                showVerificationAlert={showVerificationAlert}
                                showLeaderboard={showLeaderboard}
                                showFooter={showFooter}
                                is404={is404}
                                extraMenuLink={extraMenuLink}
                                vertical={vertical}
                                verticalLinksType={verticalLinksType}
                                preventAdminAlert={preventAdminAlert}
                            >
                                {children}
                            </ColumnLeft>
                        ),
                        columnRight: (
                            <ColumnRight
                                showSkyscraper={showSkyscraper}
                                contentFullWidth={contentFullWidth}
                                customSkyscraper={customSkyscraper}
                                is404={is404}
                            />
                        ),
                    }}
                    areaTemplate={{
                        phone: [
                            {
                                columnLeft: { startColumn: 1, endColumn: 1, startRow: 1, endRow: 1 },
                                columnRight: null,
                            },
                        ],
                        desktop: [
                            {
                                columnLeft: { startColumn: 1, endColumn: 1, startRow: 1, endRow: 1 },
                                columnRight: { startColumn: 2, endColumn: 2, startRow: 1, endRow: 1 },
                            },
                        ],
                    }}
                    css={printDisplayBlockFirefoxWorkaroundCss}
                />

                <OverlayIframe />
                <BackgroundIframe />
            </Box>
        </Fragment>
    )
}

Layout.appEmbedded = false
