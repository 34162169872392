/* eslint-disable no-restricted-syntax */
import { css } from 'styled-components'

/** fallback css colors for safari 15 and older which does not support `color-mix` */
export const safari15CssColors = css`
    :root {
        --wh-color-deprecated-safari15-petrol-darker5: #398cb9;
        --wh-color-deprecated-safari15-jungle-darker5: #1b8a6c;
        --wh-color-deprecated-safari15-alert-warning-main-darker10: #cc7700;
        --wh-color-deprecated-safari15-alert-warning-main-darker5: #e68600;
        --wh-color-deprecated-safari15-lavender-alpha20: #826eabcc;
        --wh-color-deprecated-safari15-babyseal-alpha20: #f4f6fbcc;
        --wh-color-deprecated-safari15-koala-alpha40: #7f8ca299;
        --wh-color-deprecated-safari15-panther-alpha35: #333333a5;
        --wh-color-deprecated-safari15-raccoon-alpha60: #7f8ca266;
        --wh-color-deprecated-safari15-raccoon-alpha30: #7f8ca2bb;
        --wh-color-deprecated-safari15-black-alpha80: #00000033;
        --wh-color-deprecated-safari15-black-alpha75: #00000040;
        --wh-color-deprecated-safari15-black-alpha70: #00000044;
        --wh-color-deprecated-safari15-white-alpha50: #ffffff80;
    }

    *[data-wh-theme='light-contrast'] {
        --wh-color-deprecated-safari15-petrol-darker5: #25607d;
        --wh-color-deprecated-safari15-jungle-darker5: #125a48;
        --wh-color-deprecated-safari15-alert-warning-main-darker10: #653f06;
        --wh-color-deprecated-safari15-alert-warning-main-darker5: #7d4e08;
        --wh-color-deprecated-safari15-lavender-alpha20: #6c5a90cc;
        --wh-color-deprecated-safari15-babyseal-alpha20: #f4f6fbcc;
        --wh-color-deprecated-safari15-koala-alpha40: #6c768999;
        --wh-color-deprecated-safari15-panther-alpha35: #333333a5;
        --wh-color-deprecated-safari15-raccoon-alpha60: #6c768966;
        --wh-color-deprecated-safari15-raccoon-alpha30: #6c7689bb;
        --wh-color-deprecated-safari15-black-alpha80: #00000033;
        --wh-color-deprecated-safari15-black-alpha75: #00000040;
        --wh-color-deprecated-safari15-black-alpha70: #00000044;
        --wh-color-deprecated-safari15-white-alpha50: #ffffff80;
    }

    *[data-wh-theme='dark'] {
        --wh-color-deprecated-safari15-petrol-darker5: #398cb9;
        --wh-color-deprecated-safari15-jungle-darker5: #1b8a6c;
        --wh-color-deprecated-safari15-alert-warning-main-darker10: #d68200;
        --wh-color-deprecated-safari15-alert-warning-main-darker5: #f09200;
        --wh-color-deprecated-safari15-lavender-alpha20: #826eabcc;
        --wh-color-deprecated-safari15-babyseal-alpha20: #3a3a3ccc;
        --wh-color-deprecated-safari15-koala-alpha40: #8e8e9399;
        --wh-color-deprecated-safari15-panther-alpha35: #ffffffa5;
        --wh-color-deprecated-safari15-raccoon-alpha60: #8e8e9366;
        --wh-color-deprecated-safari15-raccoon-alpha30: #8e8e93bb;
        --wh-color-deprecated-safari15-black-alpha80: #00000033;
        --wh-color-deprecated-safari15-black-alpha75: #00000040;
        --wh-color-deprecated-safari15-black-alpha70: #00000044;
        --wh-color-deprecated-safari15-white-alpha50: #2c2c2e80;
    }
`
