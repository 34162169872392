import Router from 'next/router'
import { Response } from 'express'
import { REDIRECT_AFTER_VERIFY_EMAIL_STORAGE_KEY } from '@wh/common/chapter/lib/localStorage'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { isServer, scrollToTop } from '@wh/common/chapter/lib/commonHelpers'
import { NextResponse } from '../../types/nextJS'
import { Redirect } from 'next'

interface TransitionOptions {
    shallow?: boolean
    locale?: string | false
    /** scroll to top as part of the route change - default to `true` */
    scroll?: boolean
}

type RoutingOptions = {
    href: string
    clientSideRouting: boolean
    options?: TransitionOptions
    beforeRouting?: () => Promise<unknown>
}

export class BbxRouter {
    static async push({ href, clientSideRouting, options = { shallow: false, scroll: true }, beforeRouting }: RoutingOptions) {
        if (isServer()) {
            console.error('BbxRouter.push must not be called on the server')
            return
        }

        if (!clientSideRouting || isAppEmbedded()) {
            if (typeof beforeRouting !== 'undefined') {
                // in case we have a beforeRouting handler, we want to give it the chance to finish before we reload the page
                // this fixes tagging requests being cancelled
                try {
                    await beforeRouting()
                } catch (error) {
                    console.error(error)
                } finally {
                    window.location.assign(href)
                }
            } else {
                window.location.assign(href)
            }
        } else {
            if (typeof beforeRouting !== 'undefined') {
                // no need to await since we do client side routing, and therefore the action won't be aborted by routing
                beforeRouting()
            }
            // temporary fix until we update to next.js 11 where this was fixed: https://github.com/vercel/next.js/pull/24888 https://github.com/vercel/next.js/releases/tag/v11.0.0
            if (options.shallow && options.scroll) {
                scrollToTop()
            }
            await Router.push(href, undefined, options)
        }
        return null
    }

    static back() {
        if (isServer()) {
            console.error('BbxRouter.back must not be called on the server')
            return
        }

        Router.back()
    }

    static reload() {
        if (isServer()) {
            console.error('BbxRouter.reload must not be called on the server')
            return
        }

        Router.reload()
    }

    static async replace({ href, clientSideRouting, options = { shallow: false, scroll: true }, beforeRouting }: RoutingOptions) {
        if (isServer()) {
            console.error('BbxRouter.replace must not be called on the server')
            return
        }

        if (!clientSideRouting || isAppEmbedded()) {
            if (typeof beforeRouting !== 'undefined') {
                // in case we have beforeRouting handler, we want to give it the chance to finish before we reload the page
                // this fixes tagging requests being cancelled
                try {
                    await beforeRouting()
                } catch (error) {
                    console.error(error)
                } finally {
                    window.location.replace(href)
                }
            } else {
                window.location.replace(href)
            }
        } else {
            if (typeof beforeRouting !== 'undefined') {
                // no need to await since we do client side routing, and therefore the action won't be aborted by routing
                beforeRouting()
            }
            // temporary fix until we update to next.js 11 where this was fixed: https://github.com/vercel/next.js/pull/24888 https://github.com/vercel/next.js/releases/tag/v11.0.0
            if (options.shallow && options.scroll) {
                scrollToTop()
            }
            await Router.replace(href, undefined, options)
        }
    }
}

export const getLoginRedirectUrls = (serverTarget?: string) => {
    let redirectTarget = escape(serverTarget || `${window.location.pathname + window.location.search}`)

    // ensure not logged in users for sso protected pages have correct redirect
    // internal-appembedded as redirectPath does not work publicly and is an internal
    // route path for apps.
    redirectTarget = redirectTarget.replace('/internal/app-embedded/', '/iad/')

    const redirectPath = `/iad/myprofile/login?r=${redirectTarget}`
    return {
        redirectTarget: redirectTarget,
        redirectPath: redirectPath,
    }
}

export const redirectToLoginPage = (res?: Response, serverTarget?: string, beforeRouting?: () => Promise<unknown>) => {
    const loginRedirectUrls = getLoginRedirectUrls(serverTarget)

    if (res) {
        redirectOnServer(loginRedirectUrls.redirectPath, res, 302)
        return
    }

    if (storageAvailable('localStorage')) {
        localStorage.setItem(REDIRECT_AFTER_VERIFY_EMAIL_STORAGE_KEY, loginRedirectUrls.redirectTarget)
    }
    BbxRouter.push({ href: loginRedirectUrls.redirectPath, clientSideRouting: false, beforeRouting })
}

export const getServerSideRedirectToLoginPage = (serverTarget?: string): Redirect => {
    const loginRedirectUrls = getLoginRedirectUrls(serverTarget)
    return { destination: loginRedirectUrls.redirectPath, permanent: false }
}

export const redirectToAdminErrorPage = (res?: Response) => {
    if (res) {
        redirectOnServer('/error/admin-user', res, 302)
        return
    }

    BbxRouter.push({ href: '/error/admin-user', clientSideRouting: false })
}

export const getServerSideRedirectToAdminErrorPage = (): Redirect => {
    return { destination: '/error/admin-user', permanent: false }
}

export type RedirectHttpStatusCode = 301 | 302

export const redirectTo = (path: string, res: NextResponse | undefined, redirectHttpStatusCode: RedirectHttpStatusCode = 302) => {
    if (res) {
        redirectOnServer(path, res, redirectHttpStatusCode)
    } else {
        BbxRouter.replace({ href: path, clientSideRouting: false })
    }
}

const redirectOnServer = (path: string, res: NextResponse, redirectHttpStatusCode: RedirectHttpStatusCode) => {
    res.writeHead(redirectHttpStatusCode, { Location: path })
    res.end()
    res.finished = true
}

export const isAppEmbedded = () => document.cookie.split(';').some((c) => c.trim() === 'isAppEmbedded=true')
