import { storyblokExtractDimensionsFromImageUrl, StoryblokImageDimension } from '../../lib/storyblokExtractDimensionsFromImageUrl'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { Text } from '@wh-components/core/Text/Text'
import { theme } from '@wh-components/core/theme'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { ConditionalWrapper } from '@wh-components/core/ConditionalWrapper/ConditionalWrapper'
import { storyblokResolveLink } from '../../lib/storyblokResolveLink'
import { ServerRoutingAnchorLink } from '../../../chapter/components/AnchorLink/AnchorLink'

interface StoryblokImageBlok extends StoryblokComponentType<string> {
    image: StoryblokAsset
    imageMobile?: StoryblokAsset
    width: string
    height: string
    maxWidth?: string
    fixedDimension?: boolean
    widthMobile: string
    heightMobile: string
    fixedDimensionMobile?: boolean
    maxWidthMobile?: string
    variation: '' | 'roundBorder'
    link?: StoryblokLink
}

interface Props extends StoryblokDefaultProps<StoryblokImageBlok> {}

const maxImageSize = 2500

export const StoryblokImageBlok: FunctionComponent<Props> = ({ blok }) => {
    const dimensions = storyblokExtractDimensionsFromImageUrl(blok.image.filename)
    const dimensionsMobile = storyblokExtractDimensionsFromImageUrl(blok.imageMobile?.filename ?? '')

    const width = getWidth(blok.width, dimensions)
    const height = getHeight(blok.height, dimensions)

    const widthMobile = getWidth(
        blok.widthMobile === '' ? blok.width : blok.widthMobile,
        dimensionsMobile === null ? dimensions : dimensionsMobile,
    )
    const heightMobile = getHeight(
        blok.heightMobile === '' ? blok.height : blok.heightMobile,
        dimensionsMobile === null ? dimensions : dimensionsMobile,
    )

    const maxWidth = getMaxWidth(blok.maxWidth)
    const maxWidthMobile = getMaxWidth(blok.maxWidthMobile === '' ? blok.maxWidth : blok.maxWidthMobile)

    const imageSrc = buildSrc(blok.image.filename, dimensions, width, height)
    const imageSrcMobile = buildSrc(blok.imageMobile?.filename ?? '', dimensionsMobile, widthMobile, heightMobile)

    if (
        (width ?? 0) > maxImageSize ||
        (height ?? 0) > maxImageSize ||
        (widthMobile ?? 0) > maxImageSize ||
        (heightMobile ?? 0) > maxImageSize
    ) {
        console.warn('image dimensions too big')
        return (
            <Text fontWeight="bold" color="palette.signal.red">
                Höhe oder Breite zu groß. Kleinere Dimensionen angeben!
            </Text>
        )
    }

    const img = (
        <ResponsiveImage
            src={imageSrcMobile === '' ? imageSrc : imageSrcMobile}
            alt={blok.image.alt}
            objectFit="cover"
            cssHeight={{ phone: blok.fixedDimensionMobile ? heightMobile : 'auto', tablet: blok.fixedDimension ? height : 'auto' }}
            maxWidth={{ phone: maxWidthMobile, tablet: maxWidth }}
            cssWidth={{ phone: widthMobile, tablet: width }}
            display="inline-block"
            aspectRatio={{
                phone: `${widthMobile ? widthMobile : 1} / ${heightMobile ? heightMobile : 1}`,
                tablet: `${width ? width : 1} / ${height ? height : 1}`,
            }}
            css={blok.variation ? roundedBorderMixin : undefined}
            {...storyblokEditable(blok as unknown as SbBlokData)}
        />
    )

    if (imageSrcMobile === '') {
        return <ConditionalLinkWrapper link={blok.link}>{img}</ConditionalLinkWrapper>
    }

    return (
        <ConditionalLinkWrapper link={blok.link}>
            <picture>
                <source srcSet={imageSrc} media={`(min-width: ${theme.breakpoints.tablet}px)`} />
                {img}
            </picture>
        </ConditionalLinkWrapper>
    )
}

const getWidth = (length: string | undefined, dimensions: StoryblokImageDimension | null): number | undefined => {
    if (length) {
        return +length
    } else if (dimensions) {
        return dimensions.width
    }
    return undefined
}

const getHeight = (length: string | undefined, dimensions: StoryblokImageDimension | null): number | undefined => {
    if (length) {
        return +length
    } else if (dimensions) {
        return dimensions.height
    }
    return undefined
}

const getMaxWidth = (maxWidth?: string) => {
    const trimmedMaxWidth = maxWidth?.trim() ?? ''

    if (trimmedMaxWidth === '') {
        return '100%'
    } else if (!trimmedMaxWidth.endsWith('%') && !trimmedMaxWidth.endsWith('px')) {
        return `${trimmedMaxWidth}px`
    } else {
        return trimmedMaxWidth
    }
}

const buildSrc = (filename: string, dimensions: StoryblokImageDimension | null, width: number | undefined, height: number | undefined) => {
    let storyblokImageExtension = ''

    // if the dimensions are null it's most likely a svg
    if (dimensions) {
        storyblokImageExtension = `/m/${width ?? 0}x${height ?? 0}`
    }

    return `${filename}${storyblokImageExtension}`
}

const roundedBorderMixin = css`
    background-color: ${(p) => p.theme.colors.semantic.surface.DEFAULT};
    border-radius: 50%;
    border: 1px solid ${(p) => p.theme.colors.palette.owl};
`

interface ConditionalLinkWrapperProps {
    link?: StoryblokLink
    children: React.ReactElement
}

const ConditionalLinkWrapper: FunctionComponent<ConditionalLinkWrapperProps> = (props) => {
    const linkHref = storyblokResolveLink(props.link)

    return (
        <ConditionalWrapper
            condition={!!linkHref}
            wrapper={(children) => (
                <ServerRoutingAnchorLink
                    href={linkHref}
                    target={props.link?.target}
                    // story links are safe internal links where also SEO crawling should happen, but for all other link types set nofollow and noopener for SEO blocking and security
                    rel={props.link?.linktype === 'story' ? undefined : 'nofollow noopener'}
                    type="anchor"
                    css={css`
                        img,
                        picture {
                            display: block;
                        }
                    `}
                >
                    {children}
                </ServerRoutingAnchorLink>
            )}
        >
            {props.children}
        </ConditionalWrapper>
    )
}
