import { RefObject, useEffect, useState } from 'react'

export const useScrollPosition = (ref: RefObject<HTMLDivElement>) => {
    const [scrollPos, setScrollPos] = useState<'left' | 'right' | 'between'>('left')

    useEffect(() => {
        if (!ref.current) {
            return
        }

        const container = ref.current

        const handleScroll = () => {
            const maxScrollWidth = container.scrollWidth - container.clientWidth
            if (container.scrollLeft < 50) {
                setScrollPos('left')
            } else if (container.scrollLeft > maxScrollWidth - 50) {
                setScrollPos('right')
            } else {
                setScrollPos('between')
            }
        }

        container.addEventListener('scroll', handleScroll, false)

        return () => {
            container.removeEventListener('scroll', handleScroll, false)
        }
    }, [ref])

    return scrollPos
}
