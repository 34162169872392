/* eslint-disable max-lines */

import { setIframeContentDecoder } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageDecoderIframe'
import { protocolDecoder } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageDecoderProtocolDecoder'
import {
    AdvertisingToPageMessage,
    ExtractedAdvertisingToPageMessage,
} from '@wh/common/digital-advertising/components/AdvertisingStateProvider/advertisingMessageProtocols'
import {
    positionFixedOrAbsoluteDecoder,
    safeCSSValueStringDecoder,
    stringOrNumberDecoder,
} from '@wh/common/digital-advertising/components/AdvertisingStateProvider/decoderHelpers'
import { JsonDecoder, Ok } from 'ts.data.json'

const setBackgroundTransparentDecoder = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'set-background-transparent' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('set-background-transparent'),
        target: JsonDecoder.array(
            JsonDecoder.oneOf(
                (
                    [
                        'apn-large-leaderboard',
                        'apn-medium-leaderboard',
                        'apn-leaderboard',
                        'aside-right',
                        'header',
                        'header-vertical-links',
                        'motor-search-entry-area',
                        'motor-search-box-parent-container',
                        'estate-search-entry-area',
                        'estate-search-box-parent-container',
                        'bap-search-entry-area',
                        'bap-search-box-parent-container',
                        'startpage-teaser',
                        'startpage-feed',
                        'apn-large-leaderboard-right-separator',
                        'apn-leaderboard-right-separator',
                    ] as const
                ).map((s) => JsonDecoder.isExactly(s)),
                'transparent background single target',
            ),
            'transparent background target',
        ),
    },
    'AdvertisingToPageMessage',
)

const setBackgroundColorDecoder = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'set-background-color' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('set-background-color'),
        target: JsonDecoder.oneOf(
            (
                [
                    'background-iframe',
                    'aside-right',
                    'header',
                    'motor-search-entry-area',
                    'tab-active',
                    'tab-inactive',
                    'tab-hover',
                    'motor-search-box-parent-container',
                    'motor-search-box',
                    'motor-car-type-image-hover',
                    'estate-search-entry-area',
                    'estate-search-box-parent-container',
                    'estate-search-box',
                    'bap-search-entry-area',
                    'bap-search-box-parent-container',
                    'bap-category-box',
                    'bap-shop-widget-header',
                    'bap-shop-widget-body',
                    'startpage-teaser',
                    'startpage-feed',
                    'startpage-ad-hover',
                    'startpage-popular-categories',
                    'startpage-separator-big',
                    'startpage-separator-small',
                    'startpage-verticals-widget-top',
                    'startpage-verticals-widget-bottom',
                    'startpage-nearby-widget',
                    'startpage-adinsertion',
                ] as const
            ).map((s) => JsonDecoder.isExactly(s)),
            'background color target',
        ),
        color: safeCSSValueStringDecoder,
    },
    'AdvertisingToPageMessage',
)

const setForegroundColorDecoder = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'set-foreground-color' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('set-foreground-color'),
        target: JsonDecoder.oneOf(
            (
                [
                    'tab-active-icon',
                    'tab-active-title',
                    'tab-active-counter',
                    'tab-inactive-icon',
                    'tab-inactive-title',
                    'tab-inactive-counter',
                    'tab-hover-icon',
                    'tab-hover-title',
                    'tab-hover-counter',
                    'motor-title',
                    'motor-title-counter',
                    'motor-search-box-text',
                    'motor-search-box-links',
                    'estate-title',
                    'estate-title-counter',
                    'estate-search-box-text',
                    'estate-search-box-links',
                    'bap-title',
                    'bap-title-counter',
                    'bap-category-labels',
                    'bap-category-icons',
                    'bap-shop-widget-header',
                    'bap-shop-widget-body',
                    'startpage-standard-color',
                    'startpage-title-color',
                    'startpage-accent-color',
                    'startpage-verticals-widget-top',
                    'startpage-verticals-widget-bottom',
                ] as const
            ).map((s) => JsonDecoder.isExactly(s)),
            'foreground color target',
        ),
        color: safeCSSValueStringDecoder,
    },
    'AdvertisingToPageMessage',
)

const setBorderDecoder1 = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'set-border' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('set-border'),
        target: JsonDecoder.oneOf(
            (
                [
                    'main-vertical-navigation',
                    'tab-active',
                    'tab-inactive',
                    'tab-hover',
                    'motor-search-box',
                    'estate-search-box',
                    'bap-search-box-parent-container',
                    'bap-shop-widget',
                    'bap-category-lines',
                    'startpage-verticals-widget',
                    'startpage-nearby-widget',
                    'startpage-body',
                ] as const
            ).map((s) => JsonDecoder.isExactly(s)),
            'border target',
        ),
        color: safeCSSValueStringDecoder,
        active: JsonDecoder.optional(JsonDecoder.boolean),
    },
    'AdvertisingToPageMessage',
)

const setBorderDecoder2 = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'set-border' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('set-border'),
        target: JsonDecoder.oneOf(
            (['motor-search-box-search-button', 'estate-search-box-search-button'] as const).map((s) => JsonDecoder.isExactly(s)),
            'border target',
        ),
        active: JsonDecoder.optional(JsonDecoder.boolean),
    },
    'AdvertisingToPageMessage',
)

const setBorderDecoder3 = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'set-border' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('set-border'),
        target: JsonDecoder.oneOf(
            (['header-and-content-separator'] as const).map((s) => JsonDecoder.isExactly(s)),
            'border target',
        ),
        color: safeCSSValueStringDecoder,
        width: JsonDecoder.number,
    },
    'AdvertisingToPageMessage',
)

const setBorderDecoder = JsonDecoder.oneOf([setBorderDecoder1, setBorderDecoder2, setBorderDecoder3], 'set-border')

const changeAdSlotStylePresetDecoder = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'change-ad-slot-style' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('change-ad-slot-style'),
        target: JsonDecoder.oneOf(
            (['apn-large-skyscraper', 'apn-skyscraper'] as const).map((s) => JsonDecoder.isExactly(s)),
            'ad slot',
        ),
        style: JsonDecoder.oneOf(
            (['sbar', 'hpa', 'sky', 'lad'] as const).map((s) => JsonDecoder.isExactly(s)),
            'style',
        ),
        topStyle: JsonDecoder.optional(
            JsonDecoder.oneOf(
                (['page-top', 'apn-large-leaderboard-top', 'apn-leaderboard-top'] as const).map((s) => JsonDecoder.isExactly(s)),
                'topStyle',
            ),
        ),
        leftStyle: JsonDecoder.optional(
            JsonDecoder.oneOf(
                (['stick-to-content', 'default-spacing'] as const).map((s) => JsonDecoder.isExactly(s)),
                'leftStyle',
            ),
        ),
    },
    'AdvertisingToPageMessage',
)

const closeAdSlotDecoder = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'close-ad-slot' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('close-ad-slot'),
        target: JsonDecoder.oneOf(
            (
                [
                    'apn-large-skyscraper',
                    'apn-large-leaderboard',
                    'apn-medium-leaderboard',
                    'apn-large-content-ad',
                    'apn-medium-content-ad',
                    'apn-small-content-ad',
                    'apn-large-ad-detail',
                    'apn-medium-ad-detail',
                    'apn-small-ad-detail',
                    'apn-large-filter-ad',
                    'apn-medium-filter-ad',
                    'apn-large-channel-branding',
                    'apn-large-bottom-ad',
                    'apn-small-topic-worlds',
                    'apn-large-result-list-1',
                    'apn-large-result-list-2',
                    'apn-large-result-list-3',
                    'apn-medium-result-list-1',
                    'apn-medium-result-list-2',
                    'apn-medium-result-list-3',
                    'apn-small-result-list-1',
                    'apn-small-result-list-2',
                    'apn-small-result-list-3',
                    'apn-small-result-list-scrolling-1',
                    'apn-small-result-list-scrolling-2',
                    'apn-large-aza-1',
                    'apn-medium-aza-1',
                    'apn-large-home-special-format',
                    'apn-skyscraper',
                    'apn-leaderboard',
                    'apn-tablet-leaderboard',
                    'apn-content-ad',
                    'apn-tablet-ad-detail-content-ad',
                    'apn-ad-detail-content-ad',
                    'apn-ad-detail',
                    'apn-filter-ad',
                    'apn-channel-branding',
                    'apn-bottom-ad',
                    'apn-topic-worlds',
                    'apn-fake-ad-1',
                    'apn-fake-ad-2',
                    'apn-fake-ad-3',
                    'apn-tablet-result-list-1',
                    'apn-tablet-result-list-2',
                    'apn-result-list-1',
                    'apn-result-list-2',
                    'apn-result-list-3',
                    'apn-aza-1',
                    'apn-aza-2',
                    'apn-home-special-format',
                    'apn-message-sent',
                    'apn-scrolling-position-1',
                    'apn-scrolling-position-2',
                ] as const
            ).map((s) => JsonDecoder.isExactly(s)),
            'ad slot',
        ),
    },
    'AdvertisingToPageMessage',
)

const applyDecoder = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'apply' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('apply'),
    },
    'AdvertisingToPageMessage',
)

const gradientDecoder = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'set-header-gradient' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('set-header-gradient'),
    },
    'AdvertisingToPageMessage',
)

const changeAdSlotStyleCustomDecoder1 = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'change-ad-slot-style' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('change-ad-slot-style'),
        target: JsonDecoder.oneOf(
            (['apn-large-skyscraper', 'apn-skyscraper'] as const).map((s) => JsonDecoder.isExactly(s)),
            'ad slot',
        ),
        style: JsonDecoder.isExactly('custom'),
        position: positionFixedOrAbsoluteDecoder,
        marginLeft: stringOrNumberDecoder,
        marginTop: stringOrNumberDecoder,
        marginRight: stringOrNumberDecoder,
        marginBottom: stringOrNumberDecoder,
    },
    'change ad slot custom',
)

const changeAdSlotStyleCustomDecoder2 = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'change-ad-slot-style' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('change-ad-slot-style'),
        target: JsonDecoder.oneOf(
            (['apn-large-skyscraper', 'apn-skyscraper'] as const).map((s) => JsonDecoder.isExactly(s)),
            'ad slot',
        ),
        style: JsonDecoder.isExactly('custom'),
        position: positionFixedOrAbsoluteDecoder,
        marginLeft: stringOrNumberDecoder,
        marginTop: stringOrNumberDecoder,
        marginRight: stringOrNumberDecoder,
        height: stringOrNumberDecoder,
    },
    'change ad slot custom',
)

const changeAdSlotStyleCustomDecoder3 = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'change-ad-slot-style' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('change-ad-slot-style'),
        target: JsonDecoder.oneOf(
            (['apn-large-skyscraper', 'apn-skyscraper'] as const).map((s) => JsonDecoder.isExactly(s)),
            'ad slot',
        ),
        style: JsonDecoder.isExactly('custom'),
        position: positionFixedOrAbsoluteDecoder,
        marginLeft: stringOrNumberDecoder,
        marginTop: stringOrNumberDecoder,
        marginBottom: stringOrNumberDecoder,
        width: stringOrNumberDecoder,
    },
    'change ad slot custom',
)

const changeAdSlotStyleCustomDecoder4 = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'change-ad-slot-style' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('change-ad-slot-style'),
        target: JsonDecoder.oneOf(
            (['apn-large-skyscraper', 'apn-skyscraper'] as const).map((s) => JsonDecoder.isExactly(s)),
            'ad slot',
        ),
        style: JsonDecoder.isExactly('custom'),
        position: positionFixedOrAbsoluteDecoder,
        marginLeft: stringOrNumberDecoder,
        marginTop: stringOrNumberDecoder,
        width: stringOrNumberDecoder,
        height: stringOrNumberDecoder,
    },
    'change ad slot custom',
)

const changeAdSlotStyleCustomDecoder = JsonDecoder.oneOf(
    [changeAdSlotStyleCustomDecoder1, changeAdSlotStyleCustomDecoder2, changeAdSlotStyleCustomDecoder3, changeAdSlotStyleCustomDecoder4],
    'change ad slot custom',
)

const requestAdvertisingParametersDecoder = JsonDecoder.objectStrict<
    ExtractedAdvertisingToPageMessage<{ type: 'request-advertising-parameters' }>
>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('request-advertising-parameters'),
    },
    'AdvertisingToPageMessage',
)

const requestDataParametersDecoder = JsonDecoder.objectStrict<ExtractedAdvertisingToPageMessage<{ type: 'request-data' }>>(
    {
        protocol: protocolDecoder,
        type: JsonDecoder.isExactly('request-data'),
    },
    'AdvertisingToPageMessage',
)

// we check if the message has correct types specified in AdvertisingToPageMessage in order to avoid runtime problems when adverts send wrongly structured data
export const advertisingToPageMessageDecoder = JsonDecoder.oneOf<AdvertisingToPageMessage>(
    [
        setBackgroundTransparentDecoder,
        setBackgroundColorDecoder,
        setForegroundColorDecoder,
        setBorderDecoder,
        closeAdSlotDecoder,
        applyDecoder,
        changeAdSlotStylePresetDecoder,
        changeAdSlotStyleCustomDecoder,
        setIframeContentDecoder,
        requestAdvertisingParametersDecoder,
        requestDataParametersDecoder,
        gradientDecoder,
    ],
    'AdvertisingToPageMessage',
)

export const advertisingToPageMessageProtocolOnlyDecoder = JsonDecoder.objectStrict<Pick<AdvertisingToPageMessage, 'protocol'>>(
    {
        protocol: protocolDecoder,
    },
    'AdvertisingToPageMessageProtocol',
)

export const sanitizeAdvertisingToPageMessage = (message: unknown): AdvertisingToPageMessage | null => {
    try {
        const result = advertisingToPageMessageDecoder.decode(message)
        if (result instanceof Ok) {
            return result.value
        } else {
            return null
        }
    } catch (_error) {
        return null
    }
}

export const hasCorrectAdvertisingProtocol = (message: unknown): boolean => {
    try {
        const result = advertisingToPageMessageProtocolOnlyDecoder.decode(message)
        return result instanceof Ok
    } catch (_error) {
        return false
    }
}
