import { createGlobalStyle } from 'styled-components'
import { safari15CssColors } from './safari15CssColors'

export const AppStyle = createGlobalStyle`

html {
    line-height: ${(p) => p.theme.lineHeights.paragraph};
}

body {
    margin: 0;
    background: ${(p) => p.theme.colors.semantic.surface.DEFAULT};
    color: ${(p) => p.theme.colors.palette.verydarkgrey};
}

hr {
    height: 1px;
    margin: 0;
    border: 0;
    background-color: ${(p) => p.theme.colors.palette.owl};
}

@media print {
    * {
        color-adjust: exact;
    }
}


ul {
    padding-left: 18px;
    margin: 0;

    li {
        word-wrap: break-word;

        &.active {
            font-weight: ${(p) => p.theme.fontWeights.bold};
        }
    }
}

input::placeholder {
  color: ${(p) => p.theme.colors.palette.darkgrey};
}

input:-ms-input-placeholder {
  color: ${(p) => p.theme.colors.palette.darkgrey};
}

${safari15CssColors}
`
