import { StoryblokComponentType } from '@storyblok/js/dist/types'
import React, { FunctionComponent } from 'react'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { Hotjar } from '@wh/common/chapter/components/Hotjar/Hotjar'

interface StoryblokHotjarIntegration extends StoryblokComponentType<string> {}

interface Props extends StoryblokDefaultProps<StoryblokHotjarIntegration> {}

export const StoryblokHotjarIntegration: FunctionComponent<Props> = ({ story }) => {
    return <Hotjar id={`${story?.id}`} siteId={317358} enabled={true} />
}
