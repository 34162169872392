import React, { FunctionComponent, useState } from 'react'
import { render, StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import styled from 'styled-components'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import ArrowRightThick from '@wh-components/icons/ArrowRightThick'
import ArrowDownThick from '@wh-components/icons/ArrowDownThick'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'

export interface StoryblokCollapsibleFaqBlok extends StoryblokComponentType<string> {
    question: string
    answer: StoryblokRichtext
}

interface Props extends StoryblokDefaultProps<StoryblokCollapsibleFaqBlok> {}

export const StoryblokCollapsibleFaq: FunctionComponent<Props> = ({ blok }) => {
    const [isAnswerExpanded, setAnswerExpanded] = useState(false)
    const toggleAnswerExpanded = () => setAnswerExpanded(!isAnswerExpanded)

    const itemId = `answer-${blok._uid}`

    return (
        <div {...storyblokEditable(blok as unknown as SbBlokData)}>
            <StyledQuestion key="question" onClick={toggleAnswerExpanded}>
                <StyledButton aria-expanded={isAnswerExpanded} aria-controls={itemId}>
                    {isAnswerExpanded ? <ArrowDownThick color="palette.verydarkgrey" /> : <ArrowRightThick color="palette.verydarkgrey" />}
                </StyledButton>
                <strong>{blok.question}</strong>
            </StyledQuestion>
            {isAnswerExpanded && (
                <StyledAnswer key="answer" id={itemId}>
                    {render(blok.answer)}
                </StyledAnswer>
            )}
        </div>
    )
}

const StyledButton = styled.button`
    background-color: transparent;
    border: none;
    width: 2em;
`

const StyledQuestion = styled.p`
    margin-left: 2em;
    text-indent: -2em;
`

const StyledAnswer = styled.div`
    margin-left: 2em;
`
