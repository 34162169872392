import styled, { DataAttributes } from 'styled-components'
import { FlexboxProps, flexbox } from '@wh-components/system/flexbox'
import { LayoutProps, layout } from '@wh-components/system/layout'
import { PositionProps, position } from '@wh-components/system/position'
import { space, SpaceProps } from '@wh-components/system/space'
import { border, BorderProps } from '@wh-components/system/border'
import { testIdAttribute, TestProps } from '@wh-components/core/common'

export const TransparentButton = styled.button.attrs<TestProps & DataAttributes>(testIdAttribute)<
    LayoutProps & SpaceProps & PositionProps & FlexboxProps & BorderProps & TestProps
>`
    margin: 0;
    padding: 0;
    display: block;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: 0;
    background: none;
    line-height: 1.5;
    text-decoration: none;
    outline: none;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    text-align: left;
    font-size: ${(p) => p.theme.fontSizes.m};

    &:focus {
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.palette.primary.disabled};
    }
    &:focus:not(:focus-visible) {
        box-shadow: none;
    }

    ${layout}
    ${space}
    ${position}
    ${flexbox}
    ${border}
`
