import App, { AppContext, AppInitialProps } from 'next/app'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { HireComment } from '@wh/common/chapter/components/HireComment/HireComment'
import {
    ExpressNextAppContext,
    GetInitialPropsReturnType,
    OptionalPageOptions,
    PropsIndexSignature,
    RequestWithOptimizelyDatafile,
} from '@wh/common/chapter/types/nextJS'
import { TrackingLibraryLoader } from '@wh/common/chapter/components/TrackingLibraryLoader/TrackingLibraryLoader'
import { PerformanceApi } from '@wh/common/chapter/components/PerformanceApi/PerformanceApi'
import { Request } from 'express'
import { BbxCookie, getBbxCookieFromRequest } from '@wh/common/chapter/types/cookies'
import { NProgress } from '@wh/common/chapter/components/NProgress/NProgress'
import { HEADER_NAME_GET_INITAL_PROPS_DURATION_MILLISECONDS } from '@wh/common/chapter/lib/config/constants'
import { getQueryParam } from '@wh/common/chapter/lib/urlHelpers'
import { AdvertisingStateProvider } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { captureException } from '@wh/common/chapter/components/Sentry/sentry'
import { initSentry } from '@bbx/common/lib/sentry'
import { WillhabenThemeProvider } from '@wh-components/core/theme/WillhabenThemeProvider'
import { parseUserAgent, UserAgentProvider } from '@wh/common/chapter/components/UserAgentProvider/UserAgentProvider'
import { GlobalStyle } from '@wh-components/core/GlobalStyle'
import { ExternalStyle } from '@wh-components/core/ExternalStyle'
import { useUserAgent } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { BaseModal } from '@wh-components/core/Modal/BaseModal'
import Router from 'next/router'
import { ToastContainer } from '@wh-components/core/Toast/Toast'
import { FeatureToggle, FeatureToggleProvider } from '@wh/common/chapter/components/FeatureToggleProvider/FeatureToggleProvider'
import { getFeatureTogglesFromCtx } from '@wh/common/chapter/lib/toggles/feature-toggles'
import {
    CookieProvider,
    getWhitelistedCookiesFromRequestOrDocument,
    WhitelistedCookies,
} from '@wh/common/chapter/components/CookieProvider/CookieProvider'
import { DebugFlagProvider } from '@wh/common/chapter/components/DebugFlagProvider/DebugFlagProvider'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { getVerticalListAndStoreCookieResponse } from '@wh/common/chapter/api/verticalApiClient'
import { GlobalStateProvider, UserProfileState } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { VerticalList } from '@wh/common/chapter/types/verticals'
import { getUserInfo } from '@wh/common/chapter/api/userApiClient'
import { DataFetcher } from '@wh/common/chapter/components/DataFetcher/DataFetcher'
import { DidomiLoader, DidomiStyles } from '@wh/common/chapter/components/Didomi/DidomiLoader'
import { DidomiProvider } from '@wh/common/chapter/components/Didomi/DidomiContext'
import { SSGProvider } from '@wh/common/chapter/components/SSGProvider/SSGProvider'
import { mapUserInfoToProfileData } from '@wh/common/chapter/lib/mapUserInfoToProfileData'
import { CustomOptimizelyProvider } from '@wh/common/chapter/components/Optimizely/CustomOptimizelyProvider'
import { storyBlokComponents, storyBlokPageTypes } from '@wh/common/cms/lib/storyblokConfig'
import { theme } from '@wh-components/core/theme'
import { storyblokInit } from '@storyblok/react'
import { StoryblokTrendSlider } from '@bbx/search-journey/sub-domains/start-page/components/widget/StoryblokTrendSlider'
import { saveScrollPos, restoreScrollPos } from '@wh/common/chapter/lib/apptsx/scroll-position'
import { BrandMetrics } from '@wh/common/chapter/components/BrandMetrics/BrandMetricsLoader'
import { StoryblokSearchResultList } from '@bbx/search-journey/common/components/StoryblokSearchResultList/StoryblokSearchResultList'
import { ClarityTrackingUat } from '@wh/common/chapter/components/ClarityTrackingUat/ClarityTrackingUat'
import { ThemeSetterProvider } from '@wh/common/chapter/components/ThemeSetter'

const ClientHeaderFromUserAgent: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const screenSize = useUserAgent().screenSize
    // set header on server side for api-requests made in react components
    globalThis.currentScreenSize = screenSize

    return <React.Fragment>{children}</React.Fragment>
}

const shouldFetchUserInfoOnServer = (request?: Request): boolean => {
    return !!getBbxCookieFromRequest(BbxCookie.BBX_JSESSIONID, request)
}

interface BaseMyAppProps {
    userAgent: string
    toggles: Record<string, boolean>
    initialOptimizelyDatafile: string | undefined
    cookies: WhitelistedCookies
    verticalsInfo: VerticalList | undefined
    profileData: UserProfileState
}

interface MyAppProps extends BaseMyAppProps {
    showVerificationAlert: boolean
    is404: boolean
    isSSG: boolean
}

interface MyAppPropsStaticExport extends Partial<BaseMyAppProps> {
    showVerificationAlert: boolean
    is404: boolean
    profileData: UserProfileState
    isSSG: boolean
}

interface MyAppPropsWithErr extends MyAppProps {
    err: unknown
}

interface MyAppPropsWithErrStaticExport extends MyAppPropsStaticExport {
    err: unknown
}

interface MyAppState {
    toggles: undefined | Record<FeatureToggle, boolean>
    cookies: undefined | WhitelistedCookies
}

initSentry()
storyblokInit({
    components: {
        ...storyBlokPageTypes,
        ...storyBlokComponents,
        trendSliderInContentPage: StoryblokTrendSlider,
        searchResultList: StoryblokSearchResultList,
    },
})

class MyApp extends App<MyAppPropsWithErr | MyAppPropsWithErrStaticExport, {}, MyAppState> {
    constructor(props: MyAppPropsWithErrStaticExport & AppInitialProps & ExpressNextAppContext) {
        super(props)
        this.state = { toggles: props.toggles, cookies: props.cookies }
    }

    static async getInitialProps(appCtx: AppContext): Promise<(AppInitialProps & MyAppProps) | (AppInitialProps & MyAppPropsStaticExport)> {
        // unfortunately the app context cannot be directly defined as ExpressNextAppContext due to some typing issue
        const { Component, ctx } = appCtx as ExpressNextAppContext
        let pageProps: PropsIndexSignature = {}
        const isServer = !!ctx.req

        const startTime = Date.now()
        const userAgent = (ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent) || ''

        // set header on server side for api-requests made in all the getInitialProps()
        const { screenSize } = parseUserAgent(userAgent)
        globalThis.currentScreenSize = screenSize

        // On the first API request, cookie tokens might not be available, so we will store the cookies
        // from the API response and pass them on to the current response.
        const verticalsInfoPromise = getVerticalListAndStoreCookieResponse(ctx.req, ctx.res)
        /* For statically exported pages, this method (getInitialProps) is called at build-time for the
         exported pages.
         Usually the request and response-objects used here are filled on the Server and undefined in the Browser
         When called at build-time, the objects are not undefined, but nearly all fields are empty objects.

         The thing is: in Dev-Mode, the statically exported pages are also just rendered on demand, like normal SSR
         Next.js stupidly fills the Request/Response objects normally in that case, therefore this method here
         behaves differently in Dev-Mode vs Production-Mode for the static pages.

         Therefore we make a big if-block around all the request-dependent code that doesn't make sense for
          statically exported pages
        */
        const ComponentWithStaticProps = Component as typeof Component & { hasStaticProps?: boolean }
        const isSSG = ComponentWithStaticProps.hasStaticProps && isServer
        if (isSSG) {
            await verticalsInfoPromise
            const verticalsInfo = await verticalsInfoPromise
            return { showVerificationAlert: false, is404: false, isSSG: true, verticalsInfo, profileData: 'unsure' } as AppInitialProps &
                MyAppPropsStaticExport
        } else {
            // INFO1: it is VERY important to filter the cookies from the request, because it includes HttpOnly cookies that then would be added to the __NEXT_DATA__ of the document and be accessible to any javascript => security issue
            // INFO2: we only refresh cookies on the initial server render and on client side navigation (when getInitialProps is executed), we do not refresh it when a cookie is set - for once we don't currently need that, and more imporantly we don't want to cause rerenders of the whole app when a cookie is set
            const cookies = getWhitelistedCookiesFromRequestOrDocument(ctx.req, typeof document !== 'undefined' ? document : undefined)

            let userInfoPromise

            /*
              It's not possible to pass data from the getInitialProps here to the getServerSideProps-method in a page.
              Therefore we need to fetch the profileData-object inside getServerSideProps if we need it there.
              To not fetch it twice, we set the flag "hasServerSidePropsWithUserData" in the page and don't fetch it here if it's true.

              The props returned from here and from getServerSideProps are merged into one object and both are available in the React-page component.
            */
            const ComponentWithServerSideProps = Component as typeof Component & { hasServerSidePropsWithUserData?: boolean }
            const dontFetchUserInfo = ComponentWithServerSideProps.hasServerSidePropsWithUserData
            if ((shouldFetchUserInfoOnServer(ctx.req) || !isServer) && !dontFetchUserInfo) {
                userInfoPromise = getUserInfo(ctx.req)
            }

            const [verticalsInfo, userInfo] = await Promise.all([
                verticalsInfoPromise.catch(() => undefined),
                userInfoPromise?.catch(() => undefined),
            ])
            const toggles = await getFeatureTogglesFromCtx('search', ctx.req)

            const optimizelyDatafile = (ctx.req as RequestWithOptimizelyDatafile)?.optimizelyDatafile

            if (Component.getInitialProps) {
                pageProps =
                    (await (Component.getInitialProps({
                        ...ctx,
                        profileData: mapUserInfoToProfileData(userInfo),
                    }) as GetInitialPropsReturnType)) || {}
            }

            if (ctx.res?.header && !ctx.res?.finished) {
                const durationSeconds = Date.now() - startTime
                ctx.res.header(HEADER_NAME_GET_INITAL_PROPS_DURATION_MILLISECONDS, durationSeconds.toString())
            }

            const showVerificationAlert = getQueryParam(appCtx.router.asPath, 'emailVerified') === 'true'

            return {
                pageProps: { ...pageProps },
                showVerificationAlert,
                userAgent,
                isSSG: false,
                toggles,
                initialOptimizelyDatafile: optimizelyDatafile,
                cookies,
                is404: !!pageProps.is404,
                verticalsInfo,
                profileData: mapUserInfoToProfileData(userInfo),
            }
        }
    }

    async componentDidMount() {
        // set header after SSG
        if (navigator?.userAgent) {
            const { screenSize } = parseUserAgent(navigator.userAgent)
            globalThis.currentScreenSize = screenSize
        }

        // to indicate rehydration is done and we are interactive
        // this is needed for selenium
        document.body.setAttribute('data-interactive', 'true')
        BaseModal.setAppElement('#__next')

        if (storageAvailable('sessionStorage')) {
            this.applyScrollRestoration()
        }

        /**
         * Rehydrate FeatureToggleProvider on SSG pages
         * if toggles are undefined in initialState.
         */

        if (this.state.toggles === undefined) {
            this.setFeatureToggleState()
        }

        if (this.state.cookies === undefined) {
            this.setCookies()
        }
    }

    async setFeatureToggleState() {
        const toggles = await getFeatureTogglesFromCtx('search', undefined)
        this.setState(() => {
            return {
                toggles,
            }
        })
    }

    async setCookies() {
        const cookies = getWhitelistedCookiesFromRequestOrDocument(undefined, typeof document !== 'undefined' ? document : undefined)

        this.setState(() => {
            return {
                cookies,
            }
        })
    }

    // this is needed to manually manage scroll position restoration in case of having to run getInitialProps on browser back
    // see https://github.com/zeit/next.js/issues/3303 and https://gist.github.com/claus/992a5596d6532ac91b24abe24e10ae81 for more info
    applyScrollRestoration() {
        if ('scrollRestoration' in window.history) {
            let shouldScrollRestore = false
            window.history.scrollRestoration = 'manual'
            restoreScrollPos(this.props.router.asPath)

            const onBeforeUnload = (event: BeforeUnloadEvent) => {
                saveScrollPos(this.props.router.asPath)
                delete event.returnValue
            }

            const onRouteChangeStart = () => {
                saveScrollPos(this.props.router.asPath)
            }

            const onRouteChangeComplete = (url: string) => {
                if (shouldScrollRestore) {
                    shouldScrollRestore = false
                    restoreScrollPos(url)
                }
            }

            window.addEventListener('beforeunload', onBeforeUnload)
            Router.events.on('routeChangeStart', onRouteChangeStart)
            Router.events.on('routeChangeComplete', onRouteChangeComplete)
            Router.beforePopState(() => {
                shouldScrollRestore = true
                return true
            })
        }
    }

    // This reports errors thrown while rendering components
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        captureException(error, errorInfo)
        throw error
    }

    render() {
        // err is needed as Workaround for https://github.com/zeit/next.js/issues/8592
        const {
            Component,
            pageProps,
            showVerificationAlert,
            userAgent,
            err,
            is404,
            isSSG,
            router,
            verticalsInfo,
            profileData,
            initialOptimizelyDatafile,
        } = this.props

        const modifiedPageProps = { ...pageProps, err }
        const { toggles, cookies } = this.state

        const ComponentWithOptions = Component as typeof Component & OptionalPageOptions
        const PageLayout: typeof Layout = ComponentWithOptions.Layout || Layout

        return (
            <React.Fragment>
                <DebugFlagProvider url={router.asPath}>
                    <DidomiProvider>
                        <CookieProvider cookies={cookies}>
                            <ThemeSetterProvider>
                                <DidomiStyles />
                                <GlobalStyle />
                                <ExternalStyle />
                                <PerformanceApi />
                                <TrackingLibraryLoader />
                                <HireComment />
                                <NProgress options={{ trickleSpeed: 200 }} showAfterMs={300} />
                                <ClarityTrackingUat />
                                <GlobalStateProvider
                                    initialProfileData={profileData ?? ((pageProps as PropsIndexSignature).profileData as UserProfileState)}
                                    initialVerticalsInfo={verticalsInfo?.vertical}
                                >
                                    <DataFetcher />
                                    <WillhabenThemeProvider theme={theme}>
                                        <AdvertisingStateProvider>
                                            <UserAgentProvider userAgent={userAgent}>
                                                <SSGProvider isSSG={isSSG}>
                                                    <FeatureToggleProvider toggles={toggles}>
                                                        <CustomOptimizelyProvider
                                                            optimizelyDatafile={initialOptimizelyDatafile}
                                                            datafileUrl="/bbx-search/nodeapi/optimizely-datafile"
                                                        >
                                                            <ClientHeaderFromUserAgent>
                                                                <PageLayout
                                                                    showVerificationAlert={showVerificationAlert}
                                                                    is404={is404}
                                                                    allowAndroidBanner={ComponentWithOptions.allowAndroidBanner}
                                                                    preventAdminAlert={ComponentWithOptions.preventAdminAlert}
                                                                    verticalLinksType={ComponentWithOptions.verticalLinksType}
                                                                >
                                                                    <Component {...modifiedPageProps} />
                                                                </PageLayout>
                                                                <ToastContainer position="top-left" />
                                                            </ClientHeaderFromUserAgent>
                                                        </CustomOptimizelyProvider>
                                                    </FeatureToggleProvider>
                                                    {!PageLayout.appEmbedded && <DidomiLoader />}
                                                    <BrandMetrics />
                                                </SSGProvider>
                                            </UserAgentProvider>
                                        </AdvertisingStateProvider>
                                    </WillhabenThemeProvider>
                                </GlobalStateProvider>
                            </ThemeSetterProvider>
                        </CookieProvider>
                    </DidomiProvider>
                </DebugFlagProvider>
            </React.Fragment>
        )
    }
}

export default MyApp
