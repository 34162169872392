import React, { Fragment, memo, PropsWithChildren, useCallback, useEffect, useState } from 'react'

declare global {
    interface Window {
        updateDOM?: () => void
    }
}

type Theme = 'light' | 'light-contrast' | 'dark'

let updateDOM: (() => void) | undefined

const STORAGE_KEY = 'bbx-theme'

export interface ThemeSwitcherState {
    theme: Theme
    changeTheme: (newTheme: Theme) => void
}

export const ThemeSwitcherContext = React.createContext<ThemeSwitcherState>({
    theme: 'light',
    changeTheme: () => {
        throw new Error('ThemeSetterProvider not found')
    },
})

/**
 * restores the selected theme from localStorage.
 **/
export const ThemeSetterProvider = (props: PropsWithChildren) => {
    const [theme, setTheme] = useState<Theme>(
        () =>
            // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
            ((typeof localStorage !== 'undefined' && localStorage !== null && localStorage.getItem(STORAGE_KEY)) ?? 'light') as Theme,
    )

    useEffect(() => {
        // store global functions to local variables to avoid any interference
        updateDOM = window.updateDOM
        const storageEventListener = (e: StorageEvent): void => {
            if (e.key === STORAGE_KEY) {
                setTheme(e.newValue as Theme)
                updateDOM?.()
            }
        }
        // sync all open tabs - info this only triggers for other tabs, not the current one
        window.addEventListener('storage', storageEventListener)

        return () => {
            window.removeEventListener('storage', storageEventListener)
        }
    }, [])

    const changeTheme = useCallback((newTheme: Theme) => {
        setTheme(newTheme)
        localStorage.setItem(STORAGE_KEY, newTheme)
        document.cookie = `${STORAGE_KEY}=${newTheme};path=/;domain=.${window.location.hostname};max-age=${365 * 24 * 60 * 60 * 1000}`
        updateDOM?.()
    }, [])

    return (
        <Fragment>
            <Script />
            <ThemeSwitcherContext.Provider value={{ theme, changeTheme }}>{props.children}</ThemeSwitcherContext.Provider>
        </Fragment>
    )
}

/**
 * This script is injected as-is into the DOM, therefore it cannot use any outside variables.
 *
 * modifyTransition modifies transition globally to avoid patched transitions.
 *
 * getComputedStyle forces a restyle.
 *
 * taken from https://github.com/vercel/next.js/pull/66926
 **/
export const NoFOUCScript = (storageKey: string) => {
    const modifyTransition = () => {
        const css = document.createElement('style')
        css.textContent = '*,*:after,*:before{transition:none !important;}'
        document.head.appendChild(css)

        return () => {
            getComputedStyle(document.body)
            setTimeout(() => document.head.removeChild(css), 1)
        }
    }

    window.updateDOM = () => {
        try {
            const restoreTransitions = modifyTransition()
            const theme = typeof localStorage !== 'undefined' && localStorage !== null ? localStorage.getItem(storageKey) : undefined
            if (theme) {
                document.documentElement.setAttribute('data-wh-theme', theme)
                document.cookie = `${storageKey}=${theme};path=/;domain=.${window.location.hostname};max-age=${365 * 24 * 60 * 60 * 1000}`
            } else {
                const themeFromCookie = document.cookie
                    .split(';')
                    .find((c) => c.trim().startsWith(`${storageKey}=`))
                    ?.split('=')[1]
                if (themeFromCookie) {
                    document.documentElement.setAttribute('data-wh-theme', themeFromCookie)
                    if (typeof localStorage !== 'undefined' && localStorage !== null) {
                        localStorage.setItem(storageKey, themeFromCookie)
                    }
                } else {
                    const userPreferenceContrastMode = window.matchMedia('(prefers-contrast: more)').matches
                    if (userPreferenceContrastMode) {
                        document.documentElement.setAttribute('data-wh-theme', 'light-contrast')
                        if (typeof localStorage !== 'undefined' && localStorage !== null) {
                            document.cookie = `${storageKey}=light-contrast;path=/;domain=.${window.location.hostname};max-age=${365 * 24 * 60 * 60 * 1000}`
                            localStorage.setItem(storageKey, 'light-contrast')
                            localStorage.setItem('bbx-theme-auto-enabled', 'light-contrast')
                        }
                    } else {
                        const userPrefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
                        if (userPrefersDarkMode) {
                            document.documentElement.setAttribute('data-wh-theme', 'dark')
                            if (typeof localStorage !== 'undefined' && localStorage !== null) {
                                document.cookie = `${storageKey}=dark;path=/;domain=.${window.location.hostname};max-age=${365 * 24 * 60 * 60 * 1000}`
                                localStorage.setItem(storageKey, 'dark')
                                localStorage.setItem('bbx-theme-auto-enabled', 'dark')
                            }
                        } else {
                            document.documentElement.removeAttribute('data-wh-theme')
                        }
                    }
                }
            }
            restoreTransitions()
        } catch (e) {
            console.error('Error applying theme', e)
        }
    }

    window.updateDOM()
}

const Script = memo(() => (
    <script
        dangerouslySetInnerHTML={{
            __html: `(${NoFOUCScript.toString()})('${STORAGE_KEY}')`,
        }}
    />
))
