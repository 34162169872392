import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

interface StoryblokTitleCard extends StoryblokComponentType<string> {
    title: string
    children: SbBlokData[]
}

interface Props extends StoryblokDefaultProps<StoryblokTitleCard> {}

export const StoryblokTitleCard: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <Container {...storyblokEditable(blok as unknown as SbBlokData)}>
            <Title>{blok.title}</Title>
            <Content>
                {blok.children.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
                ))}
            </Content>
        </Container>
    )
}

const Container = styled.div`
    overflow: hidden;
    border: 1px solid ${(p) => p.theme.colors.palette.owl};
    border-radius: ${(p) => p.theme.borderRadius}px;
`

const Title = styled.div`
    padding: ${(p) => p.theme.space.s}px ${(p) => p.theme.space.m}px;
    font-weight: bold;
    background-color: ${(p) => p.theme.colors.palette.polarbear};
    border-bottom: 1px solid ${(p) => p.theme.colors.palette.owl};
    font-size: ${(p) => p.theme.fontSizes.l};
`

const Content = styled.div`
    padding: ${(p) => p.theme.space.m}px;
`
