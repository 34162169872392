import { storyblokCallActionEvent } from '../../lib/storyblokCallActionEvent'
import { richTextComponents } from '../../lib/storyblokConfig'
import { storyblokExtractDimensionsFromImageUrl } from '../../lib/storyblokExtractDimensionsFromImageUrl'
import { ISbStoryData, SbBlokData } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import { Heading } from '@wh-components/core/Heading/Heading'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { Text } from '@wh-components/core/Text/Text'
import { MarginProps } from '@wh-components/system/space'
import { AnchorLink, ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import React, { Fragment, ReactNode } from 'react'
import {
    MARK_BOLD,
    MARK_CODE,
    MARK_ITALIC,
    MARK_LINK,
    MARK_STRIKE,
    MARK_STYLED,
    MARK_UNDERLINE,
    NODE_HEADING,
    NODE_HR,
    NODE_IMAGE,
    NODE_OL,
    NODE_PARAGRAPH,
    NODE_UL,
    render,
} from 'storyblok-rich-text-react-renderer'
import styled, { css } from 'styled-components'

type RichTextProps = {
    blok: SbBlokData
    richTextKey: string
    inline?: boolean
    fontSize?: string
    id?: string
    story?: ISbStoryData
}

// workaround for next.js swc bug, needs to be a function instead of a const because of import cycle: https://github.com/vercel/next.js/issues/46734
export function InternalRichText({ blok, richTextKey, inline, fontSize, id, story, ...props }: RichTextProps & MarginProps) {
    const content = render(blok[richTextKey], {
        nodeResolvers: {
            [NODE_HEADING]: (children, { level }) => {
                const textAndClassName = extractTextAndClassName(children)
                if (!textAndClassName?.text) {
                    return null
                }
                const { text, className } = textAndClassName
                return <Heading marginBottom="s" marginTop={level !== 1 ? 'l' : undefined} text={text} level={level} color={className} />
            },
            [NODE_PARAGRAPH]: (children) => {
                if (inline) {
                    return <Fragment>{children}</Fragment>
                }
                return (
                    <Text as="p" marginBottom="m" fontSize={fontSize} lineHeight={1.5}>
                        {children ?? <Fragment>&nbsp;</Fragment>}
                    </Text>
                )
            },
            [NODE_IMAGE]: (_, { src, alt, title }) => {
                const dimensions = storyblokExtractDimensionsFromImageUrl(src)
                return src ? (
                    <ResponsiveImage
                        src={`${src}/m/`}
                        alt={alt}
                        title={title}
                        width={dimensions?.width}
                        height={dimensions?.height}
                        cssHeight="auto"
                        cssWidth="100%"
                        display="inline-block"
                    />
                ) : null
            },
            [NODE_HR]: () => {
                return <Divider marginVertical="l" />
            },
            [NODE_UL]: (children) => {
                return <UnorderedList>{children}</UnorderedList>
            },
            [NODE_OL]: (children) => {
                return <OrderedList>{children}</OrderedList>
            },
        },
        markResolvers: {
            [MARK_LINK]: (children, { href, target, linktype }) => {
                const linkTag = extractTagFromHref(href)

                const onClick = () => {
                    if (story?.full_slug) {
                        storyblokCallActionEvent(linkTag.tag, story.full_slug)
                    }
                }

                if (linktype === 'email') {
                    return (
                        <ServerRoutingAnchorLink type="anchor" href={`mailto:${linkTag.href}`} onClick={linkTag.tag ? onClick : undefined}>
                            {children}
                        </ServerRoutingAnchorLink>
                    )
                }

                return (
                    <AnchorLink
                        type="anchor"
                        href={linkTag.href}
                        target={target}
                        clientSideRouting={false}
                        onClick={linkTag.tag ? onClick : undefined}
                        rel="noopener noreferrer"
                    >
                        {children}
                    </AnchorLink>
                )
            },
            [MARK_BOLD]: (children) => <Text fontWeight="bold">{children}</Text>,
            [MARK_ITALIC]: (children) => <Text fontStyle="italic">{children}</Text>,
            [MARK_UNDERLINE]: (children) => (
                <Text
                    css={css`
                        text-decoration: underline;
                    `}
                >
                    {children}
                </Text>
            ),
            [MARK_STRIKE]: (children) => (
                <Text
                    css={css`
                        text-decoration: strike-through;
                    `}
                >
                    {children}
                </Text>
            ),
            [MARK_CODE]: (_) => <Text>Code Blocks are not supported, please contact your tribe to implement that</Text>,
            [MARK_STYLED]: (children, classNameProp) => {
                const classname = `${classNameProp.class}`

                if (classname.startsWith('highlight')) {
                    return (
                        <Text display="inline" backgroundColor={classname.substring('highlight'.length + 1)}>
                            {children}
                        </Text>
                    )
                }

                return (
                    <Text display="inline" color={classname}>
                        {children}
                    </Text>
                )
            },
        },
        blokResolvers: richTextComponents,
    }) as JSX.Element

    if (inline) {
        return content
    }

    return (
        <Box id={id} key={blok._uid} {...storyblokEditable(blok)} {...props}>
            {content}
        </Box>
    )
}

const extractTextAndClassName = (children: ReactNode) =>
    React.Children.map(children, (node): { text?: string; className?: string } => {
        if (typeof node === 'string') {
            return { text: node }
        } else if (node && typeof node === 'object' && 'props' in node) {
            return { text: node.props.children as string, className: node.props.color }
        } else {
            return {}
        }
    })?.[0]

const listMixin = css`
    margin: 0 0 ${(p) => p.theme.space.m}px 0;
    & li > ol,
    & li > ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }

    & li > p {
        // storyblok returns a paragraph inside each list-item and we don't want to have the margin like in normal <p>
        margin-bottom: 0;
    }
`

export const UnorderedList = styled.ul`
    ${listMixin}
`

export const OrderedList = styled.ol`
    padding-left: 18px;
    ${listMixin}
`

const tagHrefRegex = /^.*{.*}$/

export const extractTagFromHref = (href: string | undefined): { href: string | undefined; tag: string } => {
    if (!href || !tagHrefRegex.test(href)) {
        return { href, tag: '' }
    }

    const openingBracketIndex = href.indexOf('{')
    const closingBracketIndex = href.lastIndexOf('}')

    return { href: href.substring(0, openingBracketIndex), tag: href.substring(openingBracketIndex + 1, closingBracketIndex) }
}
