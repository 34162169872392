import { css } from 'styled-components'
import { theme } from '@wh-components/core/theme'

export const adFocusHoverStyles = (
    highlighted: boolean = false,
    outlineOffset: number = -3,
    hoverBackgroundColor: string = theme.colors.palette.babyseal,
) => {
    return css`
        transition: background-color 200ms;
        background-color: ${highlighted ? (p) => p.theme.colors.semantic.primary.container : undefined};

        &:hover {
            background-color: ${highlighted ? undefined : hoverBackgroundColor};
            box-shadow: ${highlighted
                ? // eslint-disable-next-line no-restricted-syntax
                  'inset 0 0 100px 100px rgba(0, 0, 0, 0.025)'
                : undefined};
        }

        &:focus-within {
            outline-style: solid;
            outline-offset: ${outlineOffset}px;
            outline-color: ${(p) => p.theme.colors.palette.primary.main};
            background-color: ${highlighted ? undefined : hoverBackgroundColor};
            box-shadow: ${highlighted
                ? // eslint-disable-next-line no-restricted-syntax
                  'inset 0 0 100px 100px rgba(0, 0, 0, 0.025)'
                : undefined};
            z-index: ${(p) => p.theme.zIndices.content + 2};
        }
    `
}
