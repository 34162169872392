import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { InternalRichText } from './InternalRichText'

interface StoryblokFactTableItem extends StoryblokComponentType<string> {
    name: SbBlokData[]
    text: SbBlokData[]
}

interface StoryblokFactTable extends StoryblokComponentType<string> {
    items: StoryblokFactTableItem[]
}

type Props = StoryblokDefaultProps<StoryblokFactTable>

export const StoryblokFactTable: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <Table {...storyblokEditable(blok as unknown as SbBlokData)}>
            <tbody>
                {blok.items.map((item) => (
                    <tr key={item._uid} {...storyblokEditable(item as unknown as SbBlokData)}>
                        <Th>
                            <InternalRichText blok={item as unknown as SbBlokData} richTextKey="name" inline={true} story={story} />
                        </Th>
                        <td>
                            <InternalRichText blok={item as unknown as SbBlokData} richTextKey="text" inline={true} story={story} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

const Table = styled.table`
    width: 100%;
    text-align: left;
`

const Th = styled.th`
    font-weight: ${(p) => p.theme.fontWeights.regular};
`
