import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import React, { FunctionComponent } from 'react'
import { Text } from '@wh-components/core/Text/Text'

interface StoryblokBlueBox extends StoryblokComponentType<string> {
    heading: string
    subtext: string
    image: SbBlokData[]
    children: SbBlokData[]
}

type Props = StoryblokDefaultProps<StoryblokBlueBox>

export const StoryblokBlueBox: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <Box
            {...storyblokEditable(blok as unknown as SbBlokData)}
            borderRadius="m"
            padding="m"
            backgroundColor="palette.primary.main"
            color="semantic.foreground.white"
        >
            <Text as="p" fontSize={{ phone: '28px', tablet: '21px' }} fontWeight="bold" fontStyle="italic" marginBottom={5}>
                {blok.heading}
            </Text>
            <Box display="flex" justifyContent="space-between" flexDirection="column">
                <Box display="flex" marginBottom="m">
                    <Text as="p" fontSize="l">
                        {blok.subtext}
                    </Text>
                    <div>
                        {blok.image.map((nestedBlok: SbBlokData) => (
                            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                        ))}
                    </div>
                </Box>
                {blok.children.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
                ))}
            </Box>
        </Box>
    )
}
