import React, { FunctionComponent, useEffect, useCallback, useRef } from 'react'
import NProgressLib, { NProgressOptions } from 'nprogress'
import { useRouter } from 'next/router'
import { createGlobalStyle, keyframes } from 'styled-components'

interface NProgressProps {
    color?: string
    showAfterMs?: number
    spinner?: boolean
    options?: Partial<NProgressOptions>
}

let timer: number | undefined

// eslint-disable-next-line no-restricted-syntax
export const NProgress: FunctionComponent<NProgressProps> = ({ color = '#2299DD', showAfterMs = 300, spinner = true, options }) => {
    const routeChangeStart = useCallback(() => {
        clearTimeout(timer)
        timer = window.setTimeout(() => NProgressLib.start(), showAfterMs)
    }, [showAfterMs])

    const routeChangeEnd = useCallback(() => {
        clearTimeout(timer)
        NProgressLib.done()
    }, [])

    const router = useRouter()
    const routerRef = useRef(router)
    routerRef.current = router

    useEffect(() => {
        if (options) {
            NProgressLib.configure(options)
        }

        routerRef.current.events.on('routeChangeStart', routeChangeStart)
        routerRef.current.events.on('routeChangeComplete', routeChangeEnd)
        routerRef.current.events.on('routeChangeError', routeChangeEnd)

        return () => {
            clearTimeout(timer)
            routerRef.current.events.off('routeChangeStart', routeChangeStart)
            routerRef.current.events.off('routeChangeComplete', routeChangeEnd)
            routerRef.current.events.off('routeChangeError', routeChangeEnd)
        }
    }, [options, routeChangeEnd, routeChangeStart])

    return <NProgressStyle color={color} spinner={spinner} />
}

const rotationKeyframes = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const NProgressStyle = createGlobalStyle<Required<Pick<NProgressProps, 'color' | 'spinner'>>>`
    #nprogress {
        pointer-events: none;
    }
    #nprogress .bar {
        background: ${(p) => p.color};
        position: fixed;
        z-index: 1031;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
    }
    #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px ${(p) => p.color}, 0 0 5px ${(p) => p.color};
        opacity: 1;
        transform: rotate(3deg) translate(0px, -4px);
    }
    #nprogress .spinner {
        display: ${(p) => (p.spinner ? 'block' : 'none')};
        position: fixed;
        z-index: 1031;
        top: 15px;
        right: 15px;
    }
    #nprogress .spinner-icon {
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        border: solid 2px transparent;
        border-top-color: ${(p) => p.color};
        border-left-color: ${(p) => p.color};
        border-radius: 50%;
        animation: ${rotationKeyframes} 400ms linear infinite;
    }
    .nprogress-custom-parent {
        overflow: hidden;
        position: relative;
    }
    .nprogress-custom-parent #nprogress .spinner,
    .nprogress-custom-parent #nprogress .bar {
        position: absolute;
    }
`
