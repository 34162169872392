import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import React, { Fragment, FunctionComponent } from 'react'
import { InternalRichText } from './InternalRichText'
import { Box } from '@wh-components/core/Box/Box'
import { StoryblokTextWithPictureAndLinks, StoryblokTextWithPictureAndLinksBlok } from './StoryblokTextWithPictureAndLinks'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { storyblokEditable } from '@storyblok/react'
import {
    StoryblokTextWithPictureAndLinksPicItem,
    StoryblokTextWithPictureAndLinksPicItemBlok,
} from './StoryblokTextWithPictureAndLinksPicItem'

export interface StoryblokTextWithPictureAndLinksListBlok extends StoryblokComponentType<string> {
    heading: StoryblokComponentType<string> & { content: { content: string }[] }
    items: (StoryblokTextWithPictureAndLinksBlok | StoryblokTextWithPictureAndLinksPicItemBlok)[]
}

interface Props extends StoryblokDefaultProps<StoryblokTextWithPictureAndLinksListBlok> {
    taggingData?: TaggingData
    taggingPrefix?: string
    testId?: string
}

export const StoryblokTextWithPictureAndLinksList: FunctionComponent<Props> = ({ blok, taggingData, taggingPrefix, testId, story }) => {
    return (
        <Fragment>
            {blok.heading?.content?.[0]?.content && (
                <InternalRichText blok={blok as unknown as SbBlokData} richTextKey="heading" story={story} />
            )}
            <Box
                display="flex"
                gap="m"
                flexWrap={{ phone: 'wrap', tablet: 'nowrap' }}
                testId={testId}
                {...storyblokEditable(blok as unknown as SbBlokData)}
            >
                {blok.items.map((item, index) => {
                    switch (item.component) {
                        case 'textWithPictureAndLinks':
                            return (
                                <StoryblokTextWithPictureAndLinks
                                    blok={item as StoryblokTextWithPictureAndLinksBlok}
                                    taggingPrefix={taggingPrefix}
                                    taggingData={taggingData}
                                    key={index}
                                    story={story}
                                />
                            )
                        case 'textWithPictureAndLinksListPicItem':
                            return (
                                <StoryblokTextWithPictureAndLinksPicItem
                                    blok={item as StoryblokTextWithPictureAndLinksPicItemBlok}
                                    taggingPrefix={taggingPrefix}
                                    taggingData={taggingData}
                                    key={index}
                                    story={story}
                                />
                            )
                    }
                    console.error('Rendering an unsupported child in StoryblokTextWithPictureAndLinksList')
                    return null
                })}
            </Box>
        </Fragment>
    )
}
