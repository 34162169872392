import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { SpaceProps } from '@wh-components/system/space'
import { useUnreadMessagesCount } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import MessagingIcon from '@wh-components/icons/Messaging'

interface MyMessagesLinkProps extends SpaceProps {
    onClick?: () => void
    isVerticalMenuTransparent?: boolean
}

export const MyMessagesLink: FunctionComponent<MyMessagesLinkProps> = ({ onClick, isVerticalMenuTransparent = false, ...props }) => {
    const [unreadMessages] = useUnreadMessagesCount()
    const linkText = `${unreadMessages > 99 ? '99+' : unreadMessages} neue Nachricht${unreadMessages > 1 ? 'en' : ''}`

    const messagingSVGColor = isVerticalMenuTransparent
        ? 'semantic.foreground.white'
        : unreadMessages
          ? 'semantic.foreground.white'
          : 'palette.koala'
    const textColor = isVerticalMenuTransparent
        ? 'semantic.foreground.white'
        : unreadMessages
          ? 'semantic.foreground.white'
          : 'palette.verydarkgrey'

    return (
        <ClientRoutingAnchorLink
            type="anchor"
            href="/iad/myprofile/chat"
            underline="none"
            position="relative"
            display="flex"
            alignItems="center"
            aria-label={unreadMessages ? linkText : 'Meine Nachrichten'}
            testId="header-messaging-link"
            onClick={onClick}
            {...props}
            css={css`
                &:hover {
                    > svg {
                        color: ${(p) => p.theme.colors.palette.primary.main};
                    }
                }

                ${unreadMessages &&
                css`
                    ${(p) => p.theme.media.tablet} {
                        padding: 2px 6px;
                        background-color: ${(p) => p.theme.colors.palette.coral};
                        color: ${(p) => p.theme.colors.semantic.foreground.white};
                        border-radius: ${(p) => p.theme.borderRadii.m}px;

                        &:hover {
                            > svg {
                                color: ${(p) => p.theme.colors.semantic.foreground.white};
                            }
                        }
                    }
                `}
            `}
        >
            <MessagingIcon size={{ phone: 'xlarge', tablet: 'xsmall' }} color={{ phone: 'palette.koala', tablet: messagingSVGColor }} />
            <Text
                display={{ phone: 'none', tablet: 'block' }}
                fontSize="s"
                fontWeight={unreadMessages ? 'bold' : undefined}
                color={textColor}
                marginLeft="xs"
            >
                {unreadMessages ? linkText : 'Nachrichten'}
            </Text>
            {unreadMessages > 0 && (
                <Box
                    display={{ tablet: 'none' }}
                    height={12}
                    width={12}
                    position="absolute"
                    top={0}
                    right={0}
                    borderRadius="50%"
                    backgroundColor="palette.coral"
                    border="2px solid"
                    borderColor="semantic.surface"
                />
            )}
        </ClientRoutingAnchorLink>
    )
}
