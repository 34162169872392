import { WidgetType } from '@bbx/common/types/UserFeed'
import { Box } from '@wh-components/core/Box/Box'
import { CloseButton } from '@wh-components/core/CloseButton/CloseButton'
import { Divider } from '@wh-components/core/Divider/Divider'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import { IconType } from '@wh-components/icons/utilities/createSvgIcon'
import { ResponsiveValue } from '@wh-components/system'
import { LayoutProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'
import { ClientRoutingAnchorLink, ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { increaseClickableArea } from '@wh/common/chapter/components/increaseClickableArea'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import React, { createElement, Fragment, FunctionComponent, HTMLAttributes, RefObject, useContext } from 'react'
import { css, useTheme } from 'styled-components'

interface WidgetWrapperProps {
    heading?: string
    children: React.ReactNode
    backgroundColor?: ResponsiveValue<string>
    icon?: IconType
    onClose?: () => void
    linkText?: string
    href?: string
    visibilityTrackingRef?: RefObject<HTMLDivElement>
    type: WidgetType
}

export const FeedWidgetWrapper: FunctionComponent<WidgetWrapperProps & LayoutProps & SpaceProps & HTMLAttributes<HTMLDivElement>> = ({
    heading,
    children,
    icon,
    backgroundColor,
    visibilityTrackingRef,
    onClose,
    linkText,
    href,
    type,
    ...props
}) => {
    return (
        <Box as="article" {...props} ref={visibilityTrackingRef} testId={`feed-widget-${type}`}>
            {heading && (
                <Fragment>
                    <WidgetHeader
                        icon={icon}
                        backgroundColor={backgroundColor}
                        heading={heading}
                        onClose={onClose}
                        linkText={linkText}
                        href={href}
                    />
                    <DividerWithNegativeMargin marginVertical="s" />
                </Fragment>
            )}
            {children}
            <WidgetDivider />
        </Box>
    )
}

export const WidgetHeader: FunctionComponent<{
    icon?: IconType
    backgroundColor?: ResponsiveValue<string>
    heading: string
    onClose?: () => void
    linkText?: string
    href?: string
    onClick?: () => void
}> = ({ backgroundColor, icon, heading, onClose, linkText, href, onClick }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const titleColor = advertisingState.pageModifications.foregroundColors?.['startpage-title-color']
    const accentColor = advertisingState.pageModifications.foregroundColors?.['startpage-accent-color']

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
                {icon && <WidgetIcon icon={icon} backgroundColor={backgroundColor} />}
                {href ? (
                    <ClientRoutingAnchorLink
                        type="anchor"
                        href={href}
                        onClick={onClick}
                        color={titleColor ?? 'palette.verydarkgrey'}
                        css={css`
                            outline: 0;
                        `}
                    >
                        <Heading level={2} text={heading} fontSize="l" />
                    </ClientRoutingAnchorLink>
                ) : (
                    <Heading level={2} text={heading} fontSize="l" color={titleColor} />
                )}
            </Box>
            {onClose && (
                <CloseButton
                    marginRight={{ phone: 'm', tablet: 0 }}
                    size="xsmall"
                    aria-label={`${heading} schließen`}
                    testId={`${heading}-close-button`}
                    onClick={onClose}
                    css={css`
                        color: ${(p) => p.theme.colors.palette.koala};
                        transform: scale(0.83) translateY(2px);
                        outline: 1px solid;
                        ${increaseClickableArea(8)}
                    `}
                />
            )}
            {href && linkText && (
                <ServerRoutingAnchorLink type="anchor" href={href} marginRight={{ phone: 'm', tablet: 0 }} color={accentColor}>
                    <Text fontSize="s" fontWeight="bold">
                        {linkText}
                    </Text>
                </ServerRoutingAnchorLink>
            )}
        </Box>
    )
}

export const WidgetIcon: FunctionComponent<{ icon: IconType; backgroundColor?: ResponsiveValue<string> }> = ({ icon, backgroundColor }) => {
    return (
        <Box
            width="24px"
            height="24px"
            borderRadius="50%"
            backgroundColor={backgroundColor}
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginRight="s"
        >
            {createElement(icon, { size: 'xsmall', color: 'semantic.foreground.white' })}
        </Box>
    )
}

export const DividerWithNegativeMargin = (props: SpaceProps) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const theme = useTheme()
    const separatorSmallBackground =
        advertisingState.pageModifications.backgroundColors?.['startpage-separator-small'] ?? theme.colors.semantic.outline

    return (
        <Divider
            {...props}
            css={css`
                ${(p) => p.theme.media.only.phone} {
                    margin-left: -${(p) => p.theme.space.m}px;
                }
                background-color: ${separatorSmallBackground};
            `}
        />
    )
}

export const WidgetDivider = () => {
    const advertisingState = useContext(AdvertisingStateContext)
    const separatorBigBackground = advertisingState.pageModifications.backgroundColors?.['startpage-separator-big'] ?? 'palette.babyseal'

    return (
        <Box
            height="16px"
            marginVertical="m"
            backgroundColor={separatorBigBackground}
            css={css`
                margin-left: -${(p) => p.theme.space.m}px;
                ${(p) => p.theme.media.tablet} {
                    margin-right: -${(p) => p.theme.space.m}px;
                }
            `}
        />
    )
}
