import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { LazyPicture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { PayliveryBadge } from '../../../chapter/components/PayliveryBadge/PayliveryBadge'
import { storyblokResolveLink } from '../../lib/storyblokResolveLink'
import { storyblokTransformSlugToTag } from '../../lib/storyblokTransformSlugToTag'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'

export interface StoryblokTextWithPictureAndLinksPicItemBlok extends StoryblokComponentType<string> {
    image: StoryblokAsset
    imageLink: StoryblokLink
    showPayliveryBadge?: boolean
    imageBackgroundColor: string
    imageHoverColor: string
    taggingId: string
}

interface Props extends StoryblokDefaultProps<StoryblokTextWithPictureAndLinksPicItemBlok> {
    taggingData?: TaggingData
    taggingPrefix?: string
}

export const StoryblokTextWithPictureAndLinksPicItem: FunctionComponent<Props> = ({
    blok,
    story,
    taggingData,
    taggingPrefix,
    ...props
}) => {
    const { image, imageLink, imageBackgroundColor, imageHoverColor, showPayliveryBadge, taggingId } = blok

    const prefix = taggingPrefix ?? storyblokTransformSlugToTag(story?.full_slug ?? '')

    return (
        <Box
            display="flex"
            flexDirection="column"
            flex="1"
            width={{ phone: 'calc(50% - 8px)', tablet: 'auto' }}
            paddingBottom="m"
            {...storyblokEditable(blok as unknown as SbBlokData)}
            {...props}
        >
            {showPayliveryBadge && (
                <Box position="absolute" margin="xs">
                    <PayliveryBadge id={taggingId} />
                </Box>
            )}
            <ServerRoutingAnchorLink
                height="100%"
                type="anchor"
                href={storyblokResolveLink(imageLink)}
                onClick={() => {
                    if (prefix && taggingId) {
                        callActionEvent('storyblok_click', taggingData, { prefix: prefix, taggingId: taggingId })
                    }
                }}
                underline="none"
            >
                <LazyPicture
                    height="100%"
                    width="100%"
                    imgSrcSet={{ x1: `${image.filename}/m/0x350/filters:quality(90)`, x2: `${image.filename}/m/0x650/filters:quality(90)` }}
                    alt={image.alt}
                    css={css`
                        object-fit: cover;
                        background-color: ${(p) => p.theme.colors.palette.babyseal};
                        background-color: ${imageBackgroundColor};
                        transition: 0.4s;
                        ${imageHoverColor ? `&:hover {background-color: ${imageHoverColor};}` : ''}
                    `}
                />
            </ServerRoutingAnchorLink>
        </Box>
    )
}
