import { theme } from '@wh-components/core/theme'
import AlertInfoIcon from '@wh-components/icons/AlertInfo'
import AlertWarningIcon from '@wh-components/icons/AlertWarning'
import PayLiveryIcon from '@wh-components/icons/Paylivery'
import { UserProfileData } from '@wh/common/chapter/types/user'

const dac7Theme = {
    info: () => {
        return {
            name: 'info',
            icon: AlertInfoIcon,
            bannerIcon: AlertInfoIcon,
            iconColor: theme.colors.semantic.primary.DEFAULT,
            iconMenuColor: theme.colors.semantic.primary.DEFAULT,
            backgroundColor: theme.colors.semantic.primary.container,
            title: 'Bitte ergänze deine Daten',
            text: 'Da du bereits 30 Artikel verkauft oder mehr als €2.000 über PayLivery verdient hast, sind wir gesetzlich dazu verpflichtet, weitere Daten von dir zu erheben. Klicke hier um mehr zu erfahren, danke!',
        }
    },
    warning: () => {
        return {
            name: 'warning',
            icon: AlertWarningIcon,
            bannerIcon: AlertWarningIcon,
            iconColor: theme.colors.semantic.alert.warning.DEFAULT,
            iconMenuColor: theme.colors.semantic.alert.warning.DEFAULT,
            backgroundColor: theme.colors.semantic.alert.warning.container,
            title: 'Erinnerung: Wir benötigen weitere Daten',
            text: 'Bitte ergänze deine Daten, damit du weiterhin PayLivery verwenden kannst. Klicke hier um mehr zu erfahren, danke!',
        }
    },
    critical: () => {
        return {
            name: 'critical',
            icon: AlertWarningIcon,
            bannerIcon: AlertWarningIcon,
            iconColor: theme.colors.semantic.alert.error.DEFAULT,
            iconMenuColor: theme.colors.semantic.tertiary.DEFAULT,
            backgroundColor: theme.colors.semantic.tertiary.container,
            title: 'Bitte ergänze umgehend die weiteren benötigten Daten!',
            text: 'Bitte ergänze umgehend deine Daten, damit du weiterhin PayLivery verwenden kannst. Klicke hier um mehr zu erfahren, danke!',
        }
    },
    blocked: () => {
        return {
            name: 'blocked',
            icon: AlertWarningIcon,
            bannerIcon: PayLiveryIcon,
            iconColor: theme.colors.semantic.alert.error.DEFAULT,
            iconMenuColor: theme.colors.semantic.tertiary.DEFAULT,
            backgroundColor: theme.colors.semantic.tertiary.container,
            title: 'Dein PayLivery Account wurde blockiert',
            text: 'Bitte ergänze deine Daten, damit du PayLivery wieder verwenden kannst. Klicke hier um mehr zu erfahren, danke!',
        }
    },
}

export const getDac7ThemeByAttempt = (dac7Attempt: UserProfileData['dac7NotificationAttempt']) => {
    switch (dac7Attempt) {
        case 'FIRST_REQUEST': {
            return dac7Theme.info()
        }
        case 'FIRST_REMINDER': {
            return dac7Theme.warning()
        }
        case 'SECOND_REMINDER': {
            return dac7Theme.critical()
        }
        case 'PAYLIVERY_BLOCKED': {
            return dac7Theme.blocked()
        }
        default: {
            return null
        }
    }
}
