import { ISbStoryData, StoryblokComponentType } from '@storyblok/react/dist/types'
import { DEV_MODE } from '@wh/common/chapter/lib/config/constants'
import dayjs from 'dayjs'
import { PreviewData } from 'next'
import { validate } from 'uuid'
import { PreviewDataWithToken } from '../types/StoryblokComponents'
import { fetcher } from '../../chapter/api/fetcher'
import { wrapInApiErrorIfNecessary } from '../../chapter/lib/errors'
import { urlSearchParamsFromObject } from '../../chapter/api/urlSearchParams'

type StoryDefault = StoryblokComponentType<string> & { [index: string]: unknown }

/**
 * Retrieves one single story from storyblok
 * @param identifier Can be one of full_slug, id, uuid
 * @param apiKey Either the preview or public token. Public token is taken if omitted
 * @param resolve_links If linked stories will also get resolved
 */
export const getStory = async <T = StoryDefault>(
    identifier: string,
    apiKey?: string,
    resolve_links?: 'url' | 'story' | '0' | '1' | 'link',
): Promise<{ story: ISbStoryData<T>; links: ISbStoryData<T>[] }> => {
    let key = process.env.STORYBLOK_PUBLISHED_API_TOKEN
    if (apiKey) {
        key = apiKey
    }
    return await retryApiClient<{ story: ISbStoryData<T>; links: ISbStoryData<T>[] }>(
        `/cms/v2/cdn/stories/${identifier}?${urlSearchParamsFromObject({
            version: apiKey ? 'draft' : 'published',
            token: key,
            resolve_links: resolve_links ?? '0',
            cv: dayjs().startOf('hours').unix(),
            find_by: validate(identifier) ? 'uuid' : undefined,
        })}`,
    )
}

export const getStoriesByTag = async <T = StoryDefault>(
    tag: string,
    startsWith: string,
    apiKey?: string,
): Promise<{ stories: ISbStoryData<T>[] }> => {
    let key = process.env.STORYBLOK_PUBLISHED_API_TOKEN
    if (apiKey) {
        key = apiKey
    }
    return await retryApiClient<{ stories: ISbStoryData<T>[] }>(
        `/cms/v2/cdn/stories/?${urlSearchParamsFromObject({
            version: apiKey ? 'draft' : 'published',
            token: key,
            with_tag: tag,
            starts_with: startsWith,
            cv: dayjs().startOf('hours').unix(),
        })}`,
    )
}

/**
 * Retrieves one single story from storyblok
 * @param identifier Can be one of full_slug, id, uuid
 * @param apiKey Either the preview or public token. Public token is taken if omitted
 */
export const getStoryWithOptionalPreview = async <T = StoryDefault>(
    identifier: string,
    previewData?: PreviewData,
    resolve_links: 'url' | 'story' | '0' | '1' | 'link' = '0',
) => {
    if (DEV_MODE) {
        return await getStory<T>(identifier, process.env.STORYBLOK_PREVIEW_API_TOKEN, resolve_links)
    } else if (typeof previewData === 'object') {
        const previewDataWithToken = previewData as PreviewDataWithToken | undefined
        return await getStory<T>(identifier, previewDataWithToken?.preview_token, resolve_links)
    } else {
        return await getStory<T>(identifier, undefined, resolve_links)
    }
}

export const getStoriesWithStartPath = async (startsWith: string, apiKey?: string): Promise<ISbStoryData[]> => {
    const PAGE_SIZE = 25

    const stories: ISbStoryData[] = []

    let curPage: { stories: ISbStoryData[] }
    let page = 1

    do {
        curPage = await getStoriesWithStartPathPaged(page++, PAGE_SIZE, startsWith, apiKey)
        stories.push(...curPage.stories)
    } while (curPage.stories.length >= PAGE_SIZE)
    // api response doesn't contain paging information, so we need to look at how many stories are returned by the endpoint

    return stories
}

const getStoriesWithStartPathPaged = async (
    page: number,
    pageSize: number,
    startsWith: string,
    apiKey?: string,
): Promise<{
    stories: ISbStoryData[]
}> => {
    let key = process.env.STORYBLOK_PUBLISHED_API_TOKEN
    if (apiKey) {
        key = apiKey
    }

    return await retryApiClient<{
        stories: ISbStoryData[]
    }>(
        `/cms/v2/cdn/stories/?${urlSearchParamsFromObject({
            version: apiKey ? 'draft' : 'published',
            starts_with: startsWith,
            token: key,
            page: page,
            per_page: pageSize,
            cv: dayjs().startOf('hours').unix(),
        })}`,
    )
}

const retryApiClient = async <T>(url: string, options: RequestInit = {}): Promise<T> => {
    const MAX_RETRIES = 25
    const BASE_WAIT_MS = 900

    let data: T | undefined
    let i = 0

    while (!data && i < MAX_RETRIES) {
        const response = await fetcher<T>(url, options).catch((error) => {
            const apiError = wrapInApiErrorIfNecessary(error)
            if (apiError.statusCode === 429) {
                console.warn(
                    `storyblok: status code 429 for "${JSON.stringify({
                        url: url.replace(/token=[^&$]*/, 'token=<removed>'),
                        ...options,
                    })}"`,
                )
                i++
                return new Promise((resolve) => setTimeout(resolve, BASE_WAIT_MS * i)).then(() => undefined)
            } else {
                throw error
            }
        })
        data = response
    }

    if (!data) {
        throw new Error(
            `storyblok: Too many 429 for "${JSON.stringify({ url: url.replace(/token=[^&$]*/, 'token=<removed>'), ...options })}"`,
        )
    }

    return data
}
