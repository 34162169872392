import React, { FunctionComponent } from 'react'
import { AnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import styled, { css, useTheme } from 'styled-components'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { Box } from '@wh-components/core/Box/Box'
import { MenuLinkProps } from './menuLinkTypes'

const StyledAnchorLink = styled(AnchorLink)<Pick<MenuLinkProps, 'active' | 'isHeaderBoxTransparent'>>`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1 1 auto;
    font-weight: ${(p) => p.theme.fontWeights.semibold};
    text-transform: uppercase;
    justify-content: center;
    padding-top: ${(p) => p.theme.space.m}px;
    font-size: ${(p) => p.theme.fontSizes.m};
    text-decoration: none;

    &:after {
        display: block;
        content: '';
        border-bottom: ${(p) => p.theme.space.xs}px solid
            ${(p) => (p.isHeaderBoxTransparent ? p.theme.colors.semantic.surface.DEFAULT : p.theme.colors.palette.primary.main)};
        transform: ${(p) => (p.active ? 'scaleX(1)' : 'scaleX(0)')};
        transition: transform 250ms ease-in-out;
        width: 100%;
        padding-top: ${(p) => p.theme.space.m}px;
    }

    &:hover,
    &:focus {
        color: ${(p) => (p.isHeaderBoxTransparent ? p.theme.colors.semantic.foreground.white : p.theme.colors.palette.primary.main)};
        cursor: pointer;
        text-decoration: none;

        &:after {
            transform: scaleX(1);
        }
    }
`

export const MenuLink: FunctionComponent<MenuLinkProps> = ({ item, nrOfAdverts, onClick, active, isHeaderBoxTransparent }) => {
    const themeFromContext = useTheme()

    let counterColor
    let linkColor
    let hoverColor
    if (isHeaderBoxTransparent) {
        counterColor = themeFromContext.colors.semantic.foreground.white
        linkColor = themeFromContext.colors.semantic.foreground.white
        hoverColor = themeFromContext.colors.semantic.foreground.white
    } else {
        counterColor = active ? themeFromContext.colors.palette.primary.main : themeFromContext.colors.palette.elephant
        linkColor = active ? themeFromContext.colors.palette.primary.main : themeFromContext.colors.palette.verydarkgrey
        hoverColor = themeFromContext.colors.palette.primary.main
    }

    return (
        <StyledAnchorLink
            clientSideRouting={item.clientSideRouting}
            type="anchor"
            active={active}
            color={linkColor}
            isHeaderBoxTransparent={isHeaderBoxTransparent}
            href={item.href}
            onClick={() => {
                onClick()
                return callActionEvent(item.clickTag, undefined)
            }}
            testId={`menu-vertical-id-${item.verticalId.toString()}`}
        >
            <Box display="flex" justifyContent="center">
                <Box display="flex">
                    <span>{item.text}</span>{' '}
                    <span
                        css={css`
                            font-weight: ${(p) => p.theme.fontWeights.regular};
                            font-size: ${(p) => p.theme.fontSizes.s};
                            color: ${counterColor};
                            margin-left: ${(p) => p.theme.space.s}px;
                            align-self: flex-end;
                            display: none;

                            ${(p) => p.theme.media.desktop} {
                                display: inline;
                            }

                            ${StyledAnchorLink}:hover & {
                                color: ${hoverColor};
                            }
                        `}
                    >
                        {nrOfAdverts}
                    </span>
                </Box>
            </Box>
        </StyledAnchorLink>
    )
}
