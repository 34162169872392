import { theme } from '@wh-components/core/theme'
import facebook_placeholder from '@wh/common/chapter/static_hashed/img/facebook_placeholder.jpg'
import ms_tile from '@wh/common/chapter/static_hashed/img/ms_tile.png'

/**
 * Android App
 */
export const ANDROID = {
    THEME_COLOR: theme.colors.semantic.surface.DEFAULT,
}

/**
 * Microsoft App
 */
export const MICROSOFT = {
    TILE_IMAGE_URL: ms_tile,
    TILE_COLOR: theme.colors.palette.primary.main,
    SQUARE_150x150_LOGO: '',
    APPLICATION_CONFIG: '/_next/assets/browserconfig.xml',
}

/**
 * Facebook App
 */
export const FACEBOOK = {
    APP_ID: '617078354969801',
}

/**
 * Open Graph markup
 */
export const OPEN_GRAPH = {
    TYPE: 'website',
    NAME: 'willhaben',
    SITE_NAME: 'willhaben.at',
    TITLE: 'Kostenlose Kleinanzeigen, Immobilien, Gebrauchtwagen, Jobs - willhaben',
    DESCRIPTION: 'Immobilien, Gebrauchtwagen, Jobs und Marktplatz: Mehr als 8 Mio. Angebote auf willhaben',
    IMAGE: facebook_placeholder,
    IMAGE_ALT: 'Die größte Marktplatz-App Österreichs',
    IMAGE_WIDTH: 1200,
    IMAGE_HEIGHT: 630,
    LOCALE: 'de_AT',
}

/**
 * Twitter cards
 */
export const TWITTER = {
    CARD: 'summary_large_image',
    SITE: '@willhaben',
    CREATOR: '@willhaben',
}
