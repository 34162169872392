import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Card } from '@wh-components/core/Card/Card'
import React, { FunctionComponent } from 'react'

interface StoryblokCard extends StoryblokComponentType<string> {
    children: SbBlokData[]
}

interface Props extends StoryblokDefaultProps<StoryblokCard> {}

export const StoryblokCard: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <Card {...storyblokEditable(blok as unknown as SbBlokData)}>
            {blok.children.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
            ))}
        </Card>
    )
}
