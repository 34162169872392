import { storyblokCallActionEvent } from '../../lib/storyblokCallActionEvent'
import { storyblokResolveLink } from '../../lib/storyblokResolveLink'
import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import { ButtonColorType, ButtonSizeType, ButtonVariantType } from '@wh-components/core/Button/Button'
import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'
import { IconType } from '@wh-components/icons/utilities/createSvgIcon'
import dynamic from 'next/dynamic'
import React, { FunctionComponent } from 'react'

interface StoryblokButton extends StoryblokComponentType<string> {
    text: string
    link: StoryblokLink
    color: ButtonColorType
    variant: ButtonVariantType
    size: ButtonSizeType
    icon?: string
    iconAfterText?: boolean
    width?: number
    taggingEventName: string
}

const dynamicComponents = {
    Plus: dynamic(() => import('@wh-components/icons/Plus')),
    Minus: dynamic(() => import('@wh-components/icons/Minus')),
    ArrowDown: dynamic(() => import('@wh-components/icons/ArrowDown')),
    ArrowLeftThick: dynamic(() => import('@wh-components/icons/ArrowLeftThick')),
    ArrowLeft: dynamic(() => import('@wh-components/icons/ArrowLeft')),
    ArrowLongLeft: dynamic(() => import('@wh-components/icons/ArrowLongLeft')),
    ArrowRightThick: dynamic(() => import('@wh-components/icons/ArrowRightThick')),
    ArrowRight: dynamic(() => import('@wh-components/icons/ArrowRight')),
    ArrowSorting: dynamic(() => import('@wh-components/icons/ArrowSorting')),
    ArrowUp: dynamic(() => import('@wh-components/icons/ArrowUp')),
    ArrowUpCurved: dynamic(() => import('@wh-components/icons/ArrowUpCurved')),
    ArrowtinyDown: dynamic(() => import('@wh-components/icons/ArrowtinyDown')),
    ArrowtinyLeft: dynamic(() => import('@wh-components/icons/ArrowtinyLeft')),
    ArrowtinyRight: dynamic(() => import('@wh-components/icons/ArrowtinyRight')),
    ArrowtinyUp: dynamic(() => import('@wh-components/icons/ArrowtinyUp')),
}

interface Props extends StoryblokDefaultProps<StoryblokButton> {}

export const StoryblokButton: FunctionComponent<Props> = ({ blok, story }) => {
    const { icon, iconAfterText, color, variant, size, width, link, taggingEventName, text } = blok

    /**
     * Taken from convertSize in @wh-components/core/Button/Button.tsx
     */
    const iconSizeMap = {
        large: 'medium',
        medium: 'small',
        small: 'xsmall',
        xsmall: 12,
    }

    // @ts-ignore
    const MyIcon: IconType = icon ? dynamicComponents[blok.icon] : undefined

    return (
        <LinkWithButtonStyle
            {...storyblokEditable(blok as unknown as SbBlokData)}
            Icon={!iconAfterText ? MyIcon : undefined}
            color={color}
            variant={variant}
            size={size}
            width={width ? `min(${width}px, 100%)` : undefined}
            href={storyblokResolveLink(link)}
            onClick={() => {
                storyblokCallActionEvent(taggingEventName, story?.full_slug ?? '')
            }}
            target={link.target}
        >
            {text}
            {iconAfterText && MyIcon && <MyIcon size={iconSizeMap[size]} color="inherit" marginLeft="xs" fontSize="inherit" />}
        </LinkWithButtonStyle>
    )
}
