import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'

type Columns = '1' | '2' | '3' | '4'

interface StoryblokColumns extends StoryblokComponentType<string> {
    desktop: Columns
    tablet: Columns
    mobile: Columns
    items: SbBlokData[]
}

interface Props extends StoryblokDefaultProps<StoryblokColumns> {}

const columnNumberToPercent = (columns: Columns): string => {
    switch (columns) {
        case '1':
            return '100%'
        case '2':
            return '50%'
        case '3':
            return '33.3%'
        case '4':
            return '25%'
    }
}

export const StoryblokColumns: FunctionComponent<Props> = ({ blok, story }) => {
    const theme = useTheme()
    const desktop = +blok.desktop
    const tablet = +blok.tablet
    const mobile = +blok.mobile

    const flexBasisModifierDesktop = Math.ceil((theme.space.m * (desktop - 1)) / desktop)
    const flexBasisModifierTablet = Math.ceil((theme.space.m * (tablet - 1)) / tablet)
    const flexBasisModifierMobile = Math.ceil((theme.space.m * (mobile - 1)) / mobile)

    return (
        <Box display="flex" flexWrap="wrap" gap="m" {...storyblokEditable(blok as unknown as SbBlokData)}>
            {blok.items.map((item) => (
                <ItemContainer
                    key={item._uid}
                    columnSize={{
                        desktop: columnNumberToPercent(blok.desktop),
                        tablet: columnNumberToPercent(blok.tablet),
                        mobile: columnNumberToPercent(blok.mobile),
                    }}
                    flexBasisModifier={{
                        desktop: `${flexBasisModifierDesktop}px`,
                        tablet: `${flexBasisModifierTablet}px`,
                        mobile: `${flexBasisModifierMobile}px`,
                    }}
                >
                    <StoryblokComponent blok={item} story={story} />
                </ItemContainer>
            ))}
        </Box>
    )
}

type ItemContainerProps = {
    columnSize: { desktop: string; tablet: string; mobile: string }
    flexBasisModifier: { desktop: string; tablet: string; mobile: string }
}

const ItemContainer = styled.div<ItemContainerProps>`
    flex: 0 0 calc(${(p) => p.columnSize.mobile} - ${(p) => p.flexBasisModifier.mobile});

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}px) {
        flex: 0 0 calc(${(p) => p.columnSize.tablet} - ${(p) => p.flexBasisModifier.tablet});
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}px) {
        flex: 0 0 calc(${(p) => p.columnSize.desktop} - ${(p) => p.flexBasisModifier.desktop});
    }
`
