import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { AdvertSummaryComponent, AdvertSummaryVariant } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummary'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { verticals } from '@wh/common/chapter/types/verticals'
import React, { FunctionComponent } from 'react'

interface BapAdvertSummaryComponentProps {
    advertSummary: AdvertSummary
    onClickTaggingEvent?: () => Promise<void> | void
    variant?: AdvertSummaryVariant
    color?: string | undefined
    accentColor?: string | undefined
}

export const BapAdvertSummaryComponent: FunctionComponent<BapAdvertSummaryComponentProps> = ({
    advertSummary,
    onClickTaggingEvent,
    variant = 'landscape',
    color,
    accentColor,
}) => {
    return (
        <AdvertSummaryComponent
            attributes={advertSummary.attributes.attribute}
            key={advertSummary.id}
            adTypeId={advertSummary.adTypeId}
            description={advertSummary.description}
            advertImage={advertSummary.advertImageList.advertImage?.[0]?.mainImageUrl}
            advertStatus={advertSummary.advertStatus}
            href={getSeoUrl(advertSummary)}
            onClickTaggingEvent={onClickTaggingEvent}
            teaserAttributes={advertSummary.teaserAttributes}
            vertical={verticals.BAP}
            adId={advertSummary.id}
            color={color}
            accentColor={accentColor}
            variant={variant}
            showBadges={true}
        />
    )
}
