import Tracker from '@wh/pulse-sdk'
import { createImpliedPostEventPayload, createPulseLinkEventPayload } from '@wh/common/chapter/lib/tagging/taggingPulseLinkEvent'
import { TaggingActionEvent, TaggingPage, TaggingSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { getDebugFlags } from '../../components/DebugFlagProvider/DebugFlagProvider'
import { promiseTimeout, TRACKING_WORKAROUND_DELAY } from './taggingPromiseTimeout'
import { createPulseViewEventPayload } from './taggingPulseTransformations'
import { PulseFormParams } from './taggingPulseTypes'
import { getWillhabenDatacollectorApiUrl } from '../config/runtimeConfig'

const defaultPulseTracker = new Tracker('willhabenat', {
    collectorBaseUrl: getWillhabenDatacollectorApiUrl(),
})

export const trackPulseClickEvent = async (
    eventName: TaggingActionEvent | TaggingSelfPromotionEvent,
    params: Readonly<Record<string, string | undefined>>,
) => {
    if (typeof window === 'undefined' || getDebugFlags(window.location.href).trackingMode === 'disable') {
        // skip tagging
        return Promise.resolve()
    }

    const payload = createPulseLinkEventPayload(eventName, params)
    if (payload) {
        defaultPulseTracker.track('trackerEvent', payload).catch(() => void 0)
    }

    return Promise.resolve()
}

export const trackPulsePageView = async (
    eventName: TaggingPage,
    params: Readonly<
        Record<string, string | number | Record<string, unknown> | Array<string | number | Record<string, unknown>> | undefined> &
            PulseFormParams
    >,
) => {
    if (typeof window === 'undefined' || getDebugFlags(window.location.href).trackingMode === 'disable') {
        // skip tagging
        return Promise.resolve()
    }

    if (ignorePageViewEvent(eventName)) {
        // skip tagging
        return Promise.resolve()
    }

    const eventPayload = createPulseViewEventPayload(eventName, params)
    const trackedPromise1 = defaultPulseTracker.trackPageView(eventPayload).then(() => void 0)

    let trackedPromise2 = Promise.resolve()
    const impliedPostEvent = createImpliedPostEventPayload(eventName, params)
    if (impliedPostEvent) {
        trackedPromise2 = defaultPulseTracker.track('trackerEvent', impliedPostEvent).then(() => void 0)
    }

    // wait up to TRACKING_WORKAROUND_DELAY for tagging to be finished before routing in some cases
    return Promise.race([Promise.all([trackedPromise1, trackedPromise2]), promiseTimeout(TRACKING_WORKAROUND_DELAY)]).catch(() => void 0)
}

const ignorePageViewEvent = (eventName: TaggingPage) => {
    if (eventName === 'jobs_addetail_successful_application') {
        return true
    }
    return false
}
