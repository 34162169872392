import React, { createElement } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { useTheme } from 'styled-components'
import { getDac7ThemeByAttempt } from './dac7'

type DAC7NotificationProps = { dac7Attempt: UserProfileData['dac7NotificationAttempt'] }
export const DAC7Notification: React.FC<DAC7NotificationProps> = ({ dac7Attempt }) => {
    const theme = useTheme()
    const dac7Theme = getDac7ThemeByAttempt(dac7Attempt)

    return (
        <Box testId="dac7CallToAction">
            {dac7Theme?.iconMenuColor && (
                <Box display="flex" alignItems="center" marginLeft="m" justifyContent="center">
                    {createElement(dac7Theme.icon, {
                        color: `${dac7Theme?.iconMenuColor ?? theme.colors.semantic.primary.DEFAULT} !important`,
                        size: 16,
                    })}
                </Box>
            )}
        </Box>
    )
}
