import { ISbStoryData, StoryblokComponent, useStoryblokState } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import React, { Fragment, FunctionComponent } from 'react'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { SeoMetaTagsForSeoMetaData } from '@wh/common/chapter/components/SeoMetaTags/SeoMetaTags'
import { InternalRichText } from '../bloks/InternalRichText'
import { StoryProps } from '../../types/StoryblokComponents'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { storyblokResolveLink } from '../../lib/storyblokResolveLink'
import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import { ClassNameProps } from '@wh-components/core/common'
import { StoryblokContextProvider } from '../StoryblokContextProvider'

export interface StoryblokArticleBlok extends StoryblokComponentType<string> {
    body: SbBlokData[]
    heading: StoryblokRichtext
    seoImage: StoryblokAsset
    seoTitle: string
    text_only: boolean
    mobileLink: StoryblokLink
    seoKeywords: string
    mobileLinkText: string
    seoDescription: string
}

export const StoryblokStaticPage: FunctionComponent<StoryProps<StoryblokArticleBlok>> = ({ story: initialStory, links }) => {
    const story = useStoryblokState(initialStory)

    if (!story) {
        return null
    }

    const article = story.content

    const resolvedMobileLink = storyblokResolveLink(article?.mobileLink as unknown as StoryblokLink)

    return (
        <Fragment>
            <ArticleSeoMetaTags article={article} canonicalUrl={`https://www.willhaben.at/${story.full_slug}`} />
            <Box
                paddingHorizontal={{ phone: 'm', tablet: article.text_only ? 'm' : 0 }}
                paddingTop={article.text_only ? 'l' : 's'}
                maxWidth={article.text_only ? '750px' : undefined}
            >
                <StoryblokContextProvider story={story} links={links}>
                    <Box display="flex" justifyContent="space-between" alignItems="end">
                        <ArticleHeading article={article} story={story} />
                        {resolvedMobileLink && article.mobileLinkText && (
                            <Box marginBottom="s" display={{ phone: 'block', tablet: 'none' }}>
                                <ClientRoutingAnchorLink type="button" href={resolvedMobileLink} variant="outline" size="small">
                                    {article.mobileLinkText}
                                </ClientRoutingAnchorLink>
                            </Box>
                        )}
                    </Box>

                    {Array.isArray(article?.body)
                        ? article?.body.map((nestedBlok: SbBlokData) => {
                              return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
                          })
                        : null}
                </StoryblokContextProvider>
            </Box>
        </Fragment>
    )
}

interface ArticleSeoMetaTagsProps {
    article: StoryblokArticleBlok
    canonicalUrl: string
    noindex?: boolean
    nofollow?: boolean
}

export const ArticleSeoMetaTags: FunctionComponent<ArticleSeoMetaTagsProps> = ({ article, canonicalUrl, noindex, nofollow }) =>
    article?.seoTitle ? (
        <SeoMetaTagsForSeoMetaData
            seoMetaData={{
                title: article.seoTitle,
                description: article?.seoDescription,
                keywords: article?.seoKeywords,
                canonicalUrl,
            }}
            // We append `/filters:format(jpeg)` here to disable automatic image format detection, that Storyblok's image service offers
            // This way we can ensure that the SEO image is always cached by our cache, no matter what kind of client accesses the image
            // Otherwise the image service returns a "Vary"-header
            ogSiteImageUrl={article?.seoImage.filename ? `${article?.seoImage.filename}/m/1200x630/filters:format(jpeg)` : undefined}
            noindex={noindex}
            nofollow={nofollow}
        />
    ) : null

interface ArticleHeadingProps extends ClassNameProps {
    article: StoryblokArticleBlok
    story?: ISbStoryData
}

export const ArticleHeading: FunctionComponent<ArticleHeadingProps> = ({ article, story, className }) =>
    article.heading?.content?.[0]?.content ? (
        <div className={className}>
            <InternalRichText blok={article as unknown as SbBlokData} richTextKey="heading" story={story} />
        </div>
    ) : null
