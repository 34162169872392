import { StoryblokLink } from '../../types/built-in/StoryblokLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { StoryblokComponentType } from '@storyblok/js/dist/types'
import React, { FunctionComponent, useContext } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { StoryblokContext } from '../StoryblokContextProvider'
import { StoryblokArticleBlok } from '../pagetypes/StoryblokStaticPage'
import { ISbStoryData } from '@storyblok/react/dist/types'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { Text } from '@wh-components/core/Text/Text'
import { css } from 'styled-components'
import { ClientRoutingAnchorLink } from '../../../chapter/components/AnchorLink/AnchorLink'
import { BbxRouter } from '../../../chapter/lib/routing/bbxRouter'

interface StoryblokBlogListItemProps extends StoryblokComponentType<string> {
    blogpost: StoryblokLink
}

interface Props extends StoryblokDefaultProps<StoryblokBlogListItemProps> {}

export const StoryblokFeaturedBlogpostItem: FunctionComponent<Props> = ({ blok }) => {
    const storyblokContext = useContext(StoryblokContext)

    if (blok.blogpost.linktype !== 'story') {
        return (
            <Box display="flex" alignItems="center" justifyContent="center">
                The provided link was no internal Storyblok page.
            </Box>
        )
    }

    const url = blok.blogpost.cached_url
    const foundStory = storyblokContext.links.find((stry) => stry.full_slug === url) as ISbStoryData<StoryblokArticleBlok>
    const filename = foundStory?.content?.seoImage.filename
    const alt = foundStory?.content?.seoImage.alt

    const href = `/${foundStory?.full_slug}`

    return (
        <Box display="flex" flexDirection="column" as="article">
            <Text lineClamp={2} marginTop="s" as="h3" fontSize="m" order={2}>
                <ClientRoutingAnchorLink type="anchor" href={href} color="palette.verydarkgrey">
                    {foundStory?.content.seoTitle}
                </ClientRoutingAnchorLink>
            </Text>
            {filename ? (
                <ResponsiveImage
                    src={`${filename}/m/300x300`}
                    alt={alt}
                    objectFit="cover"
                    height="165px"
                    width="100%"
                    maxHeight="165px"
                    maxWidth="100%"
                    display="block"
                    css={css`
                        cursor: pointer;
                    `}
                    onClick={() => {
                        BbxRouter.push({
                            href: href,
                            clientSideRouting: true,
                        })
                    }}
                    order={1}
                />
            ) : (
                <Box
                    height="165px"
                    width="100%"
                    padding="m"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    css={css`
                        border: 3px solid orange;
                    `}
                    order={1}
                >
                    <Text fontWeight="bold">Bitte ein SEO Bild bei dem jeweiligen Blogpost hinzufügen</Text>
                </Box>
            )}
            <ClientRoutingAnchorLink type="anchor" href={href} aria-hidden="true" order={3} tabIndex={-1} display="block" marginTop="auto">
                weiterlesen
            </ClientRoutingAnchorLink>
        </Box>
    )
}
