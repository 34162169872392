import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import { theme } from '@wh-components/core/theme'
import SearchIcon from '@wh-components/icons/Search'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useRouter } from 'next/router'
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { css } from 'styled-components'
import { staticRelativeCanonicals } from '../../hocs/staticRelativeCanonicals'
import { removeTrailingSlash } from '../../lib/urlHelpers'
import { hideWhenKeyboardIsOpen } from '../../lib/hideWhenKeyboardIsOpen'

export const VERTICAL_LINKS_HEIGHT = 45

type Props = { sticky?: boolean }

export const VerticalLinks: FunctionComponent<Props> = ({ sticky }) => {
    const [showShadow, setShowShadow] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            setShowShadow(document.documentElement.scrollTop > 5)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <Box
            display={{ tablet: 'none' }}
            position={sticky ? 'fixed' : undefined}
            top={sticky ? 50 : undefined}
            backgroundColor="semantic.surface"
            zIndex={theme.zIndices.stickyContent}
            width="100%"
            // eslint-disable-next-line no-restricted-syntax
            boxShadow={showShadow ? '-2px 0 4px 0 rgba(0, 0, 0, 0.5)' : 'none'}
            css={sticky ? hideWhenKeyboardIsOpen : undefined}
        >
            <Box display="flex">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href={staticRelativeCanonicals.BapHome}
                    aria-label="Suche"
                    css={css`
                        display: block;
                        padding: 13px 10px 9px 10px;
                        border-right: 1px solid ${theme.colors.palette.owl};
                        &:hover > *,
                        &:active > * {
                            color: ${theme.colors.palette.primary.active};
                        }
                    `}
                    onClick={() => callActionEvent('header_click_vertical_links_marketplace_icon', undefined)}
                >
                    <span>
                        <SearchIcon size={18} color="inherit" pointerEvents="auto" />
                    </span>
                </ClientRoutingAnchorLink>
                <Box padding="s" paddingTop="14px" paddingHorizontal="m" display="flex" justifyContent="space-between" flex="1 0 auto">
                    <VerticalLink
                        href={staticRelativeCanonicals.BapHome}
                        onClick={() => callActionEvent('header_click_vertical_links_marketplace', undefined)}
                    >
                        Marktplatz
                    </VerticalLink>
                    <VerticalLink
                        href={staticRelativeCanonicals.EstateHome}
                        onClick={() => callActionEvent('header_click_vertical_links_realestate', undefined)}
                    >
                        Immobilien
                    </VerticalLink>
                    <VerticalLink
                        href={staticRelativeCanonicals.MotorHome}
                        onClick={() => callActionEvent('header_click_vertical_links_motor', undefined)}
                    >
                        Auto & Motor
                    </VerticalLink>
                    <VerticalLink
                        href={staticRelativeCanonicals.JobsHome}
                        onClick={() => callActionEvent('header_click_vertical_links_jobs', undefined)}
                    >
                        Jobs
                    </VerticalLink>
                </Box>
            </Box>
            <Divider />
        </Box>
    )
}

export const VerticalLink: FunctionComponent<{ href: string; children: ReactNode; onClick?: () => void }> = ({
    href,
    onClick,
    children,
}) => {
    const router = useRouter()
    // remove the trailing /
    const normalizedHref = removeTrailingSlash(href)
    const active = router.pathname.startsWith(normalizedHref)

    return (
        <ClientRoutingAnchorLink
            type="anchor"
            href={href}
            onClick={onClick}
            color={active ? 'palette.primary.main' : 'palette.verydarkgrey'}
            css={css`
                font-weight: ${theme.fontWeights.semibold};
                font-size: ${theme.fontSizes.s};
                &:hover,
                &:active {
                    color: ${theme.colors.palette.primary.main};
                }
            `}
            underline="none"
            aria-current={active ? 'page' : undefined}
        >
            {children}
        </ClientRoutingAnchorLink>
    )
}
