/* eslint-disable max-lines */
import {
    actor,
    ClassifiedAdCore,
    ClassifiedAdObject,
    createClassifiedAdWithIdOnly,
    deployStage,
    getAdIdSdrn,
    getClassifiedAdCoreFromPulseDataListingEvent,
    getClassifiedAdObjectFromPulseData,
    make_jobs_classified_ad,
    Params,
    parsePulseData,
    provider,
    published,
} from '@wh/common/chapter/lib/tagging/taggingPulseTransformations'
import { TaggingActionEvent, TaggingPage, TaggingSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { ApiPulseData, ApiPulseListingObject } from '../../types/taggingData'
import { removeNullAndUndefined } from '../functionalHelpers'

export const createPulseLinkEventPayload = (eventName: TaggingActionEvent | TaggingSelfPromotionEvent, params: Params) => {
    if (eventName === 'addetail_favorite_ad_saved' || eventName === 'addetail_favorite_ad_unsaved') {
        const isSaved = eventName === 'addetail_favorite_ad_saved'

        const classifiedAdObject = getClassifiedAdObjectFromPulseData(params)

        if (!classifiedAdObject) {
            return undefined
        }

        const event = {
            name: isSaved ? 'Ad saved' : 'Ad unsaved',
            type: isSaved ? 'Save' : 'Unsave',
            object: classifiedAdObject,
            schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/save-ad-event.json/5.json',
            $schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/save-ad-event.json/5.json',
            provider: provider,
            page: {
                '@type': 'Page',
                '@id': `sdrn:willhabenat:page:addetail`,
            },
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            published: published(params),
            publicationDate: published(params),
        }

        return event
    }

    if (eventName === 'savedads_favorite_ad_unsaved') {
        const event = {
            name: 'Ad unsaved',
            type: 'Unsave',
            // we are using the object as it is provided by the saved ads api
            object: params.object ?? (createClassifiedAdWithIdOnly(`${params.favorite_ad as string}`) as unknown as ClassifiedAdCore),
            schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/save-ad-event.json/5.json',
            $schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/save-ad-event.json/5.json',
            provider: provider,
            page: {
                '@type': 'Page',
                '@id': 'sdrn:willhabenat:page:saved-ads',
            },
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            published: published(params),
            publicationDate: published(params),
        }

        return event
    }

    if (eventName === 'search_result_list_ad_saved' || eventName === 'search_result_list_ad_unsaved') {
        const isSaved = eventName === 'search_result_list_ad_saved'

        const classifiedAd = getClassifiedAdCoreFromPulseDataListingEvent(params, `${params.favorite_ad as string}`)

        const pulseData = parsePulseData(params)
        const listingEvent = pulseData?.pulseEvents?.[0] as ApiPulseListingObject
        const search = removeNullAndUndefined(listingEvent?.search)

        if (classifiedAd) {
            const event = {
                name: isSaved ? 'Ad saved' : 'Ad unsaved',
                type: isSaved ? 'Save' : 'Unsave',
                object: classifiedAd,
                schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/save-ad-event.json/5.json',
                $schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/save-ad-event.json/5.json',
                provider: provider,
                page: { '@type': 'Page', '@id': `sdrn:willhabenat:page:resultlist` },
                actor: actor(params.user_uuid),
                deployStage: deployStage(),
                search,
            }

            return event
        }
    }

    if (eventName === 'real_estate_result_list_nc_project_click') {
        const classifiedAd = getClassifiedAdCoreFromPulseDataListingEvent(params, `${params.ad_id as string}`)

        if (classifiedAd) {
            const engagementEvent = getEngagementEvent(
                classifiedAd,
                'NewConstructionProjectClick',
                'Click New Construction Project',
                'Click',
                'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/5.json ',
                'resultlist',
                params.user_uuid,
            )

            return engagementEvent
        }
    }

    if (eventName === 'real_estate_result_list_nc_object_click') {
        const engagementEvent = getEngagementEvent(
            createClassifiedAdWithIdOnly(`${params.ad_id as string}`) as unknown as ClassifiedAdCore, // does not fulfill the schema, but we accept it for this edge case
            'NewConstructionUnitClick',
            'Click New Construction Object',
            'Click',
            'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/5.json',
            'resultlist',
            params.user_uuid,
        )

        return engagementEvent
    }

    if (eventName === 'addetail_unit_click') {
        const classifiedAd = getClassifiedAdCoreFromPulseDataListingEvent(params, `${params.ad_id as string}`)

        if (classifiedAd) {
            const engagementEvent = getEngagementEvent(
                classifiedAd,
                'NewConstructionAdDetailClick',
                'Click New Construction Object Table Row',
                'Click',
                'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/5.json',
                'addetail',
                params.user_uuid,
            )

            return engagementEvent
        }
    }

    if (
        eventName === 'addetail_share_webshareapi_click' ||
        eventName === 'addetail_share_email_click' ||
        eventName === 'addetail_share_whatsapp_click' ||
        eventName === 'addetail_share_facebook_click' ||
        eventName === 'addetail_share_twitter_click' ||
        eventName === 'addetail_share_linkedin_click' ||
        eventName === 'addetail_share_link_click'
    ) {
        const classifiedAdObject = getClassifiedAdObjectFromPulseData(params)

        if (!classifiedAdObject) {
            return undefined
        }

        const engagementEvent = getEngagementEvent(
            classifiedAdObject,
            'ShareDialog',
            'Ad Shared',
            'Engagement',
            'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
            'addetail',
            params.user_uuid,
        )

        return engagementEvent
    }

    if (eventName === 'addetail_virtual_tour_click') {
        const classifiedAdObject = getClassifiedAdObjectFromPulseData(params)

        if (!classifiedAdObject) {
            return undefined
        }

        const engagementEvent = getEngagementEvent(
            classifiedAdObject,
            'VirtualTourLinkClicked',
            'Virtual Tour Link Clicked',
            'Engagement',
            'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
            'addetail',
            params.user_uuid,
        )

        return engagementEvent
    }

    if (eventName === 'addetail_send_message_click' || eventName === 'addetail_send_email_click') {
        const classifiedId = `${params.ad_id as string}`
        const classifiedAdObject = getClassifiedAdObjectFromPulseData(params)

        if (!classifiedAdObject) {
            return undefined
        }
        const clickEvent = {
            name: 'Ad reply submitted',
            type: 'Send',
            $schema: 'https://schema.adevinta.com/events/willhabenat/ljt/frontend/seller-contacted-event.json/1.json',
            schema: 'https://schema.adevinta.com/events/willhabenat/ljt/frontend/seller-contacted-event.json/1.json',
            object: {
                '@type': 'Message',
                '@id': `sdrn:willhabenat:message:${classifiedId}`,
                name: urlDecode(params.seller_name as string | undefined),
                telephone: urlDecode(params.seller_phone_number as string | undefined),
                inReplyTo: classifiedAdObject,
            },
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
            page: {
                '@id': 'sdrn:willhabenat:page:generalist:addetail',
                '@type': 'Page',
                pageType: 'ad_detail',
            },
        }

        return clickEvent
    }

    if (eventName === 'addetail_show_phone_number_click') {
        const classifiedId = `${params.ad_id as string}`
        const classifiedAdObject = getClassifiedAdObjectFromPulseData(params)

        if (!classifiedAdObject) {
            return undefined
        }

        const clickEvent = {
            name: 'Ad phone number displayed',
            type: 'Show',
            $schema: 'https://schema.adevinta.com/events/willhabenat/ljt/frontend/phone-number-displayed-event.json/1.json',
            schema: 'https://schema.adevinta.com/events/willhabenat/ljt/frontend/phone-number-displayed-event.json/1.json',
            object: {
                '@type': 'PhoneContact',
                '@id': `sdrn:willhabenat:phonecontact:${classifiedId}`,
                name: urlDecode(params.seller_name as string | undefined),
                telephone: urlDecode(params.seller_phone_number as string | undefined),
                inReplyTo: classifiedAdObject,
            },
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,

            page: {
                '@id': 'sdrn:willhabenat:page:generalist:addetail',
                '@type': 'Page',
                pageType: 'ad_detail',
            },
        }

        return clickEvent
    }

    if (eventName === 'addetail_call_phone_click') {
        const classifiedId = `${params.ad_id as string}`
        const classifiedAdObject = getClassifiedAdObjectFromPulseData(params)

        if (!classifiedAdObject) {
            return undefined
        }

        const clickEvent = {
            name: 'Ad phone number called',
            type: 'Call',
            target: { type: 'PhoneContact', inReplyTo: classifiedAdObject },
            $schema: 'https://schema.adevinta.com/events/willhabenat/ljt/frontend/seller-called-event.json/1.json',
            schema: 'https://schema.adevinta.com/events/willhabenat/ljt/frontend/seller-called-event.json/1.json',
            object: {
                type: 'PhoneContact',
                '@id': `sdrn:willhabenat:classified:${classifiedId}`,
                name: urlDecode(params.seller_name as string | undefined),
                inReplyTo: classifiedAdObject,
                category: classifiedAdObject?.category,
                telephone: params.ad_contact_phone,
                price: params.price,
            },
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
            page: {
                '@id': 'sdrn:willhabenat:page:generalist:addetail',
                '@type': 'Page',
                pageType: 'ad_detail',
            },
        }
        return clickEvent
    }

    if (eventName === 'addetail_dealer_website_click') {
        const classifiedAdObject = getClassifiedAdObjectFromPulseData(params)

        if (!classifiedAdObject) {
            return undefined
        }

        const clickEvent = {
            name: 'Homepage link',
            type: 'Click',
            $schema: 'https://schema.adevinta.com/events/willhabenat/ljt/frontend/homepage-click-event.json/4.json',
            schema: 'https://schema.adevinta.com/events/willhabenat/ljt/frontend/homepage-click-event.json/4.json',
            object: {
                '@id': `sdrn:willhabenat:classified:${params.ad_id as string}:element:HomepageClick`,
                '@type': 'ExternalLinkContact',
                linkType: 'DealerHomepage',
                inReplyTo: classifiedAdObject,
            },
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
            target: classifiedAdObject,
            page: {
                '@id': 'sdrn:willhabenat:page:ad_detail',
                '@type': 'Page',
                pageType: 'homepage',
            },
        }

        return clickEvent
    }

    if (eventName === 'addetail_order_now_click') {
        const classifiedAdObject = getClassifiedAdObjectFromPulseData(params)

        if (!classifiedAdObject) {
            return undefined
        }

        const clickEvent = {
            name: 'Product link',
            type: 'Click',
            $schema: 'https://schema.adevinta.com/events/willhabenat/ljt/frontend/order-online-button-click-event.json/2.json',
            schema: 'https://schema.adevinta.com/events/willhabenat/ljt/frontend/order-online-button-click-event.json/2.json',
            object: {
                '@type': 'ExternalLinkContact',
                '@id': `sdrn:willhabenat:classified:${params.ad_id as string}:element:OrderOnline`,
                linkType: 'OrderOnline',
                inReplyTo: classifiedAdObject,
            },
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
            page: {
                '@id': 'sdrn:willhabenat:page:ljt:OrderOnline',
                '@type': 'Page',
                pageType: 'ad_detail',
            },
        }

        return clickEvent
    }

    if (eventName === 'addetail_external_link_click') {
        const classifiedAdObject = getClassifiedAdObjectFromPulseData(params)

        if (!classifiedAdObject) {
            return undefined
        }

        const clickEvent = {
            name: 'Homepage link',
            type: 'Click',
            $schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/external-link-event.json/2.json',
            schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/external-link-event.json/2.json',
            object: {
                '@type': 'ExternalLinkContact',
                '@id': `sdrn:willhabenat:classified:${params.ad_id as string}:element:ExternalLink`,
                linkType: 'ExternalLink',
                inReplyTo: classifiedAdObject,
            },
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
            target: classifiedAdObject,
            page: {
                '@id': 'sdrn:willhabenat:page:ad_detail',
                '@type': 'Page',
                pageType: 'landingpage',
            },
        }

        return clickEvent
    }

    if (
        eventName === 'search_result_list_home_topad_view' ||
        eventName === 'search_result_list_home_topad_click' ||
        eventName === 'vertical_home_topad_click' ||
        eventName === 'vertical_home_topad_view' ||
        eventName === 'real_estate_vertical_home_topad_click' ||
        eventName === 'bap_vertical_home_topad_click' ||
        eventName === 'browse_topad_click'
    ) {
        const classifiedAd: ClassifiedAdCore = buildClassifiedAdForTopAds(params)

        if (!classifiedAd) {
            return undefined
        }

        let engagementEvent: Record<string, unknown>
        switch (eventName) {
            case 'search_result_list_home_topad_click':
                engagementEvent = getEngagementEvent(
                    classifiedAd,
                    'TopAdSearchResultClick',
                    'Top ad clicked',
                    'Engagement',
                    'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
                    'resultlist',
                    params.user_uuid,
                )
                break
            case 'browse_topad_click':
                engagementEvent = getEngagementEvent(
                    classifiedAd,
                    'TopAdStartPageClick',
                    'Top ad clicked',
                    'Engagement',
                    'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
                    'browsepage',
                    params.user_uuid,
                )
                break
            case 'real_estate_vertical_home_topad_click':
            case 'bap_vertical_home_topad_click':
            case 'vertical_home_topad_click':
                engagementEvent = getEngagementEvent(
                    classifiedAd,
                    'TopAdStartPageClick',
                    'Top ad clicked',
                    'Engagement',
                    'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
                    'vertical_startpage',
                    params.user_uuid,
                )
                break
            case 'vertical_home_topad_view':
                engagementEvent = getEngagementEvent(
                    classifiedAd,
                    'TopAdStartPageView',
                    'Top ad viewed',
                    'Engagement',
                    'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
                    'vertical_startpage',
                    params.user_uuid,
                )
                break
            case 'search_result_list_home_topad_view':
                engagementEvent = getEngagementEvent(
                    classifiedAd,
                    'TopAdSearchResultView',
                    'Top ad viewed',
                    'Engagement',
                    'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
                    'resultlist',
                    params.user_uuid,
                )
                break
        }

        return engagementEvent
    }

    if (
        eventName === 'search_result_list_adinmotion_click' ||
        eventName === 'search_result_list_adinmotion_view' ||
        eventName === 'search_result_list_adinmotion_start' ||
        eventName === 'search_result_list_pole_position_click' ||
        eventName === 'search_result_list_pole_position_view'
    ) {
        const classifiedAd = getClassifiedAdCoreFromPulseDataListingEvent(params, `${params.ad_id as string}`)

        if (!classifiedAd) {
            return undefined
        }

        let engagementEvent: Record<string, unknown>
        switch (eventName) {
            case 'search_result_list_adinmotion_click':
                engagementEvent = getEngagementEvent(
                    classifiedAd,
                    'AdInMotionClick',
                    'Ad in Motion clicked',
                    'Engagement',
                    'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
                    'resultlist',
                    params.user_uuid,
                )
                break
            case 'search_result_list_adinmotion_view':
                engagementEvent = getEngagementEvent(
                    classifiedAd,
                    'AdInMotionView',
                    'AdInMotion View',
                    'Engagement',
                    'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
                    'resultlist',
                    params.user_uuid,
                )
                break
            case 'search_result_list_adinmotion_start':
                engagementEvent = getEngagementEvent(
                    classifiedAd,
                    'AdInMotionFirstInteraction',
                    'AdInMotion First Interaction',
                    'Engagement',
                    'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
                    'resultlist',
                    params.user_uuid,
                )
                break
            case 'search_result_list_pole_position_click':
                engagementEvent = getEngagementEvent(
                    classifiedAd,
                    'PolePositionClick',
                    'Pole Position Click',
                    'Engagement',
                    'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
                    'resultlist',
                    params.user_uuid,
                )
                break
            case 'search_result_list_pole_position_view':
                engagementEvent = getEngagementEvent(
                    classifiedAd,
                    'PolePositionView',
                    'Pole Position View',
                    'Engagement',
                    'https://schema.adevinta.com/events/willhabenat/search/frontend/ad-engagement-event.json/6.json',
                    'resultlist',
                    params.user_uuid,
                )
                break
        }

        return engagementEvent
    }

    if (eventName === 'jobs_addetail_savead_save_click' || eventName === 'jobs_addetail_savead_unsave_click') {
        const isSaved = eventName === 'jobs_addetail_savead_save_click'
        const clickEvent = {
            name: isSaved ? 'Ad saved' : 'Ad unsaved',
            type: isSaved ? 'Save' : 'Unsave',
            object: make_jobs_classified_ad(params),
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
        }

        return clickEvent
    }

    if (eventName === 'ad_widget_loaded' || eventName === 'ad_widget_in_view') {
        const widgetType = params.taggingWidgetType as string
        const source = params.source as string
        const listName = params.listName as string
        const transactionId = params.transactionId as string | undefined
        const adIds = params.adIds as (string | number)[] | undefined
        const additionalInfoJobs = params.additionalInfoJobs
        const additionalInfoObject = params.additionalInfoObject

        if (!listName) {
            return undefined
        }

        const widgetLoadedEvent = {
            type: eventName === 'ad_widget_loaded' ? 'View' : 'Engagement',
            name: eventName === 'ad_widget_loaded' ? `Recommendation widget impression` : `Recommendation widget viewable impression`,
            object: {
                '@type': 'RecommendationList',
                numItems: adIds?.length,
                items: adIds?.map((adId) => buildRecommendationItem(adId)),
                ...(additionalInfoObject && { 'spt:custom': additionalInfoObject }),
            },
            recommendation: {
                '@id': transactionId ? `sdrn:willhabenat:recommendation:${transactionId}` : undefined,
                '@type': 'RecommendationMetadata',
                recommendationType: getRecommendationTypeFromWidgetType(widgetType),
                source: source,
                listName: listName,
            },
            schema: 'http://schema.adevinta.com/events/tracker-event.json/307.json',
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
            ...(additionalInfoJobs && { 'spt:custom': additionalInfoJobs }),
        }

        return widgetLoadedEvent
    }

    if (eventName === 'ad_widget_ad_click') {
        const widgetType = params.taggingWidgetType as string
        const adId = params.adId as string | number
        const adIndex = params.adIndex as number
        const source = params.source as string
        const listName = params.listName as string
        const transactionId = params.transactionId as string | undefined
        const additionalInfoJobs = params.additionalInfoJobs
        const additionalInfoObject = params.additionalInfoObject

        const adClickedEvent = {
            type: 'Click',
            name: 'Recommendation widget clicked',
            object: buildRecommendationItem(adId, adIndex, additionalInfoObject),
            recommendation: {
                '@id': transactionId ? `sdrn:willhabenat:recommendation:${transactionId}` : undefined,
                '@type': 'RecommendationMetadata',
                recommendationType: getRecommendationTypeFromWidgetType(widgetType),
                source: source,
                listName: listName,
            },
            schema: 'http://schema.adevinta.com/events/tracker-event.json/307.json',
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
            ...(additionalInfoJobs && { 'spt:custom': additionalInfoJobs }),
        }

        return adClickedEvent
    }

    if (eventName === 'recommendation_widget_ad_click') {
        const widgetType = params.taggingWidgetType as string
        const adId = params.adId as string | number
        const adIndex = params.adIndex as number
        const source = params.source as string
        const listName = params.listName as string
        const transactionId = params.transactionId as string | undefined
        const additionalInfoObject = params.additionalInfoObject

        const adClickedEvent = {
            type: 'Click',
            name: 'Recommendation widget clicked',
            object: buildRecommendationItem(adId, adIndex, additionalInfoObject),
            recommendation: {
                '@id': transactionId ? `sdrn:willhabenat:recommendation:${transactionId}` : undefined,
                '@type': 'RecommendationMetadata',
                recommendationType: getRecommendationTypeFromWidgetType(widgetType),
                source: source,
                listName: listName,
            },
            schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/recommendation-item-event.json/0.json',
            $schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/recommendation-item-event.json/0.json',
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
            page: { '@type': 'Page', '@id': `sdrn:willhabenat:page:${source}` },
        }

        return adClickedEvent
    }

    if ((eventName === 'ad_widget_show_all_button_click' || eventName === 'ad_widget_show_all_click') && params.forcePulse) {
        const widgetType = params.taggingWidgetType as string
        const source = params.source as string
        const listName = params.listName as string
        const transactionId = params.transactionId as string | undefined
        const additionalInfoJobs = params.additionalInfoJobs
        const additionalInfoObject = params.additionalInfoObject
        const widgetInteractionId = params.widgetInteractionId as string | undefined

        return {
            type: 'Click',
            name: 'Recommendation widget clicked',
            object: {
                '@type': 'RecommendationItem',
                '@id': widgetInteractionId ? `sdrn:willhabenat:widget-interaction:${widgetInteractionId}` : undefined,
                ...(additionalInfoObject && { 'spt:custom': additionalInfoObject }),
            },
            recommendation: {
                '@id': transactionId ? `sdrn:willhabenat:recommendation:${transactionId}` : undefined,
                '@type': 'RecommendationMetadata',
                recommendationType: getRecommendationTypeFromWidgetType(widgetType),
                source: source,
                listName: listName,
            },
            schema: 'http://schema.adevinta.com/events/tracker-event.json/307.json',
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
            ...(additionalInfoJobs && { 'spt:custom': additionalInfoJobs }),
        }
    }

    if (
        (eventName === 'recommendation_widget_show_all_button_click' ||
            eventName === 'recommendation_widget_show_all_click' ||
            eventName === 'recommendation_widget_header_click' ||
            eventName === 'recommendation_widget_arrow_click') &&
        params.forcePulse
    ) {
        const widgetType = params.taggingWidgetType as string
        const source = params.source as string
        const listName = params.listName as string
        const transactionId = params.transactionId as string | undefined
        const widgetInteractionId = params.widgetInteractionId as string | undefined

        return {
            type: 'Click',
            name: 'Recommendation widget clicked',
            object: {
                '@type': 'UIElement',
                '@id': widgetInteractionId ? `sdrn:willhabenat:widget-interaction:${widgetInteractionId}` : undefined,
                elementType: buildElementType(eventName),
            },
            recommendation: {
                '@id': transactionId ? `sdrn:willhabenat:recommendation:${transactionId}` : undefined,
                '@type': 'RecommendationMetadata',
                recommendationType: getRecommendationTypeFromWidgetType(widgetType),
                source: source,
                listName: listName,
            },
            schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/recommendation-ui-element-event.json/1.json',
            $schema: 'https://schema.adevinta.com/events/willhabenat/search/frontend/recommendation-ui-element-event.json/1.json',
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
            page: { '@type': 'Page', '@id': `sdrn:willhabenat:page:${source}` },
        }
    }

    if (eventName === 'jobs_addetail_application_internal_send_click') {
        const clickEvent = {
            name: 'Job application sent',
            type: 'Send',
            object: {
                '@type': 'Message',
                '@id': 'sdrn:willhabenat:message:0',
                inReplyTo: make_jobs_classified_ad(params),
            },
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
        }

        return clickEvent
    }

    if (eventName === 'jobs_addetail_application_external_open_click') {
        const url = document.location.href
        const clickEvent = {
            name: 'Apply now button',
            type: 'Click',
            object: {
                '@type': 'ExternalLinkContact',
                '@id': `sdrn:willhabenat:externallinkcontact:${url}`,
                url: url,
                inReplyTo: {
                    ...make_jobs_classified_ad(params),
                    url: url,
                },
            },
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
        }

        return clickEvent
    }

    if (eventName.startsWith('jobs_addetail_share_')) {
        let shareType: string
        switch (eventName) {
            case 'jobs_addetail_share_webshareapi_click':
                shareType = 'WebShareApi'
                break
            case 'jobs_addetail_share_email_click':
                shareType = 'Email'
                break
            case 'jobs_addetail_share_whatsapp_click':
                shareType = 'WhatsApp'
                break
            case 'jobs_addetail_share_facebook_click':
                shareType = 'Facebook'
                break
            case 'jobs_addetail_share_twitter_click':
                shareType = 'Twitter'
                break
            case 'jobs_addetail_share_linkedin_click':
                shareType = 'LinkedIn'
                break
            case 'jobs_addetail_share_link_click':
                shareType = 'Link'
                break
            case 'jobs_addetail_print_click':
                shareType = 'Print'
                break
            default:
                shareType = 'Share'
                break
        }

        const classifiedAd = make_jobs_classified_ad(params)
        const shareTypeLabel = shareType === 'Share' ? '' : `${shareType}`
        const clickEvent = {
            type: 'Share',
            element: {
                '@id': `${classifiedAd['@id']}:element:${shareType.toLowerCase()}`,
                '@type': 'UIElement',
                name: `${shareTypeLabel}Share Button`,
            },
            object: classifiedAd,
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
        }
        return clickEvent
    }

    if (eventName === 'jobs_resultlist_savead_click' || eventName === 'jobs_resultlist_unsavead_click') {
        const isSaved = eventName === 'jobs_resultlist_savead_click'
        const pulseData: ApiPulseData | undefined = params.pulse_data
            ? (JSON.parse(params.pulse_data as string) as ApiPulseData)
            : undefined
        const jobAd = pulseData?.items?.find((item) => item.adId === params.favorite_ad)

        const clickEvent = {
            name: isSaved ? 'Ad saved' : 'Ad unsaved',
            type: isSaved ? 'Save' : 'Unsave',
            object: jobAd,
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            provider: provider,
        }

        return clickEvent
    }

    return undefined
}

export const createImpliedPostEventPayload = (eventName: TaggingPage, params: Params) => {
    if (eventName === 'ad_insertion_confirmation') {
        const object = getClassifiedAdObjectFromPulseData(params)
        return {
            type: 'Create',
            name: 'Ad insertion confirmed',
            object,
            actor: actor(params.user_uuid),
            deployStage: deployStage(),
            published: published(params),
            publicationDate: published(params),
            provider,
        }
    }

    return undefined
}

const getEngagementEvent = (
    classifiedAd: ClassifiedAdCore | ClassifiedAdObject,
    elementType: string,
    name: string,
    action: 'Click' | 'View' | 'Engagement',
    schema: string,
    page: string,
    user_uuid: unknown,
) => ({
    name: name,
    type: action,
    object: {
        '@type': 'UIElement',
        '@id': `${classifiedAd['@id']}:element:${elementType}`,
        elementType: elementType,
    },
    schema: schema,
    $schema: schema,
    target: classifiedAd,
    provider: provider,
    page: { '@type': 'Page', '@id': `sdrn:willhabenat:page:${page}` },
    actor: actor(user_uuid),
    deployStage: deployStage(),
})

const buildClassifiedAdForTopAds = (params: Readonly<Record<string, unknown>>): ClassifiedAdCore => ({
    '@id': getAdIdSdrn(params.ad_id as string),
    '@type': 'ClassifiedAd',
    adUuid: params.ad_uuid as string,
    publisher: buildPublisher(params.org_uuid as string),
})

const urlDecode = (value: string | undefined) => {
    return value ? decodeURIComponent(value.replace(/\+/g, ' ')) : value
}

const buildRecommendationItem = (
    adId: string | number,
    rank?: number,
    additionalInfoObject?: string | number | Record<string, unknown> | (string | number | Record<string, unknown>)[],
) => ({
    '@id': getAdIdSdrn(adId),
    '@type': 'RecommendationItem',
    rank: rank,
    ...(additionalInfoObject && { 'spt:custom': additionalInfoObject }),
})

const getRecommendationTypeFromWidgetType = (widgetType: string) => {
    return widgetType === 'RecommendationWidget'
        ? 'user-based'
        : widgetType === 'ItemRecommendationWidget'
          ? 'related-items'
          : 'external-widget'
}

const buildElementType = (eventName: string) => {
    switch (eventName) {
        case 'recommendation_widget_show_all_button_click':
            return 'showAllButton'
        case 'recommendation_widget_show_all_click':
            return 'showAllLink'
        case 'recommendation_widget_header_click':
            return 'widgetHeader'
        case 'recommendation_widget_arrow_click':
            return 'arrow'
        default:
            return 'Element'
    }
}

export const buildPublisher = (userUuid: string | number | undefined) => {
    if (!userUuid) {
        return undefined
    }
    return { '@id': `sdrn:willhabenat:user:${userUuid}`, '@type': 'Account' }
}
