import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { ActionSheet, ActionSheetItemType } from '@wh-components/core/ActionSheet/ActionSheet'
import { Button } from '@wh-components/core/Button/Button'
import ContrastSwitchIcon from '@wh-components/icons/ContrastSwitch'
import { Text } from '@wh-components/core/Text/Text'
import { ThemeSwitcherContext } from '../ThemeSetter'
import { Box } from '@wh-components/core/Box/Box'
import CheckThickIcon from '@wh-components/icons/CheckThick'
import { ServerRoutingAnchorLink } from '../AnchorLink/AnchorLink'
import { WillhabenTippy } from '@wh-components/core/Tippy/Tippy'
import { css } from 'styled-components'
import InfoIcon from '@wh-components/icons/AlertInfo'
import { ConditionalWrapper } from '@wh-components/core/ConditionalWrapper/ConditionalWrapper'
import { toast } from '@wh-components/core/Toast/Toast'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import eclipseIconUrl from './img/eclipse.svg'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import themeSymbolsUrl from './img/theme-symbols.svg'
import { theme as styledComponentsTheme } from '@wh-components/core/theme'
import { TransparentButton } from '../TransparentButton'
import { SpaceProps } from '@wh-components/system/space'

interface ThemeSwitcherProps extends SpaceProps {
    variant: 'icon' | 'icon-with-text'
}

export const ThemeSwitcherWithTooltip: FunctionComponent<ThemeSwitcherProps> = (props) => {
    const [showTooltip, setShowTooltip] = useState<false | 'light-contrast' | 'dark-promo' | 'dark'>(false)
    useEffect(() => {
        if (!(typeof localStorage !== 'undefined' && localStorage !== null)) {
            return
        }

        setShowTooltip(localStorage.getItem('bbx-theme-auto-enabled') as typeof showTooltip)
    }, [])

    const isMobile = useResponsiveValue({ phone: true, tablet: false }, false)

    useEffect(() => {
        if (showTooltip && isMobile) {
            switch (showTooltip) {
                case 'light-contrast':
                    toast(<TooltipContentLightContrastMobile />, { type: 'info', autoClose: false })
                    setShowTooltip(false)
                    localStorage.removeItem('bbx-theme-auto-enabled')
                    break
                case 'dark-promo':
                    toast(<TooltipContentDarkPromoMobile />, { type: 'info', autoClose: false })
                    setShowTooltip(false)
                    localStorage.removeItem('bbx-theme-auto-enabled')
                    break
                case 'dark':
                    toast(<TooltipContentDarkMobile />, { type: 'info', autoClose: false })
                    setShowTooltip(false)
                    localStorage.removeItem('bbx-theme-auto-enabled')
                    break
            }
        }
    }, [isMobile, showTooltip])

    const hideTooltip = () => {
        setShowTooltip(false)
        localStorage.removeItem('bbx-theme-auto-enabled')
    }

    return (
        <ConditionalWrapper
            condition={!!showTooltip}
            wrapper={(children) => (
                <WillhabenTippy
                    content={
                        showTooltip === 'light-contrast' ? (
                            <TooltipContentLightContrastDesktop />
                        ) : showTooltip === 'dark-promo' ? (
                            <TooltipContentDarkPromoDesktop />
                        ) : showTooltip === 'dark' ? (
                            <TooltipContentDarkDesktop />
                        ) : undefined
                    }
                    showOnCreate={true}
                    trigger="manual"
                    hideOnClick={true}
                    onClickOutside={() => {
                        hideTooltip()
                    }}
                    css={css`
                        &.tippy-box {
                            background-color: ${(p) => p.theme.colors.semantic.primary.container};

                            &[data-placement^='top'] > .tippy-arrow::before {
                                border-top-color: ${(p) => p.theme.colors.semantic.primary.container};
                            }

                            &[data-placement^='bottom'] > .tippy-arrow::before {
                                border-bottom-color: ${(p) => p.theme.colors.semantic.primary.container};
                            }

                            &[data-placement^='left'] > .tippy-arrow::before {
                                border-left-color: ${(p) => p.theme.colors.semantic.primary.container};
                            }

                            &[data-placement^='right'] > .tippy-arrow::before {
                                border-right-color: ${(p) => p.theme.colors.semantic.primary.container};
                            }

                            &[data-reference-hidden] {
                                opacity: 0;
                                pointer-events: none;
                            }
                        }
                    `}
                >
                    <Box onFocusCapture={() => hideTooltip()}>{children}</Box>
                </WillhabenTippy>
            )}
        >
            <ThemeSwitcher {...props} />
        </ConditionalWrapper>
    )
}

interface ThemeSwitcherProps {
    variant: 'icon' | 'icon-with-text'
}

export const ThemeSwitcher: FunctionComponent<ThemeSwitcherProps> = ({ variant, ...layoutProps }) => {
    const [open, setOpen] = React.useState(false)

    const { theme, changeTheme } = useContext(ThemeSwitcherContext)

    const themeItems: ActionSheetItemType[] = [
        {
            label: (
                <TransparentButton onClick={() => changeTheme('light')} width="100%">
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="s"
                        aria-selected={theme === 'light'}
                        paddingVertical="s"
                        fontWeight={theme === 'light' ? 'bold' : undefined}
                        color="semantic.foreground"
                    >
                        {/* eslint-disable-next-line no-restricted-syntax */}
                        <svg role="img" aria-hidden="true" style={{ width: 24, height: 24, display: 'block', color: '#FBE540' }}>
                            <use href={`${themeSymbolsUrl}#light`} />
                        </svg>
                        Hell
                        {theme === 'light' && <CheckThickIcon size={16} color="semantic.foreground" />}
                    </Box>
                </TransparentButton>
            ),
            onClick: () => changeTheme('light'),
        },
        {
            label: (
                <TransparentButton onClick={() => changeTheme('light-contrast')} width="100%">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="s"
                        paddingVertical="s"
                        color="semantic.foreground"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            gap="s"
                            aria-selected={theme === 'light-contrast'}
                            fontWeight={theme === 'light-contrast' ? 'bold' : undefined}
                        >
                            {/* eslint-disable-next-line no-restricted-syntax */}
                            <svg role="img" aria-hidden="true" style={{ width: 24, height: 24, display: 'block', color: '#D7BE02' }}>
                                <use href={`${themeSymbolsUrl}#light`} />
                            </svg>
                            Hoher Kontrast Hell
                            {theme === 'light-contrast' && <CheckThickIcon size={16} color="semantic.foreground" />}
                        </Box>
                        Hoher Kontrast, um die Lesbarkeit zu verbessern.
                    </Box>
                </TransparentButton>
            ),
            onClick: () => changeTheme('light-contrast'),
        },
        {
            label: (
                <TransparentButton onClick={() => changeTheme('dark')} width="100%">
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="s"
                        aria-selected={theme === 'dark'}
                        paddingVertical="s"
                        fontWeight={theme === 'dark' ? 'bold' : undefined}
                        color="semantic.foreground"
                    >
                        <svg
                            role="img"
                            aria-hidden="true"
                            style={{
                                width: 24,
                                height: 24,
                                display: 'block',
                                color: styledComponentsTheme.colors.semantic.foreground.alternate,
                            }}
                        >
                            <use href={`${themeSymbolsUrl}#dark`} />
                        </svg>
                        Dunkel
                        {theme === 'dark' && <CheckThickIcon size={16} color="semantic.foreground" />}
                    </Box>
                </TransparentButton>
            ),
            onClick: () => changeTheme('dark'),
        },
    ]

    return (
        <ActionSheet
            header={
                <Text fontSize="m" fontWeight="bold" color="semantic.foreground" truncate={true} paddingVertical="s">
                    Farbmodus wählen
                </Text>
            }
            footer={
                <Box maxWidth={500} paddingVertical="s" fontSize="s">
                    <Box>Fragen oder Probleme?</Box>
                    <Box>
                        Kontaktiere bitte den{' '}
                        <ServerRoutingAnchorLink
                            type="anchor"
                            href="https://hilfe.willhaben.at/hc/de/requests/new"
                            target="_blank"
                            css={css`
                                text-decoration: underline;
                            `}
                        >
                            Support
                        </ServerRoutingAnchorLink>
                    </Box>
                </Box>
            }
            items={themeItems}
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            dropdownPlacement="bottom-end"
        >
            <Button
                onClick={() => setOpen((oldOpen) => !oldOpen)}
                size="medium"
                variant="transparent"
                color="complimentary"
                paddingHorizontal={variant === 'icon' ? 's' : 0}
                title="Farbmodus wählen"
                aria-label="Farbmodus wählen"
                height="auto"
                {...layoutProps}
            >
                <ContrastSwitchIcon size={20} color="semantic.foreground.alternate" marginTop={variant === 'icon' ? -1 : 0} />
                <Text
                    display={variant === 'icon' ? 'none' : undefined}
                    marginLeft="m"
                    color="semantic.foreground"
                    fontWeight="regular"
                    fontSize="l"
                >
                    Farbmodus wählen
                </Text>
            </Button>
        </ActionSheet>
    )
}

const TooltipContentLightContrastDesktop = () => (
    <Box display="flex" alignItems="center" color="semantic.primary" padding="s">
        <InfoIcon size={40} color="inherit" marginRight="m" aria-hidden={true} />
        Hoher Kontrast Modus wurde automatisch aktiviert. Hier klicken um den Farbmodus zu ändern.
    </Box>
)

const TooltipContentLightContrastMobile = () => (
    <Box display="flex" alignItems="center" color="semantic.primary" padding="s">
        <InfoIcon size={40} color="inherit" marginRight="m" aria-hidden={true} />
        Hoher Kontrast Modus wurde automatisch aktiviert. Im Menü links oben lässt sich der Farbmodus zu ändern.
    </Box>
)

const TooltipContentDarkPromoDesktop = () => (
    <Box display="flex" alignItems="center" color="semantic.primary" padding="s">
        <ResponsiveImage width={40} height={40} src={eclipseIconUrl} marginRight="m" aria-hidden={true} />
        <Box>
            <Box fontWeight="bold" marginBottom="s">
                Du magst es lieber dunkel?
            </Box>
            <Box>
                Wir nehmen die heutige Sonnenfinsternis zum Anlass, um dich von unserem dunklen Farbmodus zu begeistern! Klicke hier um
                deinen Farbmodus zu wechseln.
            </Box>
        </Box>
    </Box>
)

const TooltipContentDarkPromoMobile = () => (
    <Box display="flex" alignItems="center" color="semantic.primary" padding="s">
        <ResponsiveImage width={40} height={40} src={eclipseIconUrl} marginRight="m" aria-hidden={true} />
        <Box>
            <Box fontWeight="bold" marginBottom="s">
                Du magst es lieber dunkel?
            </Box>
            <Box>
                Wir nehmen die heutige Sonnenfinsternis zum Anlass, um dich von unserem dunklen Farbmodus zu begeistern! Im Menü links oben
                lässt sich der Farbmodus zu ändern.
            </Box>
        </Box>
    </Box>
)

const TooltipContentDarkDesktop = () => (
    <Box display="flex" alignItems="center" color="semantic.primary" padding="s">
        <InfoIcon size={40} color="inherit" marginRight="m" aria-hidden={true} />
        <Box>
            <Box fontWeight="bold" marginBottom="s">
                Du magst es lieber dunkel?
            </Box>
            <Box>Wir haben willhaben nach deinen System-Einstellungen angepasst. Klicke hier um deinen Farbmodus zu wechseln.</Box>
        </Box>
    </Box>
)

const TooltipContentDarkMobile = () => (
    <Box display="flex" alignItems="center" color="semantic.primary" padding="s">
        <InfoIcon size={40} color="inherit" marginRight="m" aria-hidden={true} />
        <Box>
            <Box fontWeight="bold" marginBottom="s">
                Du magst es lieber dunkel?
            </Box>
            <Box>
                Wir haben willhaben nach deinen System-Einstellungen angepasst. Im Menü links oben lässt sich der Farbmodus zu ändern.
            </Box>
        </Box>
    </Box>
)
