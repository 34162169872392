import React, { FunctionComponent, useContext } from 'react'
import { theme } from '@wh-components/core/theme'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'

export const BackgroundIframe: FunctionComponent = () => {
    const advertisingState = useContext(AdvertisingStateContext)
    const backgroundIframeBackgroundColor = advertisingState.pageModifications.backgroundColors?.['background-iframe']
    const setGradient = advertisingState.pageModifications.headerGradient
    const shouldRenderBackgroundIframeBasedOnBreakpoint = useResponsiveValue({ phone: false, tablet: true }, false)
    const shouldRenderBackgroundIframe =
        shouldRenderBackgroundIframeBasedOnBreakpoint &&
        (advertisingState.pageModifications.backgroundIFrame?.src || backgroundIframeBackgroundColor)

    if (!shouldRenderBackgroundIframe) {
        return null
    }

    return (
        <Box>
            {setGradient && (
                <Box
                    id="gradient-header"
                    zIndex={theme.zIndices.backgroundAd + 1}
                    height="140px"
                    position="absolute"
                    top={0}
                    width="100%"
                    // eslint-disable-next-line no-restricted-syntax
                    background="linear-gradient(180deg, rgba(0,0,0, 0.8) 0%, rgba(0,0,0,0) 100%)"
                />
            )}
            <iframe
                src={advertisingState.pageModifications.backgroundIFrame?.src || ''}
                frameBorder="0"
                title="Werbung"
                aria-hidden="true"
                css={css`
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    position: ${advertisingState.pageModifications.backgroundIFrame?.position || 'absolute'};
                    z-index: ${(p) => p.theme.zIndices.backgroundAd};
                    pointer-events: auto;
                    background-color: ${backgroundIframeBackgroundColor};

                    ${(p) => p.theme.media.print} {
                        display: none;
                    }
                `}
            />
        </Box>
    )
}
