import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import { Avatar } from '@wh-components/core/Avatar/Avatar'
import React, { FunctionComponent } from 'react'
import { InternalRichText } from './InternalRichText'
import { Box } from '@wh-components/core/Box/Box'

interface StoryblokContact extends StoryblokComponentType<string> {
    image: StoryblokAsset
    text: SbBlokData
}

interface Props extends StoryblokDefaultProps<StoryblokContact> {}

export const StoryblokContact: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <Box display="flex" gap="s" {...storyblokEditable(blok as unknown as SbBlokData)}>
            <div>
                <Avatar src={`${blok.image.filename}/m/256x256`} size={64} alt={blok.image.alt} />
            </div>
            <div>
                <InternalRichText blok={blok as unknown as SbBlokData} richTextKey="text" inline={true} story={story} />
            </div>
        </Box>
    )
}
