import React, { FunctionComponent } from 'react'
import { ServerRoutingAnchorLink, ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import SocialInstagramIcon from '@wh-components/icons/SocialInstagram'
import SocialFacebookIcon from '@wh-components/icons/SocialFacebook'
import SocialYoutubeIcon from '@wh-components/icons/SocialYoutube'
import SocialTiktokIcon from '@wh-components/icons/SocialTiktok'
import gptw_logo from '@wh/common/chapter/static_hashed/img/footer/gptw.png'
import gptw_logoX2 from '@wh/common/chapter/static_hashed/img/footer/gptw_x2.png'
import styled, { css } from 'styled-components'
import { LazyPicture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import { Box } from '@wh-components/core/Box/Box'
import SocialLinkedin from '@wh-components/icons/SocialLinkedin'

export const BottomNavigation: FunctionComponent = () => (
    <BottomNav>
        <Box display={{ phone: 'none', tablet: 'block' }}>
            <ServerRoutingAnchorLink
                type="anchor"
                href="https://www.greatplacetowork.at/workplace/item/104/willhaben+internet+service+GmbH"
                target="_blank"
                testId="footer-greatplacetowork-link"
                rel="nofollow"
                display="flex"
                marginRight="20px"
            >
                <LazyPicture
                    imgSrcSet={{
                        x1: gptw_logo,
                        x2: gptw_logoX2,
                    }}
                    alt="Beste Arbeitgeber Österreichs 2023 Logo"
                    title="Beste Arbeitgeber Österreichs 2023"
                    height="70px"
                />
            </ServerRoutingAnchorLink>
        </Box>
        <NavList
            css={css`
                ${(p) => p.theme.media.tablet} {
                    flex-grow: 1;
                }
            `}
        >
            <NavListItem>
                <ServerRoutingAnchorLink type="anchor" href="https://hilfe.willhaben.at/" target="_blank" testId="footer-help-link">
                    Hilfe und Kontakt
                </ServerRoutingAnchorLink>
            </NavListItem>
            <NavListItem>
                <ClientRoutingAnchorLink type="anchor" href="/iad/agb" testId="footer-termsandconditions-link">
                    AGB
                </ClientRoutingAnchorLink>
            </NavListItem>
            <NavListItem>
                <ClientRoutingAnchorLink type="anchor" href="/iad/datenschutzerklaerung" testId="footer-data-privacy-link">
                    Datenschutzerklärung
                </ClientRoutingAnchorLink>
            </NavListItem>
            <NavListItem>
                <ClientRoutingAnchorLink type="anchor" href="/iad/impressum" testId="footer-impress-link">
                    Impressum
                </ClientRoutingAnchorLink>
            </NavListItem>
            <NavListItem>© {new Date().getFullYear()} willhaben. Alle Rechte vorbehalten.</NavListItem>
        </NavList>

        <SocialMediaList>
            <ServerRoutingAnchorLink
                type="anchor"
                href="https://www.instagram.com/willhaben/"
                target="_blank"
                color="palette.koala"
                aria-label="Zum willhaben Instagram Profil"
                testId="footer-instagram-link"
                css={css`
                    &:hover {
                        color: ${(p) => p.theme.colors.socialMedia.instagram};
                    }
                `}
            >
                <SocialInstagramIcon size="medium" color="inherit" />
            </ServerRoutingAnchorLink>
            <ServerRoutingAnchorLink
                type="anchor"
                href="https://www.tiktok.com/@willhaben"
                target="_blank"
                color="palette.koala"
                aria-label="Zum willhaben TikTok Profil"
                testId="footer-tiktok-link"
                css={css`
                    &:hover {
                        color: ${(p) => p.theme.colors.palette.verydarkgrey};
                    }
                `}
            >
                <SocialTiktokIcon size="medium" color="inherit" />
            </ServerRoutingAnchorLink>
            <ServerRoutingAnchorLink
                type="anchor"
                href="https://www.youtube.com/user/willhabenvideo/"
                target="_blank"
                color="palette.koala"
                aria-label="Zum willhaben YouTube Kanal"
                testId="footer-youtube-link"
                css={css`
                    &:hover {
                        color: ${(p) => p.theme.colors.socialMedia.youtube};
                    }
                `}
            >
                <SocialYoutubeIcon size="medium" color="inherit" />
            </ServerRoutingAnchorLink>
            <ServerRoutingAnchorLink
                type="anchor"
                href="https://www.linkedin.com/company/willhaben/"
                target="_blank"
                color="palette.koala"
                aria-label="Zum willhaben LinkedIn Profil"
                testId="footer-linkedin-link"
                css={css`
                    &:hover {
                        color: ${(p) => p.theme.colors.socialMedia.linkedin};
                    }
                `}
            >
                <SocialLinkedin size="medium" color="inherit" />
            </ServerRoutingAnchorLink>
            <ServerRoutingAnchorLink
                type="anchor"
                href="https://www.facebook.com/willhaben.at/"
                target="_blank"
                color="palette.koala"
                aria-label="Zum willhaben Facebook Profil"
                testId="footer-facebook-link"
                css={css`
                    &:hover {
                        color: ${(p) => p.theme.colors.socialMedia.facebook};
                    }
                `}
            >
                <SocialFacebookIcon size="medium" color="inherit" />
            </ServerRoutingAnchorLink>
        </SocialMediaList>
    </BottomNav>
)

const BottomNav = styled.nav`
    padding-top: ${(p) => p.theme.space.m}px;
    padding-bottom: ${(p) => p.theme.space.l}px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(p) => p.theme.media.tablet} {
        margin-top: -9px;
        padding-top: 0;
        align-items: flex-end;
        justify-content: space-between;
    }
`

const NavList = styled.ul`
    padding: 0 ${(p) => p.theme.space.m}px;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;

    ${(p) => p.theme.media.tablet} {
        padding: 0;
    }

    > *:not(:last-child) {
        margin-right: ${(p) => p.theme.space.m}px;
    }
`

const NavListItem = styled.li`
    font-size: 11px;

    a {
        color: ${(p) => p.theme.colors.palette.verydarkgrey};
    }
`

const SocialMediaList = styled.div`
    height: 24px;
    display: none;

    ${(p) => p.theme.media.tablet} {
        display: flex;

        > *:not(:last-child) {
            margin-right: ${(p) => p.theme.space.s}px;
        }
    }
`
