import { Badge, BadgeVariantType } from '@wh-components/core/Badge/Badge'
import Paylivery from '@wh-components/icons/Paylivery'
import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { MarginProps } from '@wh-components/system/space'

export const PayliveryBadge: FunctionComponent<
    { id?: string; variant?: 'transparent' | 'normal' | 'disabled'; fontSize?: string; text?: string } & MarginProps
> = ({ id, variant = 'normal', fontSize = 'xs', text = 'PayLivery', ...props }) => {
    const color1 = variant === 'transparent' ? 'transparent' : 'semantic.foreground.white'
    const variantBorder: BadgeVariantType = variant === 'transparent' ? 'solid' : 'outline'

    return (
        <Badge
            Icon={PayliveryLarge}
            fontSize={fontSize}
            color1={color1}
            color2={variant === 'disabled' ? 'palette.grey' : 'palette.lavender'}
            fontWeight="bold"
            variant={variantBorder}
            lineHeight="1"
            testId={`${id}-paylivery-badge`}
            marginBottom="xxs"
            paddingVertical={0}
            css={css`
                flex-shrink: 0;
                vertical-align: middle;
                border: none;
            `}
            {...props}
        >
            {text}
        </Badge>
    )
}

const PayliveryLarge = styled(Paylivery)`
    font-size: ${(p) => p.theme.fontSizes.l};
    margin-right: ${(p) => p.theme.space.xxs}px;
` as typeof Paylivery
