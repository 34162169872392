import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { Text } from '@wh-components/core/Text/Text'
import React, { FunctionComponent } from 'react'
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import { css } from 'styled-components'
import { InternalRichText } from './InternalRichText'

interface StoryblokAppScreenshotCard extends StoryblokComponentType<string> {
    image: StoryblokAsset
    text: StoryblokRichtext
    smallPrint: string
    children: SbBlokData[]
}

interface Props extends StoryblokDefaultProps<StoryblokAppScreenshotCard> {}

export const StoryblokAppScreenshotCard: FunctionComponent<Props> = ({ blok, story }) => {
    return (
        <Box
            display="flex"
            borderRadius={{ phone: 0, tablet: 'm' }}
            backgroundColor="palette.primary.main"
            color="semantic.foreground.white"
            {...storyblokEditable(blok as unknown as SbBlokData)}
        >
            <Box flex="0 0 220px" marginTop="-30px" marginLeft="s" display={{ mobile: 'none', tablet: 'block' }} position="relative">
                <ResponsiveImage
                    srcSet={`${blok.image.filename}/m/220x0/filters:quality(90) 1x, ${blok.image.filename}/m/440x0/filters:quality(90) 2x`}
                    src={`${blok.image.filename}/m/220x0/filters:quality(90)`}
                    alt={blok.image.alt}
                    width="100%"
                    position="absolute"
                    bottom={0}
                />
            </Box>
            <Box padding="m" paddingLeft={{ mobile: 'm', tablet: 's' }}>
                <InternalRichText
                    blok={blok as unknown as SbBlokData}
                    richTextKey="text"
                    story={story}
                    css={css`
                        p {
                            margin-bottom: ${(p) => p.theme.space.s}px;
                        }
                        & > *:last-child {
                            margin-bottom: ${(p) => p.theme.space.s}px;
                        }
                    `}
                />
                {blok.smallPrint && (
                    <Text as="p" fontSize="xs" marginBottom="m">
                        {blok.smallPrint}
                    </Text>
                )}
                {blok.children.map((nestedBlok) => (
                    <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
                ))}
            </Box>
        </Box>
    )
}
