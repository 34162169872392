import { StoryblokAsset } from '../../types/built-in/StoryblokAsset'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'

export interface StoryblokBackgroundImageBox extends StoryblokComponentType<string> {
    image: StoryblokAsset
    size?: string
    repeat?: string
    position?: string
    children: SbBlokData[]
}

interface Props extends StoryblokDefaultProps<StoryblokBackgroundImageBox> {}

export const StoryblokBackgroundImageBox: FunctionComponent<Props> = ({ blok, story }) => {
    const image = blok.image
    const size = blok.size ?? 'cover'
    const repeat = blok.repeat ?? 'no-repeat'
    const position = blok.position ?? 'center center'

    return (
        <Box
            width="100%"
            height="100%"
            backgroundImage={`url("${image.filename}")`}
            backgroundSize={size}
            backgroundRepeat={repeat}
            backgroundPosition={position}
            {...storyblokEditable(blok as unknown as SbBlokData)}
        >
            {blok.children.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={story} />
            ))}
        </Box>
    )
}
