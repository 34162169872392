import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import { Text } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink, ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Collapse } from '@wh/common/chapter/components/Collapse/Collapse'
import { BottomNavigation } from '@wh/common/chapter/components/Footer/BottomNavigation'
import { LazyPicture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import appstore_badge from '@wh/common/chapter/static_hashed/img/badges/appstore_badge_white.png'
import appstore_badgeX2 from '@wh/common/chapter/static_hashed/img/badges/appstore_badge_white_x2.png'
import playstore_badge from '@wh/common/chapter/static_hashed/img/badges/playstore_badge_white.png'
import playstore_badgeX2 from '@wh/common/chapter/static_hashed/img/badges/playstore_badge_white_x2.png'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { getWohnkrediteDomain } from '../../lib/config/runtimeConfig'

export const Footer: FunctionComponent = () => (
    <Box>
        <Divider marginBottom={{ tablet: 'l' }} />
        <Box display="flex" gap={{ tablet: 'm' }} flexDirection={{ phone: 'column', tablet: 'row' }}>
            <Collapse title="Über willhaben" flex={{ tablet: 1 }}>
                <CollapseContent>
                    <ServerRoutingAnchorLink type="anchor" href="https://jobsbei.willhaben.at/" target="_blank" testId="footer-jobs-link">
                        Jobs bei willhaben
                    </ServerRoutingAnchorLink>
                    <ClientRoutingAnchorLink type="anchor" href="/iad/presse" testId="footer-press-link">
                        Presse
                    </ClientRoutingAnchorLink>
                    <ClientRoutingAnchorLink type="anchor" href="/will/nachhaltigkeit" testId="footer-sustainability-link">
                        Nachhaltigkeit
                    </ClientRoutingAnchorLink>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://www.willhaben.at/will/blog"
                        target="_blank"
                        testId="footer-willhaben-blog-link"
                    >
                        willhaben Blog
                    </ServerRoutingAnchorLink>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://tech.willhaben.at/"
                        target="_blank"
                        rel="nofollow"
                        testId="footer-tech-blog-link"
                    >
                        Tech Blog
                    </ServerRoutingAnchorLink>
                </CollapseContent>
            </Collapse>
            <Collapse title="Informationen" flex={{ tablet: 1 }}>
                <CollapseContent>
                    <ServerRoutingAnchorLink type="anchor" href="https://hilfe.willhaben.at/" target="_blank" testId="footer-help-link">
                        Hilfe und Kontakt
                    </ServerRoutingAnchorLink>
                    <ClientRoutingAnchorLink type="anchor" href="/iad/sicherheitshinweise" testId="footer-securityhints-link">
                        Sicherheitshinweise
                    </ClientRoutingAnchorLink>
                    <ClientRoutingAnchorLink type="anchor" href="/iad/nutzungsbedingungen" testId="footer-termsofuse-link">
                        Nutzungsbedingungen
                    </ClientRoutingAnchorLink>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://datenschutz.willhaben.at/"
                        target="_blank"
                        testId="footer-mydata-link"
                    >
                        Meine Daten
                    </ServerRoutingAnchorLink>
                    <ClientRoutingAnchorLink type="anchor" href="/iad/datenschutzerklaerung" testId="footer-data-privacy-link">
                        Datenschutzerklärung
                    </ClientRoutingAnchorLink>
                    <ServerRoutingAnchorLink type="anchor" href={getWohnkrediteDomain()} target="_blank" testId="wohnkredite-link">
                        Wohnkredite
                    </ServerRoutingAnchorLink>
                </CollapseContent>
            </Collapse>
            <Collapse title="Business" flex={{ tablet: 1 }}>
                <CollapseContent>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://werbung.willhaben.at/"
                        target="_blank"
                        testId="footer-digitaladvertising-link"
                    >
                        Digital Advertising
                    </ServerRoutingAnchorLink>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://immo.willhaben.at/"
                        target="_blank"
                        testId="footer-willhaben-realestate-link"
                    >
                        willhaben Immobilien
                    </ServerRoutingAnchorLink>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://fahrzeughandel.willhaben.at/"
                        target="_blank"
                        testId="footer-willhaben-motor-link"
                    >
                        willhaben Motornetzwerk
                    </ServerRoutingAnchorLink>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://www.willhaben.at/jobs/stelleninserate"
                        target="_blank"
                        testId="footer-willhaben-jobs-link"
                    >
                        willhaben Jobs
                    </ServerRoutingAnchorLink>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://willshop.willhaben.at/"
                        target="_blank"
                        testId="footer-willhaben-marketplace-link"
                    >
                        Marktplatz Shoplösungen
                    </ServerRoutingAnchorLink>
                </CollapseContent>
            </Collapse>
            <Collapse title="Mobile Apps" flex={{ tablet: 1 }}>
                <CollapseContent>
                    <Text>Jetzt kostenlos downloaden!</Text>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://play.google.com/store/apps/details?id=at.willhaben"
                        target="_blank"
                        rel="nofollow"
                        testId="footer-playstore-link"
                    >
                        <LazyPicture
                            imgSrcSet={{
                                x1: playstore_badge,
                                x2: playstore_badgeX2,
                            }}
                            alt="Google Play Store Logo"
                            title="Jetzt kostenlos downloaden im Google Play Store"
                            height="40px"
                        />
                    </ServerRoutingAnchorLink>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href="https://apps.apple.com/at/app/willhaben/id660325616?mt=8"
                        target="_blank"
                        rel="nofollow"
                        testId="footer-appstore-link"
                    >
                        <LazyPicture
                            imgSrcSet={{
                                x1: appstore_badge,
                                x2: appstore_badgeX2,
                            }}
                            alt="Apple App Store Logo"
                            title="Jetzt kostenlos downloaden im Apple App Store"
                            height="40px"
                        />
                    </ServerRoutingAnchorLink>
                </CollapseContent>
            </Collapse>
        </Box>
        <BottomNavigation />
    </Box>
)

const CollapseContent = styled.div`
    display: flex;
    flex-wrap: wrap;

    a {
        color: ${(p) => p.theme.colors.palette.verydarkgrey};
    }

    > * {
        margin-right: ${(p) => p.theme.space.m}px;
        margin-bottom: ${(p) => p.theme.space.sm}px;

        ${(p) => p.theme.media.tablet} {
            margin-bottom: ${(p) => p.theme.space.s}px;
        }
    }

    ${(p) => p.theme.media.tablet} {
        flex-direction: column;

        > *:last-child {
            margin-bottom: 0;
        }
    }
`
