import { BubbleList, BubbleListItem } from '../../../chapter/components/BubbleList/BubbleList'
import { storyblokCallActionEvent } from '../../lib/storyblokCallActionEvent'
import { storyblokResolveSearchLink } from '../../lib/storyblokResolveSearchLink'
import { StoryblokSearchLink } from '../../types/StoryblokSearchLink'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'

interface StoryblokBubbleListItem extends StoryblokComponentType<string> {
    label: string
    url: StoryblokSearchLink
    taggingId: string
}

export interface StoryblokBubbleList extends StoryblokComponentType<'bubbleList'> {
    label: string
    items: StoryblokBubbleListItem[]
    taggingId: string
}

interface Props extends StoryblokDefaultProps<StoryblokBubbleList>, BoxProps {
    onClickOverride?: (item: StoryblokBubbleListItem) => void
    testId?: string
    categoriesForegroundColor?: string
}

export const StoryblokBubbleList: FunctionComponent<Props> = ({
    blok,
    story,
    onClickOverride,
    testId,
    categoriesForegroundColor,
    ...restProps
}) => {
    return (
        <Box {...storyblokEditable(blok as unknown as SbBlokData)} {...restProps} testId={testId}>
            {blok.label && (
                <Heading
                    text={blok.label}
                    level={2}
                    fontSize="m"
                    marginBottom="s"
                    testId={testId ? `${testId}-heading` : undefined}
                    color={categoriesForegroundColor ?? 'palette.raccoon'}
                    css={css`
                        text-transform: uppercase;
                    `}
                />
            )}
            <BubbleList data-testId={testId ? `${testId}-wrapper` : undefined}>
                {blok.items.map((bubbleItem, index) => (
                    <BubbleListItem
                        key={index}
                        testId={testId ? `${testId}-entry-${index}` : undefined}
                        url={storyblokResolveSearchLink(bubbleItem.url)}
                        onClick={() => {
                            if (onClickOverride) {
                                onClickOverride(bubbleItem)
                            } else {
                                storyblokCallActionEvent(
                                    `${blok.taggingId ? blok.taggingId : blok.label}::${
                                        bubbleItem.taggingId ? bubbleItem.taggingId : bubbleItem.label
                                    }`,
                                    story?.full_slug ?? '',
                                )
                            }
                        }}
                    >
                        {bubbleItem.label}
                    </BubbleListItem>
                ))}
            </BubbleList>
        </Box>
    )
}
