import { NextRequest } from '../../chapter/types/nextJS'
import { getBbxCookiesFromRequest } from '../../chapter/types/cookies'
import { ISbStoryData, StoryblokComponentType } from '@storyblok/react/dist/types'
import { PreviewData } from 'next'
import { getStoryWithOptionalPreview } from './storyblokApiClient'
import { DEV_MODE } from '../../chapter/lib/config/constants'
import { validate } from 'uuid'
import { fetcher } from '../../chapter/api/fetcher'
import { removeUndefined } from '../../chapter/lib/functionalHelpers'

interface Datasource {
    id: number
    value: string
    name: string
}

export const getStoryblokDataSourceEntries = (identifier: string, request?: NextRequest): Promise<{ dmpSegmentIds: number[] }> => {
    return fetcher<{ datasource_entries: Datasource[] }>(
        `/iad/storyblok/bbx/v2/cdn/datasource_entries?${new URLSearchParams({ datasource: identifier, per_page: '1000' })}`,
        {
            credentials: 'include',
            cookies: getBbxCookiesFromRequest(request),
        },
    ).then((result) => ({
        dmpSegmentIds: result.datasource_entries.map((entry) => Number(entry.value)),
    }))
}

export type StoryDefault = StoryblokComponentType<string> & { [index: string]: unknown }

export const getStoryblokStory = <T = StoryDefault>(
    identifier: string,
    request?: NextRequest,
): Promise<{ story: ISbStoryData<T>; links: ISbStoryData<T>[] } | undefined> => {
    return fetcher(
        `/iad/storyblok/bbx/v2/cdn/stories/${identifier}?${new URLSearchParams(
            removeUndefined({
                find_by: validate(identifier) ? 'uuid' : undefined,
            }),
        )}`,
        {
            credentials: 'include',
            cookies: getBbxCookiesFromRequest(request),
        },
    )
}

/**
 * Retrieves one single story from storyblok via the iad cache endpoint.
 * In case the preview mode should be active, the non-cached direct endpoint is used.
 * @param identifier Can be one of full_slug, id, uuid
 */
export const getCachedStoryblokStoryWithOptionalPreview = async <T = StoryDefault>(
    identifier: string,
    previewData?: PreviewData,
    request?: NextRequest,
    resolve_links: 'url' | 'story' | '0' | '1' | 'link' = '0',
) => {
    if (DEV_MODE || typeof previewData === 'object') {
        return getStoryWithOptionalPreview<T>(identifier, previewData, resolve_links)
    } else {
        return await getStoryblokStory<T>(identifier, request)
    }
}

export const getStoriesWithStartPath = async (startsWith: string): Promise<ISbStoryData[]> => {
    const PAGE_SIZE = 25

    const stories: ISbStoryData[] = []

    let curPage: { stories: ISbStoryData[] }
    let page = 1

    do {
        curPage = await getStoriesWithStartPathPaged(page++, PAGE_SIZE, startsWith)
        stories.push(...curPage.stories)
    } while (curPage.stories.length >= PAGE_SIZE)
    // api response doesn't contain paging information, so we need to look at how many stories are returned by the endpoint

    return stories
}

const getStoriesWithStartPathPaged = async (
    page: number,
    pageSize: number,
    startsWith: string,
): Promise<{
    stories: ISbStoryData[]
}> => {
    return await fetcher<{
        stories: ISbStoryData[]
    }>(
        `/iad/storyblok/bbx/v2/cdn/stories?${new URLSearchParams({
            starts_with: startsWith,
            page: `${page}`,
            per_page: `${pageSize}`,
        })}`,
        {},
    )
}
