import React, { FunctionComponent, useState } from 'react'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { storyblokEditable } from '@storyblok/react'
import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'
import { Checkbox } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { storyblokCallActionEvent } from '../../lib/storyblokCallActionEvent'
import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { isUserLoggedIn, useProfileData } from '../../../chapter/components/GlobalStateProvider/GlobalStateProvider'

interface StoryblokDiscoveryParcelStationSignupFormBlok extends StoryblokComponentType<string> {
    agreementText: string
    loggedInButtonText: string
    loggedOutButtonText: string
    exitPageURL: string
}

interface Props extends StoryblokDefaultProps<StoryblokDiscoveryParcelStationSignupFormBlok> {}

export const StoryblokDiscoveryParcelStationSignupForm: FunctionComponent<Props> = ({ blok, story }) => {
    // This component implements a fake door test.
    // After clicking the CTA button, we do not want to save/send the data, but go to a survey.

    const [isAgreementChecked, setAgreementChecked] = useState(false)
    const [userProfileData] = useProfileData()

    const isLoggedIn = isUserLoggedIn(userProfileData)

    return (
        <div {...storyblokEditable(blok as unknown as SbBlokData)}>
            {isLoggedIn ? (
                <p key="logged-in-user">
                    <span>Du bist derzeit eingeloggt als: </span>
                    <strong>
                        {userProfileData?.firstName} {userProfileData?.lastName}
                    </strong>
                </p>
            ) : (
                <p key="logged-out-user">Du bist derzeit nicht eingeloggt.</p>
            )}
            <Checkbox
                id="signup-agreement"
                testId="signup-agreement"
                label={blok.agreementText}
                onClick={() => setAgreementChecked(!isAgreementChecked)}
            />
            <LinkWithButtonStyle
                color="primary"
                marginTop="s"
                disabled={!isAgreementChecked}
                href={blok.exitPageURL}
                onClick={() => {
                    storyblokCallActionEvent('completed', story?.full_slug ?? '')
                }}
            >
                {isLoggedIn ? blok.loggedInButtonText : blok.loggedOutButtonText}
            </LinkWithButtonStyle>
        </div>
    )
}
