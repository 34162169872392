import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { storyblokEditable } from '@storyblok/js'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types'
import { Box } from '@wh-components/core/Box/Box'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { useDidomi } from '@wh/common/chapter/components/Didomi/useDidomi'
import { useRouter } from 'next/router'
import { useTimeout } from '../../../chapter/hooks/useTimeout'
import { Text } from '@wh-components/core/Text/Text'

const isValidCarAuctionsBaseUrl = (baseUrl: string): boolean =>
    baseUrl !== undefined && /^https:\/\/[a-z-]+\.2trde\.com\/[A-Za-z0-9\-_]+$/.test(baseUrl)

interface StoryblokCarAuctionsSellerWidget extends StoryblokComponentType<string> {
    baseUrl: string
    subchannelName: string
    height: string
}

interface Props extends StoryblokDefaultProps<StoryblokCarAuctionsSellerWidget> {}

export const StoryblokCarAuctionsSellerWidget: FunctionComponent<Props> = ({ blok }) => {
    const { isReady, isConsentGiven } = useDidomi('Hotjar')
    const router = useRouter()

    const [isTimeoutElapsed, setTimeoutElapsed] = useState(false)
    useTimeout(() => {
        // Didomi is not ready immediately
        setTimeoutElapsed(true)
    }, 1000)

    /* To avoid flickering, we prefer to wait until Didomi is ready, and we definitely know if the consent was accepted or rejected.
     * When the page is displayed in an app embedded webview, Didomi is not loaded and therefore isReady will remain false.
     * If Didomi is still not ready after the timeout elapsed, the component should be rendered anyway, acting like no consent was given.
     */
    const renderWidget = isReady || isTimeoutElapsed

    if (!isValidCarAuctionsBaseUrl(blok.baseUrl)) {
        return (
            <Text color="palette.signal.red" fontWeight="bold" {...storyblokEditable(blok as unknown as SbBlokData)}>
                URL ist ungültig (muss https://hostname.2trde.com/pfad entsprechen)
            </Text>
        )
    }

    let subchannel = blok.subchannelName

    const campaignSource = (router.query.source as string) ?? ''
    if (/^[A-Za-z0-9_]+$/.test(campaignSource)) {
        subchannel += `_${campaignSource}`
    }

    const url = `${blok.baseUrl}?origin=willhaben` + `&subchannel=${subchannel}` + `&hotjar_consent=${isConsentGiven ? 'true' : 'false'}`

    return renderWidget ? (
        <Box height={`${blok.height}px`} {...storyblokEditable(blok as unknown as SbBlokData)}>
            <IFrame src={url} allow="autoplay; encrypted-media; picture-in-picture" allowFullScreen={true} frameBorder="0" />
        </Box>
    ) : null
}

const IFrame = styled.iframe`
    display: block;
    width: 100%;
    height: 100%;
`
