import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { StoryblokComponentType } from '@storyblok/js/dist/types'
import React, { FunctionComponent } from 'react'
import { StoryblokIframe } from './StoryblokIframe'

interface StoryblokYoutube extends StoryblokComponentType<string> {
    orientation?: 'portrait' | 'landscape'
    url: string
}

interface Props extends StoryblokDefaultProps<StoryblokYoutube> {}

export const StoryblokYoutube: FunctionComponent<Props> = ({ blok, story }) => {
    const { url, orientation, ...restBlokProps } = blok

    const youtubeId = extractYoutubeId(url)

    if (!youtubeId) {
        return null
    }

    const height = orientation === 'portrait' ? '9 / 16' : '16 / 9'

    return (
        <StoryblokIframe
            blok={{ url: `https://www.youtube-nocookie.com/embed/${youtubeId}`, height: height, ...restBlokProps }}
            story={story}
        />
    )
}

/**
 * Taken from https://blog.devgenius.io/how-to-extract-the-id-of-a-youtube-or-vimeo-url-with-javascript-ad5e2d1049a
 */
const extractYoutubeId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/)([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[2].length === 11 ? match[2] : null
}
